import { Form } from "react-bootstrap";

interface BusinessConsultantProps {
  isDisabled: boolean,
  value: any
}

export function BusinessConsultant({ isDisabled, value }: BusinessConsultantProps) {
  return (
    <Form.Group>
      <Form.Label>Consultor Comercial</Form.Label>
      <Form.Control
        name='cc'
        disabled={isDisabled}
        type='text'
        value={value}
      />
    </Form.Group>
  )
}