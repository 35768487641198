import {
	Card,
	Line,
	IconMoney,
	IconInvoice,
	IconTypeInvoice
} from '../styles/CustomCardInvoice'
import SoftBadge from 'components/common/SoftBadge'
import INVOICE_STATUS from 'helpers/invoiceStatus'
import INVOICE_TYPES from 'helpers/invoiceTypes'

export interface IPropsComponent {
	invoice_id: number
	invoice_position: number
	total_invoices: number
	invoice_due_date?: string
	status: number
	value: number
	invoice_type: number
	isDark:boolean
}

const CustomCardInvoice = ({
	invoice_type,
	invoice_id,
	invoice_position,
	total_invoices,
	invoice_due_date,
	status,
	value,
	isDark
}: IPropsComponent) => {
	const onNavigate = (url: string) => {
		window.open(url, '_blank')
	}
	const renderStatus = (status: number) => {
		let bg = ``
		let text = ``
		switch (status) {
			case INVOICE_STATUS.PENDING:
				bg = `warning`
				text = `Aguardando pagamento`
				break
			case INVOICE_STATUS.AWAITING_RISK_ANALYSIS:
				bg = `warning`
				text = `Pagamento em análise`
				break
			case INVOICE_STATUS.CONFIRMED:
				bg = `primary`
				text = `Pagamento confirmado`
				break
			case INVOICE_STATUS.RECEIVED:
				bg = `success`
				text = `Recebida`
				break
			case INVOICE_STATUS.OVERDUE:
				bg = `danger`
				text = `Vencida`
				break
			case INVOICE_STATUS.REFUND_REQUESTED:
				bg = `info`
				text = `Estorno Solicitado`
				break
			case INVOICE_STATUS.REFUNDED:
				bg = `primary`
				text = `Estornada`
				break
			case INVOICE_STATUS.CHARGEBACK_REQUESTED:
				bg = `primary`
				text = `Recebido chargeback`
				break
			case INVOICE_STATUS.CHARGEBACK_DISPUTE:
				bg = `warning`
				text = `Em disputa de chargeback`
				break
			case INVOICE_STATUS.AWAITING_CHARGEBACK_REVERSAL:
				bg = `warning`
				text = `Disputa vencida, aguardando repasse da adquirente`
				break
		}

		return (
			<SoftBadge pill bg={bg}>
				{text}
			</SoftBadge>
		)
	}
	const renderTypeInvoice = (status: number) => {
		let bg = ``
		let text = ``
		switch (status) {
			case INVOICE_TYPES.SUBSCRIPTION:
				bg = `success`
				text = `Assinatura`
				break
			case INVOICE_TYPES.RENEGOTIATION:
				bg = `info`
				text = `Renegociação`
				break
			case INVOICE_TYPES.MEMBERSHIP_FEE:
				bg = `primary`
				text = `Taxa de Adesão`
				break
			case INVOICE_TYPES.CANCELLATION_PENALTY:
				bg = `danger`
				text = `Multa de Cancelamento`
				break
		}

		return <SoftBadge bg={bg}>{text}</SoftBadge>
	}
	function convertDate(inputData: any) {
		const parts = inputData.split('-')

		const day = parts[2]
		const month = parts[1]
		const year = parts[0]

		const formattedDate = `${day}/${month}/${year}`

		return formattedDate
	}
	const renderDate = (dateString: any) => {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}
	return (
		<Card
			onClick={() => {
				onNavigate(`/faturas/${invoice_id}`)
			}}
			isDark={isDark}
		>
			<Line>
				<div>{renderTypeInvoice(invoice_type)}</div>
			</Line>
			<Line>
				<div>
					<IconInvoice />
					Fatura {invoice_position} de {total_invoices}
				</div>
				<div>{convertDate(invoice_due_date)}</div>
			</Line>
			<Line style={{marginBottom:5}}>
				<div>
					<IconMoney />
					{value?.toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL'
					})}
				</div>
				<div>{renderStatus(status)}</div>
			</Line>
		</Card>
	)
}

export default CustomCardInvoice
