import { FC } from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import AuthCardLayout from 'layouts/AuthCardLayout';

const Logout: FC = function Logout() {
    return (
        <AuthCardLayout leftSideContent={undefined} footer={false}>
            <div className="text-center">
                <LogoutContent layout="card" titleTag="h3" />
            </div>
        </AuthCardLayout>
    );
};

export default Logout;
