import Modal from 'react-bootstrap/Modal'
import { useState, useEffect } from 'react'
import { Button, Table, Form, Row, Col, Card, Spinner } from 'react-bootstrap'
import { consultBenefits } from 'services/vittamais-core/benefit/benefit'
import InputMask from 'react-input-mask'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { getByCgc } from 'services/vittamais-core/customer/customer'
import { useNavigate } from 'react-router-dom'
function HandleCustomerModal({
	show,
	isDark,
	onClose,
	dataPage,
	changeDataPage,
	handleCep
}: any) {
	const { showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const navigate = useNavigate()

	const [FormData, setFormData] = useState({
		cgc: ''
	})
	const [controlLoading, setControlLoading] = useState(false)
	const [mask, setMask] = useState('999.999.999-99')
	const [optionsState, setOptionsState] = useState<any>({
		bp_finded: false,
		bp_data: null,
		bp_found_on: ''
	})
	const handleFieldChange = (e: any) => {
		setFormData({
			...FormData,
			[e.target.name]: e.target.value
		})
	}

	const handleInputChange = (e: any) => {
		const value = e.target.value
		const onlyDigits = value.replace(/\D+/g, '')

		if (onlyDigits.length <= 11) {
			setMask('999.999.999-99')
		} else {
			setMask('99.999.999/9999-99')
		}
	}

	const onSubmit = async () => {
		const onlyDigits: any = FormData?.cgc.replace(/\D+/g, '')
		if (!onlyDigits || onlyDigits == '') {
			showAlert({
				title: 'Preencha o campo CPF/CNPJ!',
				text: '',
				type: 'info'
			})

			return
		}
		try {
			setControlLoading(true)

			let finded = await getByCgc(onlyDigits)

			setOptionsState({
				bp_finded: true,
				bp_data: finded?.bp_data?.id ? finded?.bp_data : null,
				bp_found_on: finded?.finded_on
			})

			setControlLoading(false)
		} catch (e: any) {

			console.log(e)
			setControlLoading(false)

			let dataForm = { ...dataPage }
			if (onlyDigits.length <= 11) {
				dataForm.person_type = 1
				dataForm.cpf = onlyDigits
			} else {
				dataForm.person_type = 2
				dataForm.cnpj = onlyDigits
			}
			changeDataPage(dataForm)
			onClose()
			return

			/*
			if (e?.response?.data?.error_code == 'bp_not_found') {
				let dataForm = { ...dataPage }
				if (onlyDigits.length <= 11) {
					dataForm.person_type = 1
					dataForm.cpf = onlyDigits
				} else {
					dataForm.person_type = 2
					dataForm.cnpj = onlyDigits
				}
				changeDataPage(dataForm)
				onClose()
				return
			}
			*/
			/*if (e?.response?.data?.error_message) {
				showAlert({
					title: 'Ops...!',
					text: `${e?.response?.data?.error_message}`,
					type: 'error'
				})
				
				onClose()
				return
			}*/
			/*showAlert({
				title: 'Ops...!',
				text: 'Aconteceu algo inesperado, verifique os campos e tente novamente.',
				type: 'error'
			})
			
			onClose()
			*/
		}
	}

	const loadDataPage = () => {
		setFormData({
			cgc: ''
		})
		setOptionsState({
			bp_finded: false,
			bp_data: null,
			bp_found_on: ''
		})
	}

	const handleUseBp = async () => {
		try {
			const onlyDigits = FormData?.cgc?.replace(/\D+/g, '')
			let data = optionsState?.bp_data
			let dataForm: any = { ...dataPage }
			dataForm.address_cep = data.addr_cep
			dataForm.person_type = onlyDigits?.length > 11 ? 2 : 1;
			dataForm.name = data?.name
			dataForm.address = data.addr_address
			dataForm.address_complement = data.addr_complement
			dataForm.cpf = data.cpf
			dataForm.cnpj = data.cnpj
			dataForm.phone = data.phone
			dataForm.mobile_phone = data.mobile_phone
			dataForm.address_district = data.addr_district
			dataForm.email = data.email
			dataForm.birth_date = data.birth_date ? data.birth_date : ''
			dataForm.address_number = data.addr_number
			dataForm.rg_org = data.rg_org ? data.rg_org : ''
			dataForm.rg_uf = data.rg_uf ? data.rg_uf : ''
			dataForm.rg_number = data.rg_number ? data.rg_number : ''
			changeDataPage(dataForm)
			onClose()
			//  showToast('Informações utilizadas com sucesso!','success');
			if (data?.addr_cep?.length == 8) {
				handleCep(dataForm)
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		if (show) loadDataPage()
	}, [show])

	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				size='lg'
				centered
				style={{ color: isDark ? '#d8e2ef !important' : '' }}
			>
				<Modal.Header>
					<Row style={{ width: '100%' }}>
						<Col sm='12'>
							<Modal.Title>Informe o CPF/CNPJ do Cliente</Modal.Title>
						</Col>
					</Row>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<InputMask
								onKeyDown={e => {
									if (e.keyCode == 13 && !e.repeat) {
										onSubmit()
									}
								}}
								name='cgc'
								mask={mask}
								placeholder='Digitar CPF/CNPJ'
								className='form-control'
								onChange={handleFieldChange}
								onInput={handleInputChange}
								value={FormData.cgc}
								disabled={optionsState?.bp_finded}
							/>
						</Col>
					</Row>
				</Modal.Body>
				{optionsState?.bp_finded && optionsState?.bp_data ? (
					<Modal.Footer>
						<Row style={{ width: '100%' }}>
							<Col
								md='6'
								align='start'
								style={{ position: 'relative', left: '-15px', top: '2px' }}
							>
								{/* <h5 className='text-info'>Cliente Localizado</h5> */}
								<span className='mb-2'>
									Cliente Localizado em:{' '}
									<strong className='text-info'>
										{optionsState?.bp_found_on == 'vittamais' ? 'Vittá +' : 'VittáNet'}
									</strong>
								</span>
								{/* <Form.Label>Nome</Form.Label> */}
							</Col>
							{optionsState?.bp_found_on == 'vittamais' ? (
								<Col md='6' align='end' style={{ position: 'relative', left: '15px' }}>
									<Button
										variant='info'
										type='button'
										className='btn-sm me-2'
										onClick={() => {
											navigate(`/clientes/${optionsState?.bp_data?.id}`)
											onClose()
										}}
									>
										Acessar Cadastro
									</Button>
									<Button
										variant='falcon-default'
										className='btn-sm'
										type='button'
										onClick={() => {
											setOptionsState({
												bp_finded: false,
												bp_data: null,
												bp_found_on: ''
											})
											loadDataPage()
										}}
									>
										Digitar outro CPF/CNPJ
									</Button>
								</Col>
							) : (
								<Col md='6' align='end' style={{ position: 'relative', left: '15px' }}>
									<Button
										variant='info'
										type='button'
										className='btn-sm me-2'
										onClick={() => {
											handleUseBp()
										}}
									>
										Importar Cadastro
									</Button>
									<Button
										variant='falcon-default'
										className='btn-sm'
										type='button'
										onClick={() => {
											setOptionsState({
												bp_finded: false,
												bp_data: null,
												bp_found_on: ''
											})
											loadDataPage()
										}}
									>
										Digitar outro CPF
									</Button>
								</Col>
							)}
							<Col style={{ position: 'relative', left: '-15px' }}>
								<strong>Nome: {optionsState?.bp_data?.name}</strong>
							</Col>
						</Row>
					</Modal.Footer>
				) : optionsState?.bp_finded && !optionsState?.bp_data ? (
					<Modal.Footer>
						<Row style={{ width: '100%' }}>
							<Col md='6' align='start'>
								<h5 className='text-info mb-2'>Cliente não encontrado</h5>
							</Col>
							<Col md='6' align='end' style={{ position: 'relative', left: '15px' }}>
								<Button
									variant='info'
									type='button'
									className='btn-sm me-2'
									onClick={() => {
										onClose()
									}}
								>
									Preencher Manual
								</Button>
								<Button
									variant='falcon-default'
									className='btn-sm'
									type='button'
									onClick={() => {
										setOptionsState({
											bp_finded: false,
											bp_data: null,
											bp_found_on: ''
										})
										loadDataPage()
									}}
								>
									Digitar outro CPF
								</Button>
							</Col>
						</Row>
					</Modal.Footer>
				) : (
					<Modal.Footer>
						<Button
							disabled={controlLoading}
							variant='primary'
							onClick={() => {
								onSubmit()
							}}
						>
							{controlLoading ? 'Pesquisando...' : 'Continuar'}
							{controlLoading ? (
								<Spinner animation='border' size='sm' variant='light' />
							) : null}
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	)
}

export default HandleCustomerModal
