import { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import CustomCardHolder from '../customCardHolder/Index'
import { CustomLink } from '../styles/CustomLink'
import { IDependentInfoData } from './interfaces'
import ModalAddDependent from './components/ModalAddDependent'
import { FaUserPlus } from 'react-icons/fa'

interface IPropsComponent {
	dataDependents: any
	formData: any
	setFormData: any
	loadDataForm: () => void
	disabled: boolean
	isDark:boolean
}
function DependentComponent({
	dataDependents,
	formData,
	setFormData,
	loadDataForm,
	disabled,
	isDark
}: IPropsComponent) {
	const [showModal, setShowModal] = useState(false)
	return (
		<div className='mt-3 text-primary' style={{ fontWeight: 'bold' }}>
			<Row>
				<Col sm='6'>
					{' '}
					<h5 style={{ fontWeight: 'bold' }}>Dependentes</h5>{' '}
				</Col>
				<Col sm='6' className='d-flex justify-content-end'>
					<Button
						variant='falcon-default'
						onClick={e => {
							setShowModal(true)
							e.preventDefault()
						}}
						disabled={disabled}
					>
						{' '}
						<FaUserPlus
							size={23}
							style={{ position: 'relative', top: '-4px' }}
						/>{' '}
						Adicionar dependente
					</Button>
					{/* <CustomLink
						href='#'
						onClick={(e) => {
							setShowModal(true)
							e.preventDefault();
						}}
					>
					<FaUserPlus size={23} style={{position:"relative",top:"-4px"}}/> Adicionar dependente
					</CustomLink> */}
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					{dataDependents?.length > 0
						? dataDependents?.map((item: IDependentInfoData, index: number) => (
								<CustomCardHolder
									key={item?.id}
									dependentData={item}
									bp_name={
										item?.business_partner?.name
											? item?.business_partner?.name
											: 'Jorginho Alves Cruzes'
									}
									bp_card_number={item?.public_number}
									url_to_path={`/assinaturas/${item?.id}`}
									status={item?.status}
									loadDataForm={loadDataForm}
									isDependent={true}
									isDark={isDark}
								/>
						  ))
						: ''}
				</Col>
			</Row>
			<ModalAddDependent
				show={showModal}
				onClose={() => {
					setShowModal(false)
				}}
				FormData={formData}
				setFormData={setFormData}
				loadDataForm={loadDataForm}
			/>
		</div>
	)
}

export default DependentComponent
