import { Form } from "react-bootstrap";
import { NumericFormat } from 'react-number-format'

interface ChargeByHolderProps {
  onValueChange: any
  isDisabled: boolean
  value: any
}

export function ChargeByHolder({ onValueChange, isDisabled, value }: ChargeByHolderProps) {
  return (
    <Form.Group>
      <Form.Label>Valor por Titular</Form.Label>
      <NumericFormat
        name='charge_by_holder'
        displayType={'input'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        prefix={'R$ '}
        className='form-control'
        onValueChange={onValueChange}
        disabled={isDisabled}
      />
    </Form.Group>
  )
}