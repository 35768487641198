import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #2995d3;
  border-radius: 50%;
  position:relative;
  top:6px;
`;

export const AvatarLetter = styled.span`
  font-size: 15px;
  color: white;
`;