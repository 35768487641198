import { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import CustomCardInvoice from '../customCardInvoice/Index'
import { IDataSubscriptionInvoice } from './interfaces'
import ModalOfActions from './components/ModalOfActions'
import { RiSettings5Fill } from 'react-icons/ri'
import ModalPrintMeat from './components/ModalPrintMeat'
import ModalEditSubscription from './components/ModalEditSubscription'
import ModalInvoiceMethods from './components/ModalInvoiceMethods'
export interface IPropsComponent {
	dataInvoices: any
	subscriptionData: any
	loadDataForm: () => void
	disabled: boolean
	isDark:boolean
}
function InvoiceComponent({
	subscriptionData,
	dataInvoices,
	loadDataForm,
	disabled,
	isDark
}: IPropsComponent) {
	const [controlModalActions, setControlModalActions] = useState({
		show: false
	})
	const [controlModalPrintMeat, setControlModalPrintMeat] = useState({
		show: false
	})
	const [controlModalEditSubscription, setControlModalEditSubscription] =
		useState({
			show: false
		})
	const [controlModalInvoiceMethods, setControlModalInvoiceMethods] = useState({
		show: false,
		type: ''
	})
	const compareDueDates = (a: any, b: any) => {
		const dateA: any = new Date(a.due_date)
		const dateB: any = new Date(b.due_date)
		let sub = dateA - dateB
		return sub
	}

	return (
		<div className='mt-5 text-primary' style={{ fontWeight: 'bold' }}>
			<Row>
				<Col sm='6'>
					{' '}
					<h5 style={{ fontWeight: 'bold' }}>Faturas</h5>{' '}
				</Col>
				<Col sm='6'  className='d-flex justify-content-end'>
					<Button
						variant='falcon-default'
						onClick={event => {
							setControlModalActions({ show: true })
							event.preventDefault()
						}}
						disabled={disabled}
					>
						<RiSettings5Fill
							size={23}
							style={{ position: 'relative', top: '-2px' }}
						/>{' '}
						Ações
					</Button>
					{/* <CustomLink
						href='#'
						onClick={event => {
							setControlModalActions({ show: true })
							event.preventDefault()
						}}
					>
						<CgDetailsMore size={23} style={{ position: 'relative', top: '-2px' }} />{' '}
						Ações
					</CustomLink> */}
				</Col>
			</Row>
			<Row>
				{dataInvoices && dataInvoices.length > 0
					? dataInvoices
							.sort(compareDueDates)
							.map((item: IDataSubscriptionInvoice, index: number) => (
								<Col lg={12} key={item?.id}>
									<CustomCardInvoice
										invoice_type={item?.type}
										invoice_id={item?.id}
										invoice_position={index + 1}
										total_invoices={dataInvoices?.length}
										invoice_due_date={item?.due_date}
										status={item.status}
										value={item.gross_value / 100}
										isDark={isDark}
									/>
								</Col>
							))
					: null}
			</Row>

			<ModalOfActions
				show={controlModalActions.show}
				invoice={dataInvoices}
				onClose={response => {
					setControlModalActions({
						show: false
					})
					if (response?.action == 'print_meat') {
						setControlModalPrintMeat({
							show: true
						})
					} else if (response?.action == 'edit_signature') {
						setControlModalEditSubscription({
							show: true
						})
					} else if (response?.action == 'renegotiate') {
						setControlModalInvoiceMethods({
							show: true,
							type: 'renegotiate'
						})
					} else if (response?.action == 'cancellation_penally') {
						setControlModalInvoiceMethods({
							show: true,
							type: 'cancellation_penally'
						})
					}
				}}
			/>
			<ModalPrintMeat
				show={controlModalPrintMeat.show}
				subscriptionData={subscriptionData}
				onClose={response => {
					setControlModalPrintMeat({
						show: false
					})
				}}
				loadData={loadDataForm}
			/>
			<ModalEditSubscription
				show={controlModalEditSubscription.show}
				onClose={response => {
					if (response?.action) {
						loadDataForm()
					}
					setControlModalEditSubscription({
						show: false
					})
				}}
				subscriptionData={subscriptionData}
			/>
			<ModalInvoiceMethods
				show={controlModalInvoiceMethods.show}
				type={controlModalInvoiceMethods.type}
				onClose={response => {
					if (response?.action) {
						loadDataForm()
					}
					setControlModalInvoiceMethods({
						show: false,
						type: ''
					})
				}}
				subscriptionData={subscriptionData}
			/>
		</div>
	)
}

export default InvoiceComponent
