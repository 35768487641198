import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Row, Col } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import debounce from 'debounce-promise'
import { search as searchContracts } from 'services/vittamais-core/contract/contract'
import AppContext from 'context/Context'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { importHoldersData } from 'services/vittamais-core/import/holders'
import ModalError from './ModalError'

function ModalImportTitles() {
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const {
		config: { isDark }
	} = useContext(AppContext)
	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}
	const fileInputRef: any = useRef(null);
	const [show, setShow] = useState(false)
	const [modalError, setModalError] = useState({
		show: false,
		data: []
	})
	const [dataForm, setDataForm] = useState<any>({
		contract_id: '',
		file: ''
	})
	const [showLoading, setShowLoading] = useState(false)
	const navigate = useNavigate()
	const [loadingDoc, setLoagingDoc] = useState(false)

	const loadContractInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					// {
					// 	label: 'Todos',
					// 	value: ''
					// }
				]

				let response: any = await searchContracts({ term: term, tier_type: '2', status: '3' }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label: `Contrato: #${element.id} - Dependentes: ${element?.holder_dependents_quantity
							} - Valor: ${(element?.value / 100)?.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL'
							})} - Preço: ${(element?.price / 100)?.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL'
							})} - Desconto: ${(element?.discount / 100)?.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL'
							})}`,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceContractOptionsBp: any = debounce(loadContractInputOptions, 600)

	const onSubmit = async () => {
		setShowLoading(true)
		try {
			if (!dataForm.contract_id || !dataForm?.file) {
				showAlert({ title: 'Preencha todos os campos!', type: 'warning', text: '' })
				setShowLoading(false)
				return
			}
			const formData = new FormData()
			formData.append('file', dataForm?.file)
			const result = await importHoldersData(dataForm?.contract_id?.value, formData);
			showToast('Arquivo importado com sucesso!', 'success')
			if (result.length > 0) {
				return setModalError({
					show: true,
					data: result
				})
			}
			navigate(-1)

		} catch (e: any) {
			setShowLoading(false)
			setDataForm({
				contract_id: '',
				file: ''
			})
			if (fileInputRef.current) {
				fileInputRef.current.value = "";
			}
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text:
					'Não conseguimos importar este arquivo, verifique todas as colunas e tente novamente...'
			})
		}
		setShowLoading(false)
	}

	useEffect(() => {
		setShow(true)
	}, [])

	if (!show) return null
	return (
		<>
			<Modal show={show} onHide={() => { }} size='lg'>
				<Modal.Header>
					<Modal.Title>Importar Titulares</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<Form.Group>
								<Form.Label>Contrato</Form.Label>
								<AsyncSelect
									styles={customStyles}
									value={dataForm.contract_id}
									name='contract_id'
									placeholder='Selecionar contrato'
									isClearable
									defaultOptions
									loadOptions={debounceContractOptionsBp}
									noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
									onChange={(e: any) => {
										setDataForm({ ...dataForm, contract_id: e })
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col>
							<Form.Group>
								<Form.Label>Arquivo</Form.Label>
								<Form.Control
									name='file'
									ref={fileInputRef}
									type='file'
									onChange={(e: any) => {
										let file = e?.target?.files?.[0]
										if (file) {
											const allowedExtensions = ['.xlsx', '.xls', '.csv']
											const fileExtension = file?.name?.substring(
												file?.name?.lastIndexOf('.')
											)
											if (!allowedExtensions.includes(fileExtension)) {
												showAlert({
													type: 'info',
													text: '',
													title: 'Extensão do arquivo não permitida!'
												})
												setDataForm({ ...dataForm, file: null })
												if (fileInputRef.current) {
													fileInputRef.current.value = "";
												}
												return
											}
											setDataForm({ ...dataForm, file: file })
										}
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="info"
						disabled={loadingDoc}
						onClick={() => {
							setLoagingDoc(true)
							try {
								const link = document.createElement('a');
								link.href = 'https://storage.googleapis.com/vtm-public/Modelo-Importacao-Titulares-VittaMais.xlsx';
								link.download = 'Modelo.xlsx';
								document.body.appendChild(link);
								link.click();
								document.body.removeChild(link);
							} catch (error) {
								console.log(error)
							}

							setLoagingDoc(false)
						}}
					>
						Baixar Modelo Excel
					</Button>
					<Button
						variant='secondary'
						onClick={() => {
							navigate(-1)
						}}
						disabled={showLoading}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							onSubmit()
						}}
						disabled={showLoading}
					>
						{showLoading ? 'Importar...' : 'Importar'}
					</Button>
				</Modal.Footer>
			</Modal>
			<ModalError
				show={modalError.show}
				data={modalError.data}
				onClose={() => {
					setModalError({
						show: false,
						data: []
					});
					navigate(-1);
				}}
			/>
		</>
	)
}

export default ModalImportTitles
