
import styled from 'styled-components';

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  &:hover {
    background-color: #f0f2f5;

    .IconWrapper {
      background-color: blue;
    }
    .TextWrapper
    {
        color:blue;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: #2995d3;
 
  border-radius: 50%;
  margin-right: 10px;
  font-weight:bold;
  & svg {
    color:white;
    font-size: 16px;
  }
`;

export const Text = styled.span`
  font-size: 18px;
  color:#2995d3;
`;

export const ClickText = styled.span`
  font-size: 10px;
  color:#2995d3;
  font-weight:bold;
`;

export const OptionContent = styled.div`
  display: flex;
  align-items: center;
`;