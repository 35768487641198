import request from '../config/request'
import { IFormData } from './interfaces'


const search = (filter: IFormData,) => {
    let query = ""

    if (filter.pdv_id) query += "?pdv_id=" + filter.pdv_id
    if (filter.period) query += "&period=" + filter.period
    if (filter.initial_date) query +="&initial_date="+ filter.initial_date
    if(filter.final_date) query +="&final_date="+ filter.final_date

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/general-dashboard` +
			query,
		vtAuth: true
	})
}

export { search}

