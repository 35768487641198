import { FC, useState, useContext, useEffect } from 'react'
import {
	Card,
	Button,
	Row,
	Col,
	Form,
	DropdownButton,
	Dropdown
} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import SoftBadge from 'components/common/SoftBadge'
import AppContext from 'context/Context'
import { BsFillSendFill } from 'react-icons/bs'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import CustomLinkLeft from 'components/common/CustomLinkLeft'
import {
	loadDataEditform,
	searchUpdateInvoices,
	undoCashInvoice
} from 'services/vittamais-core/invoice/invoice'
import { IDataLoadForm } from './interfaces'
import INVOICE_STATUS from 'helpers/invoiceStatus'
import { FaMoneyBillWave, FaUndo, FaUserAlt } from 'react-icons/fa'
import { AiFillSetting } from 'react-icons/ai'
import { AiOutlinePrinter } from 'react-icons/ai'
import { GrOverview } from 'react-icons/gr'
import { AiFillEdit } from 'react-icons/ai'
import ModalEditInvoice from './components/ModalEditInvoice'
import { useLoading } from 'context/LoadingBar/LoadingBar'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { FiRefreshCw } from 'react-icons/fi'
import ModalConfirmCash from './components/ModalConfirmCash'
import { hasEspecificPermission } from 'helpers/hasEspecificPermission'
import useAuth from 'hooks/useAuth'
import INVOICE_TYPES from 'helpers/invoiceTypes'
import { BsFillTrashFill } from 'react-icons/bs'
import { deleteInvoice } from 'services/vittamais-core/invoice/invoice'
import { useNavigate } from 'react-router-dom'
const ViewCharges: FC = function ViewCharges() {
	const navigate = useNavigate()
	const { dataAuth } = useAuth()
	const { setLoading } = useLoading()
	const [showLoading, setShowLoading] = useState(false)
	const {
		config: { isDark },
		setConfig
	} = useContext(AppContext)
	const { id } = useParams()
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [controlShowModal, setControlShowModal] = useState({
		show: false
	})
	const [controlShowModalCash, setControlShowModalCash] = useState({
		show: false
	})

	const [FormData, setFormData] = useState<any>({
		id: '',
		user_id: '',
		business_partner_name: '',
		subscription_id: '',
		membership_tier_id: '',
		business_partner_id: '',
		pdv_id: '',
		holder_price: '',
		holder_dependents_quantity: '',
		recurrency: '',
		payment_method: '',
		price: '',
		discount: '',
		value: '',
		next_part_date: '',
		status: '',
		cancelled_at: '',
		cancelled_by: '',
		cancel_reason: '',
		cancel_obs: '',
		signed_at: '',
		sign_requested_at: '',
		channel_sign_requested: '',
		created_at: '',
		due_date: '',
		interest_percent: '',
		penalty_percent: '',
		discount_percent: '',
		discount_fixed: '',
		invoice_ext_url: '',
		bank_ticket_ext_url: '',
		contract_id: '',
		description: '',
		cfg_discount_value: '',
		cfg_discount_type: '',
		cfg_discount_due_date_limit_days: '',
		cfg_penalty_value: '',
		cfg_penalty_type: '',
		cfg_interest_value: '',
		cfg_interest_type: ''
	})
	const handleFieldChange = (e: any): void => {
		setFormData({
			...FormData,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = (e: any) => {
		e.preventDefault()
		console.log('e,', FormData)
	}
	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}

	const handleDeleteInvoice = async () => {
		setShowLoading(true)
		try {
			if (!id) return
			let confirmed = await showConfirm({
				title: 'Atenção!',
				text: 'Tem certeza que deseja excluir essa fatura?'
			})
			if (!confirmed) {
				setShowLoading(false)
				return
			}

			await deleteInvoice(parseInt(id))
			showToast('Fatura excluída com sucesso!', 'success')
			navigate('/faturas')
		} catch (e: any) {
			console.log(e)
			setShowLoading(false)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}
			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
	}

	const handleUndoCash = async () => {
		try {
			setShowLoading(true)
			if (!FormData?.id) {
				return
			}
			let confirmed = await showConfirm({
				title: 'Atenção!',
				text: 'Tem certeza que deseja desfazer esse pagamento?'
			})
			if (!confirmed) {
				setShowLoading(false)
				return
			}
			//chamar rota do back

			await undoCashInvoice(FormData?.id)
			await loadDataPage()
			showToast('Pagamento desfeito com sucesso!', 'success')
			//setFormData(newForm)
			setShowLoading(false)
		} catch (e: any) {
			setShowLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
	}
	const loadDataPage = async () => {
		try {
			if (!id) return
			setLoading(true)
			let dataForm: IDataLoadForm = await loadDataEditform(parseInt(id))
			let newForm = { ...FormData }
			let payment_method: any = PAYMENT_METHOD.ASK_THE_CUSTOMER
			switch (dataForm.payment_method) {
				case PAYMENT_METHOD.ASK_THE_CUSTOMER:
					payment_method = PAYMENT_METHOD.ASK_THE_CUSTOMER
					break
				case PAYMENT_METHOD.TICKET:
					payment_method = PAYMENT_METHOD.TICKET
					break
				case PAYMENT_METHOD.CREDIT_CARD:
					payment_method = PAYMENT_METHOD.CREDIT_CARD
					break
				case PAYMENT_METHOD.PIX:
					payment_method = PAYMENT_METHOD.PIX
					break
				case PAYMENT_METHOD.MONEY:
					payment_method = PAYMENT_METHOD.MONEY
					break
				default:
					payment_method = PAYMENT_METHOD.ASK_THE_CUSTOMER
					break
			}

			newForm.id = dataForm.id
			newForm.created_at = dataForm.created_at
			newForm.status = dataForm.status
			newForm.business_partner_name = dataForm?.business_partner.name
			newForm.business_partner_id = dataForm?.business_partner?.id
			newForm.subscription_id = dataForm.subscription_id
			newForm.value = dataForm.gross_value / 100
			newForm.due_date = dataForm.due_date
			newForm.payment_method = payment_method
			newForm.interest_percent = dataForm.interest_percent
			newForm.penalty_percent = dataForm.penalty_percent
			newForm.discount_percent = dataForm.discount_percent
			newForm.discount_fixed = dataForm.discount_fixed
			newForm.invoice_ext_url = dataForm.invoice_ext_url
			newForm.bank_ticket_ext_url = dataForm.bank_ticket_ext_url
			newForm.contract_id = dataForm.contract_id
			newForm.description = dataForm.description
			newForm.type = dataForm.type
			newForm.cfg_discount_value = dataForm.cfg_discount_value
			newForm.cfg_discount_type = dataForm.cfg_discount_type
			newForm.cfg_discount_due_date_limit_days =
				dataForm.cfg_discount_due_date_limit_days
			newForm.cfg_penalty_value = dataForm.cfg_penalty_value
			newForm.cfg_penalty_type = dataForm.cfg_penalty_type
			newForm.cfg_interest_value = dataForm.cfg_interest_value
			newForm.cfg_interest_type = dataForm.cfg_interest_type
			setFormData(newForm)
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_code == 'invoice_not_found') {
				navigate('/errors/404')
			}
		}
		setLoading(false)
	}
	const searchUpdateInvoice = async () => {
		try {
			setShowLoading(true)

			await searchUpdateInvoices(FormData?.id)
			await loadDataPage()
			setShowLoading(false)
			showToast('Fatura atualizada com sucesso!', 'success')
		} catch (e: any) {
			setShowLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Erro na atualização da fatura.'
			})
		}
	}
	const renderStatus = (status: number) => {
		let bg = ``
		let text = ``
		switch (status) {
			case INVOICE_STATUS.PENDING:
				bg = `warning`
				text = `Aguardando pagamento`
				break
			case INVOICE_STATUS.AWAITING_RISK_ANALYSIS:
				bg = `warning`
				text = `Pagamento em análise`
				break
			case INVOICE_STATUS.CONFIRMED:
				bg = `primary`
				text = `Pagamento confirmado`
				break
			case INVOICE_STATUS.RECEIVED:
				bg = `success`
				text = `Recebida`
				break
			case INVOICE_STATUS.OVERDUE:
				bg = `danger`
				text = `Vencida`
				break
			case INVOICE_STATUS.REFUND_REQUESTED:
				bg = `info`
				text = `Estorno Solicitado`
				break
			case INVOICE_STATUS.REFUNDED:
				bg = `primary`
				text = `Estornada`
				break
			case INVOICE_STATUS.CHARGEBACK_REQUESTED:
				bg = `primary`
				text = `Recebido chargeback`
				break
			case INVOICE_STATUS.CHARGEBACK_DISPUTE:
				bg = `warning`
				text = `Em disputa de chargeback`
				break
			case INVOICE_STATUS.AWAITING_CHARGEBACK_REVERSAL:
				bg = `warning`
				text = `Disputa vencida, aguardando repasse da adquirente`
				break
		}

		return (
			<SoftBadge pill bg={bg}>
				{text}
			</SoftBadge>
		)
	}
	const renderDate = (dateString: any) => {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}
	useEffect(() => {
		loadDataPage()
	}, [])
	return (
		<Row>
			<Col lg={12}>
				<Form onSubmit={handleSubmit}>
					<Card>
						<Card.Body>
							<div>
								<Row className='d-flex justify-content-end'>
									<Col sm='6'>
										<h5>Fatura: #{id}</h5>
										<div>
											<h6 style={{ color: '#8d99a7' }}>
												{renderDate(FormData?.created_at)}
											</h6>
										</div>
									</Col>
									<Col sm='6' align='end'>
										<div
											style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}
										>
											<h5 style={{ color: '#757e89' }}>Status: </h5>{' '}
											<span style={{ position: 'relative', top: '-2px' }}>
												{renderStatus(FormData?.status)}
											</span>
										</div>
									</Col>
								</Row>
								<Row className='d-flex justify-content-end mt-3'>
									<Col sm='6' className='mt-2'>
										<CustomLinkLeft to='/faturas' />
									</Col>
									<Col sm='6' align='end'>
										<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
											{hasEspecificPermission(
												dataAuth?.user?.access_roles,
												'edit_invoice'
											)}
											{FormData?.type == INVOICE_TYPES?.SUBSCRIPTION ? null : (
												<Button
													className={'me-2'}
													type='button'
													variant='falcon-default'
													onClick={() => {
														setControlShowModal({ show: true })
													}}
													disabled={showLoading}
												>
													<AiFillEdit
														style={{ position: 'relative', top: '-1px' }}
														size={18}
													/>{' '}
													Editar
												</Button>
											)}
											{FormData?.contract_id ? (
												<Button
													className={'me-2'}
													type='button'
													variant='falcon-default'
													onClick={() => {
														window.open(`/contratos/${FormData.contract_id}`, '_blank')
													}}
													disabled={showLoading}
												>
													<BsFillSendFill
														style={{ position: 'relative', top: '-1px' }}
														size={18}
													/>{' '}
													Contrato
												</Button>
											) : null}{' '}
											<Dropdown>
												<Dropdown.Toggle variant='falcon-default' id='dropdown-basic'>
													<AiFillSetting />
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{/* {!FormData?.subscription_id ? (
														<> */}
													<Dropdown.Item
														disabled={showLoading}
														href={FormData?.bank_ticket_ext_url}
														target='_blank'
													>
														{' '}
														<AiOutlinePrinter
															style={{ position: 'relative', top: '-1px' }}
														/>{' '}
														Imprimir boleto
													</Dropdown.Item>
													<Dropdown.Item
														href={FormData?.invoice_ext_url}
														target='_blank'
														disabled={showLoading}
													>
														{' '}
														<GrOverview style={{ position: 'relative', top: '-1px' }} />{' '}
														Visualizar fatura
													</Dropdown.Item>
													<Dropdown.Item
														onClick={searchUpdateInvoice}
														disabled={showLoading}
													>
														{' '}
														<FiRefreshCw style={{ position: 'relative', top: '-1px' }} />{' '}
														Buscar Atualizações
													</Dropdown.Item>
													{/* </>
														) : null} */}
													{FormData.payment_method == PAYMENT_METHOD.MONEY ? (
														<Dropdown.Item disabled={showLoading} onClick={handleUndoCash}>
															{' '}
															<FaUndo style={{ position: 'relative', top: '-1px' }} /> Desfazer
															Recebimento em Dinheiro
														</Dropdown.Item>
													) : (
														<Dropdown.Item
															disabled={showLoading}
															onClick={() => {
																setControlShowModalCash({ show: true })
															}}
														>
															{' '}
															<FaMoneyBillWave
																style={{ position: 'relative', top: '-1px' }}
															/>{' '}
															Recebimento em Dinheiro
														</Dropdown.Item>
													)}

													{hasEspecificPermission(
														dataAuth?.user?.access_roles,
														'delete_invoice'
													) ? (
														<Dropdown.Item
															disabled={showLoading}
															onClick={() => {
																handleDeleteInvoice()
															}}
														>
															{' '}
															<BsFillTrashFill
																style={{ position: 'relative', top: '-1px' }}
															/>{' '}
															Excluir Fatura
														</Dropdown.Item>
													) : null}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</Col>
								</Row>
							</div>
						</Card.Body>
					</Card>

					<Card className='mt-3'>
						<Card.Body>
							<h5>Detalhes</h5>
							<hr />
							<Row>
								<Col>
									{/* <div className='bg-light card-body mt-2'> */}
									<div className=''>
										<Row className='mb-2'>
											<Col lg={'6'}>
												<Form.Group>
													<Form.Label>
														Cliente{' '}
														<FaUserAlt
															style={{
																position: 'relative',
																top: '-2px',
																cursor: 'pointer',
																color: '#2c7be5'
															}}
															onClick={() => {
																window.open(
																	`/clientes/${FormData.business_partner_id}`,
																	'_blank'
																)
															}}
														/>
													</Form.Label>
													<Form.Control
														value={FormData.business_partner_name}
														name='business_partner_name'
														onChange={handleFieldChange}
														type='text'
														disabled={true}
													/>
												</Form.Group>
											</Col>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>Tipo</Form.Label>
													<Form.Control
														value={
															FormData?.type == INVOICE_TYPES?.SUBSCRIPTION
																? 'Assinatura'
																: FormData?.type == INVOICE_TYPES?.RENEGOTIATION
																? 'Renegociação'
																: FormData?.type == INVOICE_TYPES?.MEMBERSHIP_FEE
																? 'Taxa de Adesão'
																: FormData?.type == INVOICE_TYPES?.CANCELLATION_PENALTY
																? 'Multa de Cancelamento'
																: FormData?.type == INVOICE_TYPES?.SINGLE
																? 'Avulsa'
																: ''
														}
														name='holder_dependents_quantity'
														onChange={handleFieldChange}
														type='text'
														disabled={id ? true : false}
													/>
												</Form.Group>
											</Col>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>Valor</Form.Label>
													<Form.Control
														value={FormData?.value?.toLocaleString('pt-BR', {
															style: 'currency',
															currency: 'BRL'
														})}
														name='value'
														type='text'
														disabled={true}
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>Vencimento</Form.Label>
													<Form.Control
														value={FormData?.due_date}
														name='value'
														type='date'
														disabled={true}
													/>
												</Form.Group>
											</Col>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>Forma de pagamento</Form.Label>
													<Form.Select
														name='payment_method'
														disabled={true}
														value={FormData.payment_method}
													>
														<option value=''>Selecionar</option>
														{/* <option value={PAYMENT_METHOD.ASK_THE_CUSTOMER}>
															Pergunte ao cliente
														</option> */}
														<option value={PAYMENT_METHOD.TICKET}>Boleto</option>
														<option value={PAYMENT_METHOD.CREDIT_CARD}>
															Cartão de Crédito
														</option>
														<option value={PAYMENT_METHOD.PIX}>PIX</option>
														<option value={PAYMENT_METHOD.MONEY}>Dinheiro</option>
													</Form.Select>
												</Form.Group>
											</Col>
											<Col lg='6'>
												<Form.Group>
													<Form.Label>Descrição</Form.Label>
													<Form.Control
														value={FormData?.description}
														name='description'
														onChange={() => {}}
														as='textarea'
														disabled
														rows={3}
													/>
												</Form.Group>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					<Row className='mt-3'>
						<Col>
							<Card>
								<Card.Body>
									<h5>Juros, multa e desconto</h5>
									<hr />
									<Row>
										<Col sm='3'>
											<span style={{ fontWeight: 'bold' }}>Juros</span>

											{FormData?.cfg_interest_type == 'PER' ? (
												<div>{FormData?.cfg_interest_value / 100} %</div>
											) : FormData?.cfg_interest_type == 'FIX' ? (
												<div>
													{' '}
													{(FormData?.cfg_interest_value / 100)?.toLocaleString('pt-BR', {
														style: 'currency',
														currency: 'BRL'
													})}
												</div>
											) : null}
										</Col>
										<Col sm='3'>
											<span style={{ fontWeight: 'bold' }}>Multa</span>

											{FormData?.cfg_penalty_type == 'PER' ? (
												<div>{FormData?.cfg_penalty_value / 100} %</div>
											) : FormData?.cfg_penalty_type == 'FIX' ? (
												<div>
													{' '}
													{(FormData?.cfg_penalty_value / 100)?.toLocaleString('pt-BR', {
														style: 'currency',
														currency: 'BRL'
													})}
												</div>
											) : null}
										</Col>
									</Row>
									<Row className='mt-3'>
										<Col sm='3'>
											<span style={{ fontWeight: 'bold' }}>Desconto</span>
											{FormData?.cfg_discount_type == 'PER' ? (
												<div>{FormData?.cfg_discount_value / 100}%</div>
											) : FormData?.cfg_discount_type == 'FIX' ? (
												<div>
													{' '}
													{(FormData?.cfg_discount_value / 100)?.toLocaleString('pt-BR', {
														style: 'currency',
														currency: 'BRL'
													})}
												</div>
											) : null}
										</Col>

										<Col sm='3'>
											<span style={{ fontWeight: 'bold' }}>Prazo Máximo do Desconto</span>
											<div style={{ position: 'relative' }}>
												{FormData?.cfg_discount_due_date_limit_days &&
												FormData?.cfg_discount_due_date_limit_days > 0
													? `Até ${FormData?.cfg_discount_due_date_limit_days} dias antes do vencimento`
													: 'Até o dia do vencimento'}
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<ModalEditInvoice
						show={controlShowModal.show}
						FormData={FormData}
						setFormData={setFormData}
						onClose={() => {
							setControlShowModal({ show: false })
						}}
						loadDataPage={loadDataPage}
					/>
					<ModalConfirmCash
						show={controlShowModalCash.show}
						FormData={FormData}
						setFormData={setFormData}
						onClose={() => {
							setControlShowModalCash({ show: false })
						}}
						loadDataPage={loadDataPage}
					/>
				</Form>
			</Col>
		</Row>
	)
}

export default ViewCharges
