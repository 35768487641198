import { FC, useState, useContext, useEffect } from 'react'
import { Card, Button, Row, Col, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { AiFillSave } from 'react-icons/ai'
import AppContext from 'context/Context'
import CustomLinkLeft from 'components/common/CustomLinkLeft'
import SoftBadge from 'components/common/SoftBadge'
//import useAuth from 'hooks/useAuth'
import { useLoading} from 'context/LoadingBar/LoadingBar'
import {
	IOption,
	IDataPDV,
	IDataPDVLoadDataEdit,
	IFormData,
	// IMembershipTier,
} from './interfaces'
import {
	create,
	loadDataEditform,
	update
} from 'services/vittamais-core/pdv/pdv'
import {
	search
} from 'services/vittamais-core/membership-tier/membership'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
const FormPDVs: FC = function FormPDVs() {
	const {setLoading} = useLoading()
	const { showAlert } = useSweetAlert()
	const { showToast } = useToast()
	//const [optionsPDV, setOptionsPDV] = useState<IOption[]>([])
	//const [optionsAccessRoles, setOptionsAcessRoles] = useState<IOption[]>([])
	const { id } = useParams()
	//const { dataAuth } = useAuth()
	const {
		config: { isDark, isRTL },
		//setConfig
	} = useContext(AppContext)

	const tier_types: any = {
		'1': 'PF',
		'2': 'PJ',
		'3': 'SVA',
	}

	const navigate = useNavigate()

	const [formData, setFormData] = useState<IFormData>({
		name: '',
		status: 0,
		membership_tiers: [],
		created_at: '',
	})

	const [optionsMembership, setOptionsMembership] = useState<any>([]);

	const handleFieldChange = (e: any): void => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const handlePdvs = (e: any): void => {
		setFormData({
			...formData,
			membership_tiers: e
		})
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		try {
			let dataSend: IDataPDV = JSON.parse(JSON.stringify(formData));
			
			dataSend.membership_tiers = dataSend?.membership_tiers?.map(item => item.value);
			
			if (!id) {
				let response = create(dataSend);
				return response.then((e) => {
					if (e?.message_type !== undefined && e?.message_type === 'alert') {
						return showToast(e.message, 'warning');
					} else {
						showToast('PDV criado com sucesso!', 'success');
					}
				});
			} else {
				await update(parseInt(id), dataSend)
				showToast('PDV atualizado com sucesso!', 'success');
			}

			navigate('/pdvs');
		} catch (e) {
			console.log(e);
			showAlert({
				title: 'Ops...!',
				text: 'Aconteceu algo inesperado, verifique os campos e tente novamente.',
				type: 'error'
			});
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '', 
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}

	const loadDataPage = async () => {
		let dataPdvs: IOption[] = [];
		
		let tiers: any = await search({
			'term': ''
		});

		for (let i = 0; i < tiers?.data?.length; i++) {
			let tier = tiers?.data[i];
			dataPdvs.push({
				value: tier.id,
				label: `${tier.name} - ${tier_types[tier.tier_type]}`
			});
		}

		setOptionsMembership(dataPdvs);
		loadDataEditPage();
	}

	const loadDataEditPage = async () => {
		try {
			if (!id) return
			setLoading(true);
			let dataForm = { ...formData }
			let dataEdit: IDataPDVLoadDataEdit = await loadDataEditform(parseFloat(id));

			let membership_tiers: any[] = [];
			for(let i = 0; i < dataEdit.membership_tiers.length; i++) {
				membership_tiers.push({
					value: dataEdit.membership_tiers[i].id,
					label: `${dataEdit.membership_tiers[i].name} - ${tier_types[dataEdit.membership_tiers[i].tier_type]}`
				});
			}

			dataForm.name = dataEdit.name;
			dataForm.status = dataEdit.status;
			dataForm.created_at = dataEdit?.created_at;
			dataForm.membership_tiers = membership_tiers;

			setFormData(dataForm);
		} catch (e) {
			console.log(e)
		}

		setLoading(false)
	}

	useEffect(() => {
		loadDataPage()
	}, [])

	const renderDate = (dateString: any)=> {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}

	const renderStatus = (status: number) => {
		if (status == 1) {
			return (
				<SoftBadge pill bg='success'>
					Ativo
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='danger'>
					Inativo
				</SoftBadge>
			)
		}
	}

	useEffect(() => {
		loadDataPage();
	}, [])

	return (
		<Row>
			<Col md={12}>
				<Form onSubmit={handleSubmit}>
					<Card>
						<Card.Body>
							{!id ? (
								<>
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Adicionar PDV</h5>
										</Col>
										<Col></Col>
									</Row>
									<Row className='mt-4'>
										<Col sm='6'>
											<CustomLinkLeft to='/pdvs' />
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}
											>
												<Button type='submit' variant='falcon-default'>
													<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
												</Button>
											</div>
										</Col>
									</Row>
								</>
							) : (
								<div>
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Editar PDV: #{id}</h5>
											<div>
												<h6 style={{ color: '#8d99a7' }}>
													{renderDate(formData?.created_at)}
												</h6>
											</div>
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}
											>
												<h5 style={{ color: '#757e89' }}>Status: </h5>{' '}
												<span style={{ position: 'relative', top: '-2px' }}>
													{renderStatus(formData?.status)}
												</span>
											</div>
										</Col>
									</Row>
									<Row className='d-flex justify-content-end mt-3'>
										<Col sm='6'>
											<CustomLinkLeft to='/pdvs' />
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}
											>
												<Button type='submit' variant='falcon-default'>
													<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
												</Button>
											</div>
										</Col>
									</Row>
								</div>
							)}
						</Card.Body>
					</Card>

					<Card className='mt-3'>
						<Card.Body>
							<Row>
								<Col>
									<div className=''>
										<Row className='mb-3'>
										<Col sm='6'>
												<Form.Group>
													<Form.Label>Nome</Form.Label>
													<Form.Control
														value={formData.name}
														name='name'
														onChange={handleFieldChange}
														type='text'
													/>
												</Form.Group>
											</Col>
									
											<Col sm='6'>
												<Form.Group>
													<Form.Label>Status</Form.Label>
													<Form.Select
														onChange={handleFieldChange}
														name='status'
														value={formData.status}
													>
														<option value=''>Selecionar</option>
														<option value='2'>Inativo</option>
														<option value='1'>Ativo</option>
													</Form.Select>
												</Form.Group>
											</Col>

											<Col className='mt-3' sm='6'>
												<Form.Group>
													<Form.Label>Planos Disponíveis</Form.Label>
													<Select
														styles={customStyles}
														name='membership_tiers'
														onChange={handlePdvs}
														placeholder='Selecione...'
														isMulti
														options={optionsMembership}
														value={formData.membership_tiers}
													/>
												</Form.Group>
											</Col>

										</Row>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Form>
			</Col>
		</Row>
	)
}

export default FormPDVs
