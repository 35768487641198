interface IDataKinship {
    label: string;
    value: number;
}

const kinshipTypes: IDataKinship[] = [
    {
        value: 1,
        label: "PAI"
    },
    {
        value: 2,
        label: "MÃE"
    },
    {
        value: 3,
        label: "IRMÃ(O)"
    },
    {
        value: 4,
        label: "FILHO(A)"
    },
    {
        value: 5,
        label: "CÔNJUGE"
    },
    {
        value: 6,
        label: "OUTROS"
    },

];

export default kinshipTypes;
