import React, { FC, useContext } from 'react'
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'
import ProfileDropdown from 'components/navbar/top/ProfileDropdown'

// import CartNotification from 'components/navbar/top/CartNotification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from 'context/Context'
import { MdDisplaySettings } from 'react-icons/md'
import useAuth from 'hooks/useAuth'
const TopNavRightSideNavItem: FC = function TopNavRightSideNavItem() {
	const { openSettings } = useAuth()
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	return (
		<Nav
			navbar
			className='navbar-nav-icons ms-auto flex-row align-items-center'
			as='ul'
		>
			<Nav.Item as='li' style={{ position: 'relative', top: '-2px' }}>
				<Nav.Link
					className='px-2 theme-control-toggle'
					onClick={(): void => setConfig('isDark', !isDark)}
				>
					<OverlayTrigger
						key='left'
						placement={isRTL ? 'bottom' : 'left'}
						// delay={{ show: 250, hide: 400 }}
						overlay={
							<Tooltip>
								{isDark ? 'Trocar para modo luz' : 'Trocar para modo escuro'}
							</Tooltip>
						}
					>
						<div className='theme-control-toggle-label'>
							<FontAwesomeIcon
								icon={isDark ? 'sun' : 'moon'}
								className='fs-0'
								color='#2995d3'
							/>
						</div>
					</OverlayTrigger>
				</Nav.Link>
			</Nav.Item>
			<Nav.Item as='li' style={{ position: 'relative', top: '-2px' }}>
				<Nav.Link
					className='px-2 theme-control-toggle'
					onClick={() => {
						openSettings()
					}}
				>
					<MdDisplaySettings size={25} color='#2995d3' />
				</Nav.Link>
			</Nav.Item>

			{/* <CartNotification /> */}
			{/* <NotificationDropdown /> */}
			{/* <NineDotMenu /> */}
			<ProfileDropdown />
		</Nav>
	)
}

export default TopNavRightSideNavItem
