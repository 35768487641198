import request from '../config/request'
import { IPropsUpdatePasswordData, IPropsFilter, IDataUserCreate,IDataUserUpdate } from './interfaces'


const login = async (authData: any) => {
	let response = await request({
		method: 'POST',
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/login`,
		data: authData
	})

	return response
}
const Me = async () => {
	let response = await request({
		method: 'GET',
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/me`,
		vtAuth: true
	})

	return response
}
const updateMyPassword = async (data: IPropsUpdatePasswordData) => {
	let response = await request({
		method: 'POST',
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/me/update-password`,
		vtAuth: true,
		data: data
	})

	return response
}
const search = (filter: IPropsFilter, page = 1, size = 10) => {
	let query = "?page=" + page + "&size=" + size
	if (filter.term)
		query += "&term="
			+ filter.term

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/users` +
			query,
		vtAuth: true
	})
}
const create = async (user_data: IDataUserCreate) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/users`,
		data: user_data,
		vtAuth: true
	});
}
const loadDataEditform = async (id: number) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/users/${id}`,
		vtAuth: true
	});
}
const update = async (id:number, user_data:IDataUserUpdate) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/users/${id}`,
        data: user_data,
        vtAuth: true
    });
}
export { login, Me, updateMyPassword, search, create, loadDataEditform,update }
