import request from '../config/request'
import { IPropsFilter, IDataCreateContract, IBodyToCancel, ICalculateContractValues } from './interfaces'


const search = (filter: IPropsFilter, page = 1, size = 10) => {

	let query = "?page=" + page + "&size=" + size
	if (filter.term)
		query += "&term="
			+ filter.term
	if (filter.business_partner) {
		query += "&business_partner=" + filter.business_partner
	}
	if (filter.membership_tiers) {
		query += "&membership_tiers=" + filter.membership_tiers
	}
	if (filter.pdv) {
		query += "&pdv=" + filter.pdv
	}
	if (filter.status) {
		query += "&status=" + filter.status
	}
	if (filter.contract_type) {
		query += "&contract_type=" + filter.contract_type
	}
	if (filter.tier_type) {
		query += "&tier_type=" + filter.tier_type
	}

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/contracts` +
			query,
		vtAuth: true
	})
}

const searchAddendum = (filter: IPropsFilter, page = 1, size = 10) => {
	let query = "?page=" + page + "&size=" + size
	if (filter.contract_type) {
		query += "&contract_type=" + filter.contract_type
	}
	if (filter.contract_id) {
		query += "&contract_id=" + filter.contract_id
	}
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/contracts` +
			query,
		vtAuth: true
	})
}

const cancelContract = async (id: number, data: IBodyToCancel) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts/cancel/${id}`,
		data: data,
		vtAuth: true,
	});
}

const editContract = async (id: number, data: IDataCreateContract) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts/${id}`,
		data: data,
		vtAuth: true,
	});
}

const editContractAddendum = async (id: number, data: IDataCreateContract) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts-addendum/${id}`,
		data: data,
		vtAuth: true,
	});
}

const createContract = async (data: IDataCreateContract) => {

	// let postData = { ...data };
	// if (postData.recurrency) postData.recurrency = parseInt(postData.recurrency.toString());
	// if (postData.pdv_id) postData.pdv_id = parseInt(postData.pdv_id.toString());
	// if (postData.holder_dependents_quantity) postData.holder_dependents_quantity = parseInt(postData.holder_dependents_quantity.toString());
	// if (postData.payment_method) postData.payment_method = parseInt(postData.payment_method.toString());

	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts`,
		vtAuth: true,
		data: data
	});
}


const createContractAddendum = async (data: IDataCreateContract) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts-addendum`,
		vtAuth: true,
		data: data
	});
}
const loadDataEditform = async (id: any) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts/${id}`,
		vtAuth: true
	});
}
const calculateContractValues = async (info: ICalculateContractValues) => {
	let query = "?membership_tiers_id=" + info.membership_tiers_id
	query += "&recurrency_id=" + info.recurrency_id
	query += "&dependents=" + info.holder_dependents_quantity
	query += "&payment_method_id=" + info.payment_method
	if(info?.additional_products_ids && info?.additional_products_ids?.length > 0)
	{
		query+= "&additional_products_ids=" + info.additional_products_ids.join(",");
	}
	
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts/calculate-value` +
			query,
		vtAuth: true,

	});
}
const sendContract = async (id: number, typeToSend: string) => {

	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts/${id}/send?channel=${typeToSend}`,
		vtAuth: true,
	});
}

const seeSignedContract = async (id: number ) => {

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts/view-signed/${id}`,
		vtAuth: true,
	});
}

const calculateContractValuesAddemdum = async (info: any) => {
	let query = "?membership_tier_id=" + info.membership_tier_id
	query += "&recurrency_id=" + info.recurrency_id
	query += "&holder_dependents_quantity=" + info.holder_dependents_quantity
	query += "&contract_type=" + info.contract_type
	query += "&addendum_type=" + info.addendum_type
	query += "&id=" + info.id
	if(info?.additional_products_ids && info?.additional_products_ids?.length > 0)
	{
		query+= "&additional_products_ids=" + info.additional_products_ids.join(",");
	}
	
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/contracts/calculate-addendum-value` +
			query,
		vtAuth: true,

	});
}


export { search, searchAddendum, cancelContract, createContract, loadDataEditform, calculateContractValues, sendContract, editContract, seeSignedContract, createContractAddendum, editContractAddendum, calculateContractValuesAddemdum }
