import { FC } from 'react'
import { Link } from 'react-router-dom'
import LoginForm from 'components/authentication/LoginForm'
import Snowfall from 'react-snowfall'
import AuthCardLayout from 'layouts/AuthCardLayout'

const Login: FC = function Login() {
	return (
		<AuthCardLayout
			leftSideContent={
				''
				// <p className='text-white'>
				// 	Você não possui uma conta?
				// 	<br />
				// 	<Link
				// 		className='text-white text-decoration-underline'
				// 		to='/register'
				// 	>
				// 		Quero criar!
				// 	</Link>
				// </p>
			}
			footer={false}
		>
			{/* <div
				style={{
					height: '100%',
					width: '100%'
				}}
			>
				<Snowfall color='#5ac5fa' style={{ position: 'fixed' }} />
			</div> */}
			<h3>Conta de Acesso</h3>
			<LoginForm />
		</AuthCardLayout>
	)
}

export default Login
