import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useLoading } from 'context/LoadingBar/LoadingBar'
const loadingAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`

const BarContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background-color: #f0f0f0;
	z-index: 9999;
	overflow: hidden;
`

const Bar = styled.div<{ progress: number }>`
	width: ${props => props.progress}%
		${props => (props.progress == 100 ? '!important' : '')};
	height: 100%;
	background-color: #007bff;
	animation: ${loadingAnimation} 3s linear infinite;
`

const CustomLoadingBar: React.FC = () => {
	const { isLoading } = useLoading()
	const [showBar, setShowBar] = useState(false)

	useEffect(() => {
		if (isLoading) {
			setShowBar(true)
		}
		if (!isLoading && showBar) {
			setTimeout(() => {
				setShowBar(false)
			}, 300)
		}
	}, [isLoading])

	return (
		<>
			{showBar ? (
				<BarContainer>
					<Bar progress={isLoading ? 0 : 100} />
				</BarContainer>
			) : null}
		</>
	)
}

export default CustomLoadingBar
