import { FC, useState, useEffect } from 'react'
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap'
import { AiFillSave } from 'react-icons/ai'
import { Me,updateMyPassword } from 'services/vittamais-core/user/user'
import { IResponseData } from './interfaces'
import useSweetAlert from 'context/Alerts/SweetAlert'
import  {useToast}  from 'context/Toasts/Toastify'
const MyProfile: FC = function MyProfile() {

	const {showAlert} = useSweetAlert();
	const {showToast} = useToast();

	const [formData, setFormData] = useState({
		password: '',
		newPassword: '',
		confirmNewPassword: '',
		name: '',
		email: '',
		username:''
	})
	const [controlSpiner, setControlSpiner] = useState({
		loading: false
	})
	const handleFieldChange = (e: any): void => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		try
		{
			if(!formData.confirmNewPassword || !formData.newPassword || !formData.password)
			{
				showAlert({type:"info",title:"Atenção!",text:"É necessário informar todos os campos de alteração de senha..."});
				return;
			}
			if(formData.confirmNewPassword != formData.newPassword)
			{
				showAlert({type:"info",title:"A nova senha e a confirmação da nova senha precisam ser iguais!",text:''});
				return;
			}
			let dataToSend = {
				lastPassword:formData.password,
				newPassword:formData.newPassword,
				confirmNewPassword:formData.confirmNewPassword
			}
			await updateMyPassword(dataToSend);
			showToast('Senha alterada com sucesso!','success');
			//limpar form
			let newData = {...formData};
			newData.confirmNewPassword = '';
			newData.newPassword = '';
			newData.password = '';
			setFormData(newData);
		}
		catch(e)
		{
			console.log(e);
			showAlert({type:"error",title:"Ops...!",text:"Ocorreu algo inesperado."});
		}
	}

	useEffect(() => {
		loadDataPage()
	}, [])

	const loadDataPage = async () => {
		setControlSpiner({
			loading: true
		})
		try {
			let meData: IResponseData = await Me()

			let newData = { ...formData }
			newData.name = meData.name
			newData.email = meData.email
			newData.username = meData.username
			setFormData(newData)
		} catch (e) {
			console.log(e)
		}
		setControlSpiner({
			loading: false
		})
	}
	if (controlSpiner.loading) {
		return (
			<div style={{position:"absolute",top:"25%",left:"49%"}}>
				{' '}
				<Spinner animation='border' variant='info' role='status'>
					<span className='visually-hidden'>Loading...</span>
				</Spinner>
			</div>
		)
	}
	return (
		<Row>
			<Col md={12}>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md='6'>
							<Card>
								<Card.Body>
									<Row className='mb-4'>
										<Col>
											{' '}
											<h5 style={{ fontSize: 18 }}>Informações Básicas</h5>
											<h6 style={{ fontSize: 12 }}>Dados principais do usuário</h6>
										</Col>{' '}
										<Row>
											<Col sm='12'>
												<Form.Group>
													<Form.Label>Usuário</Form.Label>
													<Form.Control
														value={formData.username}
														name='username'
														onChange={handleFieldChange}
														type='text'
														disabled
													/>
												</Form.Group>
											</Col>
											<Col sm='12'>
												<Form.Group>
													<Form.Label>Nome</Form.Label>
													<Form.Control
														value={formData.name}
														name='name'
														onChange={handleFieldChange}
														type='text'
														disabled
													/>
												</Form.Group>
											</Col>
											<Col sm='12'>
												<Form.Group>
													<Form.Label>E-mail</Form.Label>
													<Form.Control
														value={formData.email}
														name='email'
														onChange={handleFieldChange}
														type='text'
														disabled
													/>
												</Form.Group>
											</Col>
										</Row>
									</Row>
								</Card.Body>
							</Card>
						</Col>

						<Col md='6'>
							<Card>
								<Card.Body>
									<Row>
										<Col>
											{' '}
											<h5 style={{ fontSize: 18 }}>Alteração de Senha</h5>
											<h6 style={{ fontSize: 12 }}>Informe os dados para prosseguir</h6>
										</Col>{' '}
									</Row>
									<Row>
										<Col sm='12'>
											<Form.Group>
												<Form.Label>Senha</Form.Label>
												<Form.Control
													value={formData.password}
													name='password'
													onChange={handleFieldChange}
													type='password'
												/>
											</Form.Group>
										</Col>
										<Col sm='12'>
											<Form.Group>
												<Form.Label>Nova senha</Form.Label>
												<Form.Control
													value={formData.newPassword}
													name='newPassword'
													onChange={handleFieldChange}
													type='password'
												/>
											</Form.Group>
										</Col>
										<Col sm='12'>
											<Form.Group>
												<Form.Label>Confirmar nova senha</Form.Label>
												<Form.Control
													value={formData.confirmNewPassword}
													name='confirmNewPassword'
													onChange={handleFieldChange}
													type='password'
												/>
											</Form.Group>
										</Col>
									</Row>
									<Row className='d-flex justify-content-end mt-2'>
										<Col sm='3' align='end'>
											<Form.Group>
												<Button type='submit' color='primary'>
													<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
												</Button>
											</Form.Group>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Form>
			</Col>
		</Row>
	)
}

export default MyProfile
