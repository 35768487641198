import { FC, useState, useEffect } from 'react'
import {
	Card,
	Table,
	Button,
	Row,
	Col,
	InputGroup,
	FormGroup,
	FormControl
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import CustomPagination from 'components/common/CustomPagination'
import CustomLoading from 'components/common/CustomLoading'
import SoftBadge from 'components/common/SoftBadge'
import {
	search,
	cancelContract
} from 'services/vittamais-core/contract/contract'
import { IResponseSearchData, IDataContract } from './interfaces'
import CONTRACT_STATUS from 'helpers/contractStatus'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import RECURRENCY_TYPE from 'helpers/recurrencyTypes'
import FilterDropdown from './components/FilterDropdown'
import MEMBERSHIP_TYPES from 'helpers/membershipTypes'
const ListContracts: FC = function ListContracts() {
	const navigate = useNavigate()
	const [listData, setListData] = useState({
		total_items: 0,
		total_pages: 0,
		current_page: 1,
		data: []
	})
	const [showLoading, setShowLoading] = useState(false)
	const [filtersData, setFiltersData] = useState<any>({
		show: false,
		bp_id: '',
		status: '',
		membership_tier_id: '',
		pdv_id: '',
		find_text: '',
		contract_type: 'contract'
	})
	const searchContracts = async (page = 1, sizePerPage = 10) => {
		setShowLoading(true)
		try {
			let filter = {
				term: filtersData.find_text,
				membership_tiers: filtersData?.membership_tier_id?.value,
				pdv: filtersData?.pdv_id,
				business_partner: filtersData?.bp_id?.value,
				status: filtersData?.status,
				contract_type: filtersData?.contract_type
			}

			let data: IResponseSearchData = await search(filter, page, sizePerPage)

			setListData({
				total_items: data.total_items,
				total_pages: data.total_pages,
				current_page: data.current_page,
				data: data.data
			})
		} catch (e) {
			console.log(e)
		}
		setShowLoading(false)
	}

	const renderStatus = (status: number) => {
		if (status == CONTRACT_STATUS.CREATED) {
			return (
				<SoftBadge pill bg='primary'>
					Criado
				</SoftBadge>
			)
		} else if (status == CONTRACT_STATUS.WAITING_SIGN) {
			return (
				<SoftBadge pill bg='info'>
					Aguardando Assinatura
				</SoftBadge>
			)
		} else if (status == CONTRACT_STATUS.SIGNED) {
			return (
				<SoftBadge pill bg='success'>
					Assinado
				</SoftBadge>
			)
		} else if (status == CONTRACT_STATUS.DEFAULTER) {
			return (
				<SoftBadge pill bg='warning'>
					Inadimplente
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='danger'>
					Cancelado
				</SoftBadge>
			)
		}
	}

	const renderPaymentMethod = (payment_method: number) => {
		if (payment_method == PAYMENT_METHOD.ASK_THE_CUSTOMER) {
			return <span>Pergunte ao cliente</span>
		} else if (payment_method == PAYMENT_METHOD.CREDIT_CARD) {
			return <span>Cartão de Crédito</span>
		} else if (payment_method == PAYMENT_METHOD.PIX) {
			return <span>PIX</span>
		} else if (payment_method == PAYMENT_METHOD.MONEY) {
			return <span>Dinheiro</span>
		} else {
			return <span>Boleto</span>
		}
	}
	const renderRecurrency = (recurrency: number) => {
		if (recurrency == RECURRENCY_TYPE.MONTHLY) {
			return <span>Mensal</span>
		} else {
			return <span>Anual</span>
		}
	}
	// const handleCancelContract = async (id:number)=>
	// {
	// try
	// {
	// 	if(!id) return;
	// 	let confirmed =	await showConfirm({title:"Atenção!",text:"Tem certeza que deseja cancelar este contrato?"})
	// 	if(!confirmed) return;
	// 	await cancelContract(id);
	// 	showToast('Contrato cancelado com sucesso!','success');
	// 	if(!inputSearchData || inputSearchData == '')
	// 	{
	// 		searchContracts(listData.current_page);
	// 	}
	// 	else
	// 	{
	// 		searchContracts();
	// 	}
	// }
	// catch(e)
	// {
	// console.log(e);
	// showAlert({type:"warning",title:"Ops...!",text:"Não conseguimos cancelar este contrato, tente novamente."})
	// }
	// }
	const handleTierTypes = (type: any) => {
		let string = ''
		if (type == MEMBERSHIP_TYPES.PF) {
			string = ' PF'
		} else if (type == MEMBERSHIP_TYPES.PJ) {
			string = ' PJ'
		} else if (type == MEMBERSHIP_TYPES.SVA) {
			string = ' SVA'
		}

		return string
	}

	const handleTierTypeName = (item: any) => {
		if (item?.membership_tier?.name)
			return (
				item?.membership_tier?.name +
				handleTierTypes(item?.membership_tier?.tier_type)
			)
		else return ''
	}

	const handleTierTypeRecurrency = (item: any) => {
		return renderRecurrency(item?.recurrency)
	}

	useEffect(() => {
		searchContracts()
	}, [])
	return (
		<Row>
			<Col md={12}>
				<Card>
					<Card.Body>
						<Row>
							<Col>
								{' '}
								<h5 style={{ fontSize: 18 }}>Lista de contratos </h5>
							</Col>{' '}
							{/* <Col align='end'>
								<Button
									onClick={() => {
										navigate('/usuarios/adicionar')
									}}
								>
									<FontAwesomeIcon icon='user-plus' /> Adicionar
								</Button>
							</Col> */}
						</Row>
						<div
							className='mt-4 mb-2'
							style={{
								display: 'flex',
								gap: '15px',
								flexDirection: 'row',
								flexFlow: 'wrap'
							}}
						>
							<div>
								{' '}
								<FilterDropdown
									searchContracts={searchContracts}
									filtersData={filtersData}
									setFiltersData={setFiltersData}
								/>
							</div>
							<div style={{ width: '50%' }}>
								<InputGroup>
									<FormControl
										placeholder='Procurar por nome ou cpf/cnpj do cliente ou id do contrato'
										aria-label="Recipient's username with two button addons"
										onChange={(e: any) => {
											setFiltersData({ ...filtersData, find_text: e?.target?.value })
										}}
										onKeyDown={(e)=>{
											if(e.keyCode == 13 && !e.repeat)
											{
												searchContracts()
											}
										}}
										value={filtersData.find_text}
									/>
									<Button
										variant='outline-primary'
										onClick={() => {
											searchContracts()
										}}
									>
										<BiSearch size={20} />
									</Button>
								</InputGroup>
							</div>
							<div style={{ marginLeft: 'auto' }}>
								{' '}
								<Button
									variant='falcon-default'
									onClick={() => {
										navigate('/contratos/adicionar')
									}}
									style={{ position: 'relative', top: '-1px' }}
								>
									<FontAwesomeIcon icon='file' /> Adicionar
								</Button>
							</div>
						</div>
					</Card.Body>
				</Card>
				<Card className='mt-3'>
					<Card.Body>
						{showLoading ? (
							<CustomLoading />
						) : (
							<div>
								<Table responsive striped>
									<thead>
										<tr>
											<th scope='col'>ID</th>
											{/* <th scope='col'>Usuário</th> */}
											<th scope='col'>Cliente</th>
											<th scope='col'>Plano</th>
											<th scope='col'>Ponto de venda</th>
											<th scope='col'>Recorrência</th>
											{/* <th scope='col'>Método de pagamento</th> */}
											<th scope='col'>Criado em</th>
											<th scope='col'>Status</th>
											<th className='text-end' scope='col'>
												Ações
											</th>
										</tr>
									</thead>
									<tbody>
										{listData?.data?.length > 0
											? listData?.data?.map((item: IDataContract, index: number) => (
													<tr key={item?.id}>
														<td>{item?.id}</td>
														{/* <td>{item?.user?.name}</td> */}
														<td>{item?.business_partner?.name}</td>
														<td>{handleTierTypeName(item)}</td>
														<td>{item?.pdv?.name}</td>
														<td>{handleTierTypeRecurrency(item)}</td>
														{/* <td>{renderPaymentMethod(item.payment_method)}</td> */}
														<td>
															{item?.created_at
																? // new Date(item?.created_at).toLocaleDateString("pt-BR", {
																  // 	hour: "2-digit",
																  // 	hour12: false,
																  // 	minute: "2-digit",
																  // 	second: "2-digit",
																  // })
																  new Date(item?.created_at).toLocaleDateString('pt-BR')
																: null}
														</td>
														<td>{renderStatus(item?.status)}</td>
														<td className='text-end'>
															<FontAwesomeIcon
																icon={'eye'}
																style={{
																	color: '#2c7be5',
																	fontSize: '20px',
																	cursor: 'pointer',
																	position: 'relative',
																	left: '-10px'
																}}
																onClick={() => {
																	if(item.addendum_id){
																		navigate(`/contratos/${item.addendum_id}/aditivo/${item?.id}`)
																	}else{
																		navigate(`/contratos/${item?.id}`)
																	}
																	
																}}
															/>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<CustomPagination
									data={listData}
									handlePageChange={e => {
										searchContracts(e)
									}}
								/>
							</div>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default ListContracts
