import request from '../config/request'
import { FormDataProps } from './interface'


const importHoldersData = async (contract_id: number, file: any) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/upload/${contract_id}`,
        data: file,
        vtAuth: true,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
export { importHoldersData }
