import { FC, useState, useContext,useEffect } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import AppContext from 'context/Context'
import Select from 'react-select'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { search as serachUsers } from '../../../services/vittamais-core/user/user'
import { search as searchBusinessPartners } from '../../../services/vittamais-core/customer/customer'
import { TbReportSearch } from 'react-icons/tb'
import SUBSCRIPTION_STATUS from 'helpers/subscriptionStatus'
import CONTRACT_STATUS from 'helpers/contractStatus'
import { exportContracts } from 'services/vittamais-core/reports/contract/contract'
import { hasEspecificPermission } from 'helpers/hasEspecificPermission'
const today = new Date().toLocaleDateString('pt-BR')
const todayOnlyDate =
	today.substr(6, 4) + '-' + today.substr(3, 2) + '-' + today.substr(0, 2)

const IndexReportContracts: FC = function IndexReportContracts() {
	const { dataAuth } = useAuth()
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}
	const [showLoading, setShowLoading] = useState(false)
	const [hasUserPermission,setHasUserPermission] = useState<any>({
	has:true,
	})
	const [FormData, setFormData] = useState<any>({
		user_id: {
			label: 'Todos',
			value: ''
		},
		bp_id: {
			label: 'Todos',
			value: ''
		},
		pdvs: [
			{
				label: 'Todos',
				value: ''
			}
		],
		start_created_at: todayOnlyDate,
		end_created_at: todayOnlyDate,
		start_signed_at: '',
		end_signed_at: '',
		start_canceled_at: '',
		end_canceled_at: '',
		subscription_status: [
			{
				label: 'Todos',
				value: ''
			}
		],
		contract_status: [
			{
				label: 'Todos',
				value: ''
			}
		]
	})
	const handleFieldChange = (e: any): void => {
		setFormData({
			...FormData,
			[e.target.name]: e.target.value
		})
	}
	const handleSelectsChanges = (name: string, e: any): void => {
		setFormData({
			...FormData,
			[name]: e
		})
	}
	const loadUsers = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					{
						label: 'Todos',
						value: ''
					}
				]

				let response: any = await serachUsers({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label: element.name + ' - e-mail: ' + element?.email,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsUsers: any = debounce(loadUsers, 600)
	const loadBpInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					{
						label: 'Todos',
						value: ''
					}
				]

				let response: any = await searchBusinessPartners({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					let cgc =
						element?.person_type == 1
							? `CPF: ${element?.cpf} - `
							: `CNPJ: ${element?.cnpj} `
					options.push({
						label: element.name + ' - ' + cgc + 'E-mail: ' + element?.email,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}
	const debounceOptionsBp: any = debounce(loadBpInputOptions, 600)
	const handleSubmit = async () => {
		setShowLoading(true)
		try {
			let dataToSend = {
				user_id: FormData?.user_id?.value,
				pdv_id: FormData?.pdvs
					?.filter((item: any) => item?.value != '')
					?.map((item: any) => item?.value),
				subscription_status: FormData?.subscription_status
					?.filter((item: any) => item?.value != '')
					?.map((item: any) => item?.value),
				contract_status: FormData?.contract_status
					?.filter((item: any) => item?.value != '')
					?.map((item: any) => item?.value),
				bp_id: FormData?.bp_id?.value,
				start_created_at: FormData.start_created_at,
				end_created_at: FormData.end_created_at,
				start_signed_at: FormData.start_signed_at,
				end_signed_at: FormData.end_signed_at,
				start_canceled_at: FormData.start_canceled_at,
				end_canceled_at: FormData.end_canceled_at
			}

			let response = await exportContracts(dataToSend)

			var headers = response.headers

			var blob = new Blob([response.data], {
				type: headers['content-type']
			})
			var link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'Contratos'
			link.click()
		} catch (e) {
			console.log(e)
		}
		setShowLoading(false)
	}
	const loadData = ()=>
	{
	let hasPermission =	hasEspecificPermission(
			dataAuth?.user?.access_roles,
			'view_user'
		)
		if(!hasPermission)
		{
			setHasUserPermission({
				has:false,
			})
			
			let form = {...FormData};
			form.user_id = {label:dataAuth?.user?.name,value:dataAuth?.user?.id}
			setFormData(form);
		}
	}
	useEffect(() => {
		loadData();
	}, [])


	return (
		<Row className='justify-content-center'>
			<Col md={7} xs={12}>
				<Card>
					<Card.Body>
						<Row>
							<Col sm='6'>
								<h5 style={{ fontSize: 18 }}>Contratos</h5>
							</Col>
						</Row>
						<hr />
						<Row className='mt-2'>
							<Col md={12}>
								<Form.Group>
									<Form.Label>PDV</Form.Label>
									<Select
										styles={customStyles}
										name='pdvs'
										onChange={(e: any) => {
											handleSelectsChanges('pdvs', e)
										}}
										placeholder='Selecione...'
										isMulti
										options={dataAuth?.user?.pdvs?.map((pdv: any) => ({
											label: pdv.name,
											value: pdv.id
										}))}
										value={FormData.pdvs}
										noOptionsMessage={() => 'Sem Resultados.'}
									/>
								</Form.Group>
							</Col>
							<Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Consultor</Form.Label>
									{hasUserPermission?.has ? <AsyncSelect
										styles={customStyles}
										name='user_id'
										placeholder='Selecionar Consultor'
										isClearable
										defaultOptions
										loadOptions={debounceOptionsUsers}
										onChange={(e: any) => {
											handleSelectsChanges('user_id', e)
										}}
										value={FormData.user_id}
										noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
									/> : <AsyncSelect
									// styles={customStyles}
									name='user_id'
									placeholder='Selecionar Consultor'
									isDisabled
									loadOptions={debounceOptionsUsers}
									onChange={(e: any) => {
										handleSelectsChanges('user_id', e)
									}}
									value={FormData.user_id}
									noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
								/>}
									
								</Form.Group>
							</Col>
							<Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Cliente</Form.Label>
									<AsyncSelect
										styles={customStyles}
										name='bp_id'
										placeholder='Selecionar cliente'
										isClearable
										defaultOptions
										loadOptions={debounceOptionsBp}
										onChange={(e: any) => {
											handleSelectsChanges('bp_id', e)
										}}
										value={FormData.bp_id}
										noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
									/>
								</Form.Group>
							</Col>
							<Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data criação inicial</Form.Label>
									<Form.Control
										value={FormData.start_created_at}
										name='start_created_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col>
							<Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data criação final</Form.Label>
									<Form.Control
										value={FormData.end_created_at}
										name='end_created_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col>
							{/* <Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data assinatura inicial</Form.Label>
									<Form.Control
										value={FormData.start_signed_at}
										name='start_signed_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col>
							<Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data assinatura final</Form.Label>
									<Form.Control
										value={FormData.end_signed_at}
										name='end_signed_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col> */}
							<Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data cancelamento inicial</Form.Label>
									<Form.Control
										value={FormData.start_canceled_at}
										name='start_canceled_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col>
							<Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data cancelamento final</Form.Label>
									<Form.Control
										value={FormData.end_canceled_at}
										name='end_canceled_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col>
							{/* <Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Status Assinatura</Form.Label>
									<Select
										styles={customStyles}
										name='subscription_status'
										onChange={(e: any) => {
											handleSelectsChanges('subscription_status', e)
										}}
										placeholder='Selecione...'
										isMulti
										options={[
											{
												label: 'Todos',
												value: ''
											},
											{
												label: 'Ativo',
												value: SUBSCRIPTION_STATUS.ACTIVATED
											},
											{
												label: 'Desativado',
												value: SUBSCRIPTION_STATUS.DISABLED
											},
											{
												label: 'Cancelado',
												value: SUBSCRIPTION_STATUS.CANCELED
											}
										]}
										value={FormData.subscription_status}
										noOptionsMessage={() => 'Sem Resultados.'}
									/>
								</Form.Group>
							</Col> */}
							<Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Status Contrato</Form.Label>
									<Select
										styles={customStyles}
										name='contract_status'
										onChange={(e: any) => {
											handleSelectsChanges('contract_status', e)
										}}
										placeholder='Selecione...'
										isMulti
										options={[
											{
												label: 'Todos',
												value: ''
											},
											{
												label: 'Cancelado',
												value: CONTRACT_STATUS.CANCELED
											},
											{
												label: 'Criado',
												value: CONTRACT_STATUS.CREATED
											},
											{
												label: 'Assinado',
												value: CONTRACT_STATUS.SIGNED
											},
											{
												label: 'Aguardando Assinatura',
												value: CONTRACT_STATUS.WAITING_SIGN
											},
											{
												label: 'Inadimplente',
												value: CONTRACT_STATUS.DEFAULTER
											}
										]}
										value={FormData.contract_status}
										noOptionsMessage={() => 'Sem Resultados.'}
									/>
								</Form.Group>
							</Col>
							<Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Formato de saída</Form.Label>
									<Select
										// styles={customStyles}
										name='contract_status'
										placeholder='Selecione...'
										isDisabled
										value={{ label: 'Excel', value: 'excel' }}
										noOptionsMessage={() => 'Sem Resultados.'}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<p/>
							<hr />
						<Col align='end'>
								<Button
									onClick={() => {
										handleSubmit()
									}}
									variant='falcon-default'
									size='sm'
									style={{ position: 'relative', top: '-3px' }}
									disabled={showLoading}
								>
									{showLoading ? 'Emitindo...' : 'Emitir Relatório'}{' '}
									<TbReportSearch
										size={15}
										style={{ position: 'relative', top: '-2px' }}
									/>
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default IndexReportContracts
