import { FC } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { FiExternalLink } from 'react-icons/fi'
const Help: FC = function Help() {
	return (
		<Row className=''>
			<Col md={12} xs={12}>
				<Card>
					<Card.Body>
						<h4 className=''>Ajuda</h4>
						<Row className='mt-2'>
							<Col>
								<small style={{ fontWeight: 'bold' }} className='text-info'>
									Abaixo você encontra links para acessar os vídeos de treinamento.
									Coloquem a qualidade em 1080p HD.
								</small>
							</Col>
						</Row>
						<Row className='mt-4'>
							<Col>
								<p>
									<a
										href='https://drive.google.com/file/d/1UCD6GBZYPHfKoIpOgX9NY-CpSlrweD6W/view?usp=sharing'
										target='_blank'
									>
										Gestão de Contratos{' '}
										<FiExternalLink style={{ position: 'relative', top: '-2px' }} />
									</a>
								</p>
                                <p>
									<a
										href='https://drive.google.com/file/d/1hziQ1mMwFgQUoBzGvS0qYLgN0K6TKu7R/view?usp=sharing'
										target='_blank'
									>
										Gestão de Faturas{' '}
										<FiExternalLink style={{ position: 'relative', top: '-2px' }} />
									</a>
								</p>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default Help
