interface IData {
    id: number;
    name: string;
}

const reasons: IData[] = [
    {
		id:1,
		name:"Benefícios Vittá"
	},
	{
		id:11,
		name:"Benefícios Lecupon"
	},
	{
		id:2,
		name:"Erro no Contrato"
	},
	{
		id:3,
		name:"Financeiro"
	},
	{
		id:4,
		name:"Forma de Pagamento"
	},
	{
		id:5,
		name:"Insatisfação Atendimento"
	},
	{
		id:6,
		name:"Insatisfação Médica"
	},
	{
		id:7,
		name:"Mudou de Cidade"
	},
	{
		id:8,
		name:"Plano de Saúde"
	},
	{
		id:9,
		name:"Outros"
	},
	{
		id:10,
		name:"Sem Retorno"
	},
	{
		id: 12,
		name:"Inadimplencia com mais de 60 dias"
	},
	{
		id: 13,
		name:"Não pagou taxa adesão"
	}
];

export default reasons;
