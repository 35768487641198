import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const Error404: FC = function Error404() {
	return (
		<Card className='text-center'>
			<Card.Body className='p-5'>
				<div className='display-1 text-300 fs-error'>404</div>
				<p className='lead mt-4 text-800 font-sans-serif fw-semi'>
					Não encontramos essa página!
				</p>
				<hr />
				{/* <p>
					Make sure the address is correct and that the page hasn&apos;t moved. If
					you think this is a mistake,
					<a href='mailto:info@exmaple.com' className='ms-1'>
						contact us
					</a>
					.
				</p> */}
				<Link className='btn btn-primary btn-sm mt-3' to='/'>
					<FontAwesomeIcon icon={faHome as IconProp} className='me-2' />
					Voltar ao início
				</Link>
			</Card.Body>
		</Card>
	)
}

export default Error404
