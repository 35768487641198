import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async'

interface AdditionalProductsProps {
  styles: any
  isDisabled: boolean
  loadOptions: any
  value: any
  onChange: any
}

export function AdditionalProducts({ styles, isDisabled, loadOptions, value, onChange }: AdditionalProductsProps) {
  return (
    <Form.Group>
      <Form.Label>Produtos Adicionais</Form.Label>
      <AsyncSelect
        styles={styles}
        name='additional_products'
        placeholder='Selecionar produtos adicionais'
        isMulti
        isClearable
        defaultOptions
        isDisabled={isDisabled}
        loadOptions={loadOptions}
        onChange={onChange}
        value={value}
        noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
      />
    </Form.Group>
  )
}