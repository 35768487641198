export const hasEspecificPermission = (
	access_roles: any[],
	permission?: string
) => {
	let found = false
	for (let i = 0; i < access_roles?.length; i++) {
		let record = access_roles[i]
		for (let j = 0; j < record?.permissions?.length; j++) {
			let result = record?.permissions[j]
			if (result.name == permission) {
				found = true
				break
			}
		}
	}

	if (found) {
		return true
	} else {
		return false
	}
}
