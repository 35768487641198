import { FC, useState, useEffect } from 'react'
import {
	Card,
	Table,
	Button,
	Row,
	Col,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import { search } from 'services/vittamais-core/invoice/invoice'
import { IDataSearchInvoice, IDataInvoice } from './interfaces'
import CustomPagination from 'components/common/CustomPagination'
import SoftBadge from 'components/common/SoftBadge'
import INVOICE_STATUS from 'helpers/invoiceStatus'
import CustomLoading from 'components/common/CustomLoading'
import FilterDropdown from './components/FilterDropdown'
import { useSearchParams } from 'react-router-dom'
import INVOICE_TYPES from 'helpers/invoiceTypes'
const ListCharges: FC = function ListCharges() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const [listData, setListData] = useState<IDataSearchInvoice>({
		total_items: 0,
		total_pages: 0,
		current_page: 1,
		data: []
	})
	const [showLoading, setShowLoading] = useState(false)
	const [filtersData, setFiltersData] = useState<any>({
		show: false,
		due_date_init: '',
		due_date_final: '',
		status: '',
		signature_id: '',
		contract_id: '',
		find_text: '',
		pdv_id: ''
	})

	const searchInvoices = async (
		page = 1,
		sizePerPage = 10,
		contractByQuery?: number
	) => {
		setShowLoading(true)
		try {
			let filter = {
				term: filtersData.find_text,
				status: filtersData?.status,
				due_date_init: filtersData?.due_date_init,
				due_date_final: filtersData?.due_date_final,
				contract: contractByQuery
					? contractByQuery
					: filtersData?.contract_id?.value,
				business_partner: filtersData?.bp_id?.value,
				subscription: filtersData?.signature_id?.value,
				pdv_id: filtersData?.pdv_id
			}

			let data: IDataSearchInvoice = await search(filter, page, sizePerPage)

			setListData({
				total_items: data.total_items,
				total_pages: data.total_pages,
				current_page: data.current_page,
				data: data.data
			})
		} catch (e) {
			console.log(e)
		}
		setShowLoading(false)
	}

	function convertDate(inputData: string) {
		const parts = inputData.split('-')

		const day = parts[2]
		const month = parts[1]
		const year = parts[0]

		const formattedDate = `${day}/${month}/${year}`

		return formattedDate
	}
	const renderStatus = (status: number) => {
		let bg = ``
		let text = ``
		switch (status) {
			case INVOICE_STATUS.PENDING:
				bg = `warning`
				text = `Aguardando pagamento`
				break
			case INVOICE_STATUS.AWAITING_RISK_ANALYSIS:
				bg = `warning`
				text = `Pagamento em análise`
				break
			case INVOICE_STATUS.CONFIRMED:
				bg = `primary`
				text = `Pagamento confirmado`
				break
			case INVOICE_STATUS.RECEIVED:
				bg = `success`
				text = `Recebida`
				break
			case INVOICE_STATUS.OVERDUE:
				bg = `danger`
				text = `Vencida`
				break
			case INVOICE_STATUS.REFUND_REQUESTED:
				bg = `info`
				text = `Estorno Solicitado`
				break
			case INVOICE_STATUS.REFUNDED:
				bg = `primary`
				text = `Estornada`
				break
			case INVOICE_STATUS.CHARGEBACK_REQUESTED:
				bg = `primary`
				text = `Recebido chargeback`
				break
			case INVOICE_STATUS.CHARGEBACK_DISPUTE:
				bg = `warning`
				text = `Em disputa de chargeback`
				break
			case INVOICE_STATUS.AWAITING_CHARGEBACK_REVERSAL:
				bg = `warning`
				text = `Disputa vencida, aguardando repasse da adquirente`
				break
		}

		return (
			<SoftBadge pill bg={bg}>
				{text}
			</SoftBadge>
		)
	}
	const renderDate = (dateString: any) => {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}
	const renderTypeInvoice = (status: number) => {
		let bg = ``
		let text = ``
		switch (status) {
			case INVOICE_TYPES.SUBSCRIPTION:
				bg = `success`
				text = `Assinatura`
				break
			case INVOICE_TYPES.RENEGOTIATION:
				bg = `info`
				text = `Renegociação`
				break
			case INVOICE_TYPES.MEMBERSHIP_FEE:
				bg = `primary`
				text = `Taxa de Adesão`
				break
			case INVOICE_TYPES.CANCELLATION_PENALTY:
				bg = `danger`
				text = `Multa de Cancelamento`
				break
			case INVOICE_TYPES.SINGLE:
				bg = `warning`
				text = `Avulsa`
				break
		}

		return (
			<SoftBadge pill bg={bg}>
				{text}
			</SoftBadge>
		)
	}
	useEffect(() => {
		let contractByQuery: any = searchParams.get('contract_id')
		if (contractByQuery) {
			setFiltersData({
				...filtersData,
				contract_id: {
					label: `Contrato: - #${contractByQuery}`,
					value: contractByQuery
				}
			})
		}

		searchInvoices(1, 10, contractByQuery)
	}, [])
	return (
		<Row>
			<Col md={12}>
				<Card>
					<Card.Body>
						<Row>
							<Col>
								{' '}
								<h5 style={{ fontSize: 18 }}>Lista de faturas </h5>
							</Col>{' '}
							{/* <Col align='end'>
								<Button
									onClick={() => {
										navigate('/usuarios/adicionar')
									}}
								>
									<FontAwesomeIcon icon='user-plus' /> Adicionar
								</Button>
							</Col> */}
						</Row>
						<div
							className='mt-4 mb-2'
							style={{
								display: 'flex',
								gap: '15px',
								flexDirection: 'row',
								flexFlow: 'wrap'
							}}
						>
							<div>
								{' '}
								<FilterDropdown
									searchInvoices={searchInvoices}
									filtersData={filtersData}
									setFiltersData={setFiltersData}
								/>
							</div>
							<div style={{ width: '50%' }}>
								<InputGroup>
									<FormControl
										placeholder='Procurar por nome ou cpf/cnpj do cliente ou id da fatura'
										aria-label="Recipient's username with two button addons"
										onChange={(e: any) => {
											setFiltersData({ ...filtersData, find_text: e?.target?.value })
										}}
										onKeyDown={e => {
											if (e.keyCode == 13 && !e.repeat) {
												searchInvoices()
											}
										}}
										value={filtersData.find_text}
									/>
									<Button
										variant='outline-primary'
										onClick={() => {
											searchInvoices()
										}}
									>
										<BiSearch size={20} />
									</Button>
								</InputGroup>
							</div>
						</div>
					</Card.Body>
				</Card>
				<Card className='mt-3'>
					<Card.Body>
						{showLoading ? (
							<CustomLoading />
						) : (
							<div>
								<Table responsive striped>
									<thead>
										<tr>
											<th scope='col'>ID</th>
											<th scope='col'>Cliente</th>
											<th scope='col'>Data de vencimento</th>
											<th scope='col'>Valor</th>
											<th scope='col'>Criado em</th>
											<th scope='col'>Tipo</th>
											<th scope='col'>Status</th>
											<th className='text-end' scope='col'>
												Ações
											</th>
										</tr>
									</thead>
									<tbody>
										{listData?.data?.length > 0
											? listData?.data?.map((item: IDataInvoice, index: number) => (
													<tr key={item?.id}>
														<td>{item?.id}</td>
														<td>{item?.business_partner?.name}</td>
														<td>{convertDate(item?.due_date)}</td>
														<td>
															{(item?.gross_value / 100)?.toLocaleString('pt-BR', {
																style: 'currency',
																currency: 'BRL'
															})}
														</td>
														<td>
															{item?.created_at
																? // new Date(item?.created_at).toLocaleDateString("pt-BR", {
																  // 	hour: "2-digit",
																  // 	hour12: false,
																  // 	minute: "2-digit",
																  // 	second: "2-digit",
																  // })
																  new Date(item?.created_at).toLocaleDateString('pt-BR')
																: null}
															{/* {renderDate(item?.created_at)} */}
														</td>
														<td>{renderTypeInvoice(item?.type)}</td>
														<td>{renderStatus(item?.status)}</td>
														<td className='text-end'>
															<FontAwesomeIcon
																icon={'eye'}
																style={{
																	color: '#2c7be5',
																	fontSize: '20px',
																	cursor: 'pointer',
																	position: 'relative',
																	left: '-10px'
																}}
																onClick={() => {
																	navigate(`/faturas/${item?.id}`)
																}}
															/>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>
								<CustomPagination
									data={listData}
									handlePageChange={e => {
										searchInvoices(e)
									}}
								/>
							</div>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default ListCharges
