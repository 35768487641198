import request from '../config/request'
import { IPropsFilter, IDataPDVCreate, IDataPDVUpdate } from './interfaces'

const search = (filter: IPropsFilter, page = 1, size = 10) => {
	let query = "?page=" + page + "&size=" + size
	if (filter.term)
		query += "&term="
			+ filter.term

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/pdvs` +
			query,
		vtAuth: true
	})
}

const create = async (pdv_data: IDataPDVCreate) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/pdvs`,
		data: pdv_data,
		vtAuth: true
	});
}

const loadDataEditform = async (id: number) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/pdvs/${id}`,
		vtAuth: true
	});
}

const update = async (id:number, pdv_data:IDataPDVUpdate) => {
    return request({
        method: "PUT",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/pdvs/${id}`,
        data: pdv_data,
        vtAuth: true
    });
}

export { search, create, loadDataEditform, update }
