import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async'

interface MainContractByAddendumProps {
  styles: any
  isDisabled: boolean
  loadOptions: any
  value: any
  onChange: any
}

export function MainContractByAddendum({ styles, isDisabled, loadOptions, value, onChange }: MainContractByAddendumProps) {
  return (
    <Form.Group>
      <Form.Label>Contrato principal</Form.Label>
      <AsyncSelect
        styles={styles}
        name='addendum_id'
        placeholder='Selecionar o contrato principal'
        isClearable
        defaultOptions
        loadOptions={loadOptions}
        value={value}
        onChange={onChange}
        noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
        isDisabled={isDisabled}
      />
  </Form.Group>
  )
}