import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const CustomSpinner = styled(Spinner)`
	font-weight: bold !important;
	color: #2c7be5 !important;
`

const CustomLoading = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '40vh'
			}}
		>
			{' '}
			<CustomSpinner />
		</div>
	)
}

export default CustomLoading
