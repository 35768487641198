

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { FaUser } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { ISubscriptionData } from '../inteface';

const CardsInfo: React.FC<ISubscriptionData> = ({ holder, dependent, total }) => {
    return (

        <>
            <Col >
                <Card>
                    <Card.Body>
                        <Col className="text-center">
                            <div className='text-info' style={{
                                width: '4rem',
                                height: '4rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                margin: '0 auto 1rem auto',
                                //color: 'blue'
                            }} >
                                <FontAwesomeIcon
                                    icon="users"
                                    className="fs-2 text-info"
                                />
                            </div>
                            <h4 className="mb-1 font-sans-serif">
                                <CountUp
                                    start={0}
                                    end={total}
                                    duration={2.75}
                                    className="text-bold"
                                    separator="."
                                />
                                <span className="fw-normal text-bold">{' ' + 'Total Assinantes Ativos'}</span>
                            </h4>

                        </Col>
                    </Card.Body>
                </Card>
            </Col>
            <Col >
                <Card >
                    <Card.Body>
                        <Col className="text-center">
                            <div style={{
                                width: '4rem',
                                height: '4rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                margin: '0 auto 1rem auto',
                                color: '#69b355'
                            }} >
                                <FaUser
                                    size={32}
                                //className="fs-2"
                                />
                            </div>
                            <h4 className="mb-1 font-sans-serif">
                                <CountUp
                                    start={0}
                                    end={holder}
                                    duration={2.75}
                                    className="text-bold"
                                    separator="."
                                />
                                <span className="fw-normal text-bold">{' ' + 'Titulares Ativos'}</span>
                            </h4>

                        </Col>
                    </Card.Body>
                </Card>
            </Col>
            <Col >
                <Card >
                    <Card.Body>
                        <Col className="text-center">
                            <div className='text-warning' style={{
                                width: '4rem',
                                height: '4rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                                margin: '0 auto 1rem auto',
                                //color: 'blue'
                            }} >
                                <FiUsers
                                    size={32}
                                //className="fs-2 text-warning"
                                />
                            </div>
                            <h4 className="mb-1 font-sans-serif">
                                <CountUp
                                    start={0}
                                    end={dependent}
                                    duration={2.75}
                                    className="text-bold"
                                    separator="."
                                />
                                <span className="fw-normal text-bold">{' ' + 'Dependentes Ativos'}</span>
                            </h4>


                        </Col>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default CardsInfo;