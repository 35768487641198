import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface PaginationProps {
	data: {
		total_items: number
		total_pages: number
		current_page: number
		data: any[]
	}
	handlePageChange: (pageNumber: number) => void
}

const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 8px;
`

const PaginationItem = styled.div<{ active?: boolean }>`
  border-radius: 50%;
  min-width: 30px; 
  height: 30px;
  padding: 0 6px; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? '#2c7be5' : '')};
  color: ${(props) => (props.active ? 'white' : '#2c7be5')};
  margin: 0 5px;
  cursor: pointer;
  overflow: hidden; 
  text-overflow: ellipsis; 

  &:hover {
    background-color: #2c7be5;
    color: white;
  }
`;

const PaginationControl = styled.div`
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	cursor: pointer;
	color: #2c7be5;

	&:hover {
		background-color: #2c7be5;
		color: white;
	}
`

const CustomPagination: React.FC<PaginationProps> = ({
	data,
	handlePageChange: handlePageChangeProp
}) => {
	const { total_pages, current_page } = data

	const [firstPage, setFirstPage] = useState(1)
	const pagesToShow = 5

	useEffect(() => {
		if (current_page < firstPage || current_page >= firstPage + pagesToShow) {
			setFirstPage(current_page)
		}
	}, [current_page])

	const handlePageChange = (pageNumber: number) => {
		handlePageChangeProp(pageNumber)
	}

	const nextPage = () => {
		handlePageChange(Math.min(total_pages, current_page + 1))
	}

	const prevPage = () => {
		handlePageChange(Math.max(1, current_page - 1))
	}

	const paginationItems = []
	for (
		let number = firstPage;
		number < firstPage + pagesToShow && number <= total_pages;
		number++
	) {
		paginationItems.push(
			<PaginationItem
				key={number}
				active={number === current_page}
				onClick={() => handlePageChange(number)}
			>
				{number}
			</PaginationItem>
		)
	}

	return (
		<PaginationContainer>
			{current_page > 1 && (
				<>
					<PaginationControl onClick={() => handlePageChange(1)}>
						{'<<'}
					</PaginationControl>
					<PaginationControl onClick={prevPage}>{'<'}</PaginationControl>
				</>
			)}
			{paginationItems}
			{current_page < total_pages && (
				<>
					<PaginationControl onClick={nextPage}>{'>'}</PaginationControl>
					<PaginationControl onClick={() => handlePageChange(total_pages)}>
						{'>>'}
					</PaginationControl>
				</>
			)}
		</PaginationContainer>
	)
}

export default CustomPagination
