import { FC, useState, useContext } from 'react'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import AppContext from 'context/Context'
import Select from 'react-select'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { search as searchBusinessPartners } from '../../../services/vittamais-core/customer/customer'
import { TbReportSearch } from 'react-icons/tb'
import { exportSubscription, search as searchSubscriptions } from 'services/vittamais-core/subscription/subscription'
import {search as searchContracts} from "services/vittamais-core/contract/contract"
import SUBSCRIPTION_STATUS from 'helpers/subscriptionStatus'
const today = new Date().toLocaleDateString('pt-BR')
const todayOnlyDate =
	today.substr(6, 4) + '-' + today.substr(3, 2) + '-' + today.substr(0, 2)

const IndexReportSubscription: FC = function IndexReportSubscription() {
	const { dataAuth } = useAuth()
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}
	const [showLoading, setShowLoading] = useState(false)
	const [FormData, setFormData] = useState<any>({
		bp_id: {
			label: 'Todos',
			value: ''
		},
		contract_id:{
			label: 'Todos',
			value: ''
		},
		start_created_at: todayOnlyDate,
		end_created_at: todayOnlyDate,
		subscription_status: [
			{
				label: 'Todos',
				value: ''
			}
		],
		pdvs:[
			{
				label: 'Todos',
				value: ''
			}
		]
	})
	const handleFieldChange = (e: any): void => {
		setFormData({
			...FormData,
			[e.target.name]: e.target.value
		})
	}
	const handleSelectsChanges = (name: string, e: any): void => {
		setFormData({
			...FormData,
			[name]: e
		})
	}

	const loadBpInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					{
						label: 'Todos',
						value: ''
					}
				]

				let response: any = await searchBusinessPartners({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					let cgc =
						element?.person_type == 1
							? `CPF: ${element?.cpf} - `
							: `CNPJ: ${element?.cnpj} `
					options.push({
						label: element.name + ' - ' + cgc + 'E-mail: ' + element?.email,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}
	const debounceOptionsBp: any = debounce(loadBpInputOptions, 600)
	
	const loadContractInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					{
						label: 'Todos',
						value: ''
					}
				]

				let response: any = await searchContracts({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label: `Contrato: #${element.id} - Dependentes: ${
							element?.holder_dependents_quantity
						} - Valor: ${(element?.value / 100)?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})} - Preço: ${(element?.price / 100)?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})} - Desconto: ${element?.discount?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})}`,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceContractOptionsBp: any = debounce(loadContractInputOptions, 600)

	const handleSubmit = async () => {
		setShowLoading(true)  
		try {
			
			let dataToSend = {
				subscription_status: FormData?.subscription_status
					?.filter((item: any) => item?.value != '')
					?.map((item: any) => item?.value),
					pdv_id: FormData?.pdvs
					?.filter((item: any) => item?.value != '')
					?.map((item: any) => item?.value),
				bp_id: FormData?.bp_id?.value,
				contract_id:FormData?.contract_id?.value,
				start_created_at: FormData.start_created_at,
				end_created_at: FormData.end_created_at,
				
			}
		
			let response = await exportSubscription(dataToSend);

			var headers = response.headers

			var blob = new Blob([response.data], {
				type: headers['content-type']
			})
			var link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = 'Assinaturas'
			link.click()
		} catch (e) {
			console.log(e)
		}
		setShowLoading(false)
	}
	return (
		<Row className='justify-content-center'>
			<Col md={7} xs={12}>
				<Card>
					<Card.Body>
						<Row>
							<Col sm='6'>
								<h5 style={{ fontSize: 18 }}>Assinaturas</h5>
							</Col>
						</Row>
						<hr />
						<Row className='mt-2'>
						<Col md={12}>
								<Form.Group>
									<Form.Label>PDV</Form.Label>
									<Select
										styles={customStyles}
										name='pdvs'
										onChange={(e: any) => {
											handleSelectsChanges('pdvs', e)
										}}
										placeholder='Selecione...'
										isMulti
										options={dataAuth?.user?.pdvs?.map((pdv: any) => ({
											label: pdv.name,
											value: pdv.id
										}))}
										value={FormData.pdvs}
										noOptionsMessage={() => 'Sem Resultados.'}
									/>
								</Form.Group>
							</Col>
						<Col md={12}>
								<Form.Group>
									<Form.Label>Contrato</Form.Label>
									<AsyncSelect
										styles={customStyles}
										name='contract_id'
										placeholder='Selecionar assinatura'
										isClearable
										defaultOptions
										loadOptions={debounceContractOptionsBp}
										onChange={(e: any) => {
											handleSelectsChanges('contract_id', e)
										}}
										value={FormData.contract_id}
										noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
									/>
								</Form.Group>
							</Col>
							<Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Cliente</Form.Label>
									<AsyncSelect
										styles={customStyles}
										name='bp_id'
										placeholder='Selecionar cliente'
										isClearable
										defaultOptions
										loadOptions={debounceOptionsBp}
										onChange={(e: any) => {
											handleSelectsChanges('bp_id', e)
										}}
										value={FormData.bp_id}
										noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
									/>
								</Form.Group>
							</Col>
							<Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data criação inicial</Form.Label>
									<Form.Control
										value={FormData.start_created_at}
										name='start_created_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col>
							<Col md={6} className='mt-2'>
								{' '}
								<Form.Group>
									<Form.Label>Data criação final</Form.Label>
									<Form.Control
										value={FormData.end_created_at}
										name='end_created_at'
										onChange={handleFieldChange}
										type='date'
									/>
								</Form.Group>
							</Col>
					
							
			
							<Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Status Assinatura</Form.Label>
									<Select
										styles={customStyles}
										name='subscription_status'
										onChange={(e: any) => {
											handleSelectsChanges('subscription_status', e)
										}}
										placeholder='Selecione...'
										isMulti
										options={[
											{
												label: 'Todos',
												value: ''
											},
											{
												label: 'Ativo',
												value: SUBSCRIPTION_STATUS.ACTIVATED
											},
											{
												label: 'Desativado',
												value: SUBSCRIPTION_STATUS.DISABLED
											},
											{
												label: 'Cancelado',
												value: SUBSCRIPTION_STATUS.CANCELED
											}
										]}
										value={FormData.subscription_status}
										noOptionsMessage={() => 'Sem Resultados.'}
									/>
								</Form.Group>
							</Col>

                            
							<Col md={12} className='mt-2'>
								<Form.Group>
									<Form.Label>Formato de saída</Form.Label>
									<Select
									
										placeholder='Selecione...'
										isDisabled
										value={{ label: 'Excel', value: 'excel' }}
										noOptionsMessage={() => 'Sem Resultados.'}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<p />
							<hr />
							<Col align='end'>
								<Button
									onClick={() => {
										handleSubmit()
									}}
									variant='falcon-default'
									size='sm'
									style={{ position: 'relative', top: '-3px' }}
									disabled={showLoading}
								>
									{showLoading ? 'Emitindo...' : 'Emitir Relatório'}{' '}
									<TbReportSearch
										size={15}
										style={{ position: 'relative', top: '-2px' }}
									/>
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default IndexReportSubscription
