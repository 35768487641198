import { FC, useEffect, useState } from 'react'
import {
	Card,
	Table,
	Button,
	Row,
	Col,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaClinicMedical } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import { search } from 'services/vittamais-core/pdv/pdv'
import { IDataResponseListPDVs, IDataPDV } from './interfaces'
import SoftBadge from 'components/common/SoftBadge'
import CustomPagination from 'components/common/CustomPagination'
import { Spinner } from 'react-bootstrap'
import CustomLoading from 'components/common/CustomLoading'
const ListPDVs: FC = function ListPDVs() {
	const navigate = useNavigate()
	const [listData, setListData] = useState({
		total_items: 0,
		total_pages: 0,
		current_page: 1,
		data: []
	})
	const [showLoading, setShowLoading] = useState(false)
	const [inputSearchData, setInputSearchData] = useState('')
	useEffect(() => {
		searchPDVs()
	}, [])

	const searchPDVs = async (page = 1, sizePerPage = 10) => {
		setShowLoading(true)
		try {
			let filter = {
				term: inputSearchData
			}

			let data: IDataResponseListPDVs = await search(filter, page, sizePerPage)
			setListData({
				total_items: data.total_items,
				total_pages: data.total_pages,
				current_page: data.current_page,
				data: data.data
			})
		} catch (e) {
			console.log(e)
		}
		setShowLoading(false)
	}
	const renderStatus = (status: number) => {
		if (status == 1) {
			return (
				<SoftBadge pill bg='success'>
					Ativo
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='danger'>
					Inativo
				</SoftBadge>
			)
		}
	}

	return (
		<Row>
			<Col md={12}>
				<Card>
					<Card.Body>
						<Row>
							<Col>
								{' '}
								<h5 style={{ fontSize: 18 }}>Lista de PDVs </h5>
							</Col>{' '}
						</Row>
						<Row className='mt-4'>
							<Col lg='6' className='mb-2'>
								<InputGroup>
									<FormControl
										placeholder='Pesquisar...'
										aria-label="Recipient's username with two button addons"
										onChange={(e: any) => {
											setInputSearchData(e?.target?.value)
										}}
										onKeyDown={e => {
											if (e.keyCode == 13 && !e.repeat) {
												searchPDVs()
											}
										}}
										value={inputSearchData}
									/>
									<Button
										variant='outline-primary'
										onClick={() => {
											searchPDVs()
										}}
									>
										<BiSearch size={20} />
									</Button>
								</InputGroup>
							</Col>
							<Col lg='6' align='end'>
								<Button
									variant='falcon-default'
									onClick={() => {
										navigate('/pdvs/adicionar')
									}}
									style={{ position: 'relative', top: '-4px' }}
								>
									<FaClinicMedical size="18" className='mb-1'/> Adicionar
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>

				<Card className='mt-3'>
					<Card.Body>
						{showLoading ? (
							<CustomLoading />
						) : (
							<div>
								<Table responsive striped>
									<thead>
										<tr>
											<th style={{ width: '10%' }} scope='col'>ID</th>
											<th style={{ width: '65%' }} scope='col'>Name</th>
											<th style={{ width: '10%' }} scope='col'>Status</th>
											<th style={{ width: '15%' }} className='text-end' scope='col'>
												Ações
											</th>
										</tr>
									</thead>
									
									<tbody>
										{listData?.data?.length > 0
											? listData?.data?.map((item: IDataPDV, index: number) => (
													<tr key={item?.id}>
														<td>{item?.id}</td>
														<td>{item?.name}</td>
														<td>{renderStatus(item?.status)}</td>
														<td className='text-end'>
															<FontAwesomeIcon
																icon={'edit'}
																style={{
																	color: '#2c7be5',
																	fontSize: '20px',
																	cursor: 'pointer',
																	position: 'relative',
																	left: '-10px'
																}}
																onClick={() => {
																	navigate(`/pdvs/${item?.id}`)
																}}
															/>
														</td>
													</tr>
											  ))
											: null}
									</tbody>
								</Table>

								<CustomPagination
									data={listData}
									handlePageChange={e => {
										searchPDVs(e)
									}}
								/>
							</div>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default ListPDVs
