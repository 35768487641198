import Modal from 'react-bootstrap/Modal'
import { Form, Row, Col, Button, Table } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { useState, useEffect } from 'react'
import INVOICE_STATUS from 'helpers/invoiceStatus'
import SoftBadge from 'components/common/SoftBadge'
import { renegotiateSubscriptionData } from 'services/vittamais-core/subscription/subscription'
import { generateCancellationFee } from 'services/vittamais-core/subscription/subscription'
import INVOICE_TYPES from 'helpers/invoiceTypes'
interface IPropsModal {
	show: boolean
	onClose: (data?: { action?: string }) => void
	subscriptionData: any
	type: string
}
function ModalInvoiceMethods({
	subscriptionData,
	show,
	type,
	onClose
}: IPropsModal) {
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [showLoading, setShowLoading] = useState(false)
	const initialState = {
		due_date: '',
		value: 0,
		payment_method: PAYMENT_METHOD.ASK_THE_CUSTOMER,
		description: '',
		invoicesTorenegotiate: []
	}
	const [formData, setFormData] = useState<any>(initialState)
	const handleFieldChange = (name: string, e: any): void => {
		if (name == 'value') {
			setFormData({
				...formData,
				[name]: e
			})
		} else {
			setFormData({
				...formData,
				[name]: e.target.value
			})
		}
	}
	const renderTypeInvoice = (status: number) => {
		let bg = ``
		let text = ``
		switch (status) {
			case INVOICE_TYPES.SUBSCRIPTION:
				bg = `success`
				text = `Assinatura`
				break
			case INVOICE_TYPES.RENEGOTIATION:
				bg = `info`
				text = `Renegociação`
				break
			case INVOICE_TYPES.MEMBERSHIP_FEE:
				bg = `primary`
				text = `Taxa de Adesão`
				break
			case INVOICE_TYPES.CANCELLATION_PENALTY:
				bg = `danger`
				text = `Multa de Cancelamento`
				break
		}

		return (
			<SoftBadge pill bg={bg}>
				{text}
			</SoftBadge>
		)
	}
	const onsubmit = async () => {
		try {
			setShowLoading(true)
			const dataToSend = {
				value: formData?.value * 100,
				payment_method: formData?.payment_method,
				due_date: formData?.due_date,
				description: formData?.description,
				invoices: formData?.invoicesTorenegotiate
			}

			if (type == 'renegotiate') {
				await renegotiateSubscriptionData(subscriptionData?.id, dataToSend)
			} else {
				await generateCancellationFee(subscriptionData?.id, dataToSend)
			}

			showToast('Assinatura atualizada com sucesso!', 'success')
			onClose({ action: 'refresh' })
		} catch (e: any) {
			setShowLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
		setShowLoading(false)
	}
	const clearData = () => {
		setFormData(initialState)
	}
	function convertDate(inputData: any) {
		const parts = inputData.split('-')

		const day = parts[2]
		const month = parts[1]
		const year = parts[0]

		const formattedDate = `${day}/${month}/${year}`

		return formattedDate
	}
	const renderStatus = (status: number) => {
		let bg = ``
		let text = ``
		switch (status) {
			case INVOICE_STATUS.PENDING:
				bg = `warning`
				text = `Aguardando pagamento`
				break
			case INVOICE_STATUS.AWAITING_RISK_ANALYSIS:
				bg = `warning`
				text = `Pagamento em análise`
				break
			case INVOICE_STATUS.CONFIRMED:
				bg = `primary`
				text = `Pagamento confirmado`
				break
			case INVOICE_STATUS.RECEIVED:
				bg = `success`
				text = `Recebida`
				break
			case INVOICE_STATUS.OVERDUE:
				bg = `danger`
				text = `Vencida`
				break
			case INVOICE_STATUS.REFUND_REQUESTED:
				bg = `info`
				text = `Estorno Solicitado`
				break
			case INVOICE_STATUS.REFUNDED:
				bg = `primary`
				text = `Estornada`
				break
			case INVOICE_STATUS.CHARGEBACK_REQUESTED:
				bg = `primary`
				text = `Recebido chargeback`
				break
			case INVOICE_STATUS.CHARGEBACK_DISPUTE:
				bg = `warning`
				text = `Em disputa de chargeback`
				break
			case INVOICE_STATUS.AWAITING_CHARGEBACK_REVERSAL:
				bg = `warning`
				text = `Disputa vencida, aguardando repasse da adquirente`
				break
		}

		return (
			<SoftBadge pill bg={bg}>
				{text}
			</SoftBadge>
		)
	}

	const onSelectedInvoice = (isChecked: boolean, invoice_id: number) => {
		let data = { ...formData }
		if (isChecked) {
			if (!data.invoicesTorenegotiate?.includes(invoice_id)) {
				data.invoicesTorenegotiate.push(invoice_id)
			}
		} else {
			data.invoicesTorenegotiate = data.invoicesTorenegotiate.filter(
				(e: number) => e != invoice_id
			)
		}
		setFormData(data)
	}

	useEffect(() => {
		setFormData(initialState);
	}, [show])

	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				// onHide={() => {
				// 	onClose()
				// }}
				size='xl'
			>
				<Modal.Header>
					<Modal.Title>
						{type == 'renegotiate' ? 'Renegociação' : 'Gerar Multa de Cancelamento'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col sm='6'>
							<Form.Group>
								<Form.Label>Vencimento</Form.Label>
								<Form.Control
									name='due_date'
									onChange={e => {
										handleFieldChange('due_date', e)
									}}
									type='date'
									value={formData.due_date}
								/>
							</Form.Group>
						</Col>
						<Col sm='6'>
							<Form.Group>
								<Form.Label>Valor</Form.Label>
								<NumericFormat
									name='value'
									displayType={'input'}
									thousandSeparator={'.'}
									decimalSeparator={','}
									decimalScale={2}
									fixedDecimalScale={true}
									value={formData.value}
									prefix={'R$ '}
									className='form-control'
									onValueChange={values => handleFieldChange('value', values.floatValue)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className='mt-2'>
						<Col sm='6'>
							<Form.Group>
								<Form.Label>Método de pagamento</Form.Label>
								<Form.Select
									name='payment_method'
									value={formData.payment_method}
									onChange={e => {
										handleFieldChange('payment_method', e)
									}}
								>
									<option value=''>Selecionar</option>
									{/* <option value={PAYMENT_METHOD.ASK_THE_CUSTOMER}>
										Pergunte ao cliente
									</option> */}
									<option value={PAYMENT_METHOD.TICKET}>Boleto</option>
									<option value={PAYMENT_METHOD.CREDIT_CARD}>Cartão de Crédito</option>
									<option value={PAYMENT_METHOD.PIX}>PIX</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col sm='6'>
							<Form.Label>Descrição</Form.Label>
							<Form.Control
								value={formData.description}
								name='description'
								as='textarea'
								rows={3}
								onChange={e => {
									handleFieldChange('description', e)
								}}
							/>
						</Col>
					</Row>
					{type == 'renegotiate' ? (
						<Row>
							<Col>
								<Row className='mt-4'>
									<Col sm='6'>
										<h4>Faturas</h4>
									</Col>
								</Row>

								<Table responsive striped bordered>
									<thead>
										<tr>
										<th scope='col' style={{ textAlign: 'center' }}>
												Ações
											</th>
											<th scope='col'>Descrição</th>
											<th scope='col'>Valor</th>
											<th scope='col'>Data Vencimento</th>
											<th scope='col'>Tipo</th>
											<th scope='col'>Status</th>
										</tr>
									</thead>
									<tbody>
										{subscriptionData?.invoices_info?.length > 0
											? subscriptionData?.invoices_info?.filter((e:any)=>e?.type != INVOICE_TYPES.MEMBERSHIP_FEE )?.map(
													(item: any, index: number) => (
														<tr key={'row' + index}>
																<td style={{ textAlign: 'center' }}>
																<Form.Check.Input
																	style={{ transform: 'scale(1.2)' }}
																	name='control_invoices'
																	type='checkbox'
																	onChange={(e: any) => {
																		onSelectedInvoice(e?.target?.checked, item?.id)
																	}}
																></Form.Check.Input>
															</td>
															<td>
																Fatura {index + 1} de {subscriptionData?.invoices_info?.length}
															</td>
															<td>
																{(item?.gross_value / 100)?.toLocaleString('pt-BR', {
																	style: 'currency',
																	currency: 'BRL'
																})}
															</td>

															<td>{convertDate(item?.due_date)}</td>
															<td>{renderTypeInvoice(item?.type)}</td>
															<td>{renderStatus(item?.status)}</td>
														</tr>
													)
											  )
											: null}
									</tbody>
								</Table>
							</Col>
						</Row>
					) : null}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => {
							onClose()
						}}
						disabled={showLoading}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						onClick={async () => {
							if (type == 'renegotiate') {
								if (
									!formData?.due_date ||
									!formData?.payment_method ||
									!formData?.value ||
									formData?.invoicesTorenegotiate?.length <= 0
								) {
									return showAlert({
										type: 'warning',
										title: 'Atenção!',
										text:
											'É necessário preencher todas as informações e selecionar ao menos uma fatura!'
									})
								}
							} else {
								if (
									!formData?.due_date ||
									!formData?.payment_method ||
									!formData?.value
								) {
									return showAlert({
										type: 'warning',
										title: 'Atenção!',
										text: 'É necessário preencher todas as informações!'
									})
								}
							}
							let confirmed = await showConfirm({
								title: 'Atenção!',
								text: 'Tem certeza que deseja continuar essa ação?'
							})
							if (!confirmed) return
							onsubmit()
						}}
						disabled={showLoading}
					>
						{showLoading ? 'Enviando solicitação...' : 'Confirmar'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalInvoiceMethods
