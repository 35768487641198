import { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { IPropsModal } from './interfaces'
import kinshipTypes from 'helpers/kinshipTypes'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { cancelContract } from 'services/vittamais-core/contract/contract'
import CONTRACT_STATUS from 'helpers/contractStatus'
import { search as searchCustomer } from 'services/vittamais-core/customer/customer'
import AsyncSelect from 'react-select/async'
import debounce from 'debounce-promise'
import AppContext from 'context/Context'
import { create } from 'services/vittamais-core/subscription/subscription'
function ModalAddDependent({
	show,
	onClose,
	FormData,
	setFormData,
	loadDataForm
}: IPropsModal) {
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [modalDataForm, setModalDataForm] = useState<{
		business_partner_id: any
		kinship_id: any
	}>({
		business_partner_id: '',
		kinship_id: ''
	})
	const handleFieldChange = (e: any): void => {
		setModalDataForm({
			...modalDataForm,
			[e.target.name]: e.target.value
		})
	}
	const handleChangeBP = (e: any) => {
		let value = ''
		if (e?.value) {
			value = e
		}

		setModalDataForm({
			...modalDataForm,
			business_partner_id: value
		})
	}

	const addDependent = async () => {
		try {
			if (!modalDataForm?.business_partner_id || !modalDataForm?.kinship_id) {
				showAlert({
					type: 'warning',
					title: 'Atenção!',
					text: 'É necessário preencher todos os campos!'
				})
				return
			}

			let dataSend = {
				business_partner_id: modalDataForm?.business_partner_id?.value,
				type_dependents: modalDataForm?.kinship_id,
				holder_subscription_id: FormData?.id,
				contract_id: FormData?.contract_id
			}
			await create(dataSend)
			//depois ajustar back para mandar objeto para n precisar fazer req
			loadDataForm()
			showToast('Dependente adicionado com sucesso!', 'success')
			onClose()
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
	}

	const loadCustomerInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = []

				let response: any = await searchCustomer({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					let cgc =
						element?.person_type == 1
							? `CPF: ${element?.cpf} - `
							: `CNPJ: ${element?.cnpj} `
					options.push({
						label: element.name + ' - ' + cgc + 'E-mail: ' + element?.email,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsCustomer: any = debounce(loadCustomerInputOptions, 600)
	const customStyles = {
		control: (provided: any, state: any) => {
			const isDisabled = state.selectProps.isDisabled

			let color = isDark ? 'white' : ''
			let backgroundColor = isDark ? '#0b1727' : ''
			let borderColor = isDark ? '#344050' : '#dae3f0'

			if (isDisabled && !isDark) {
				backgroundColor = '#edf2f9'
				color = '#4a4f5e'
				borderColor = '#dce5f1'
			}

			return {
				...provided,
				backgroundColor,
				color,
				borderColor
			}
		}
	}
	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				// onHide={() => {
				// 	onClose()
				// }}
				size='lg'
			>
				<Modal.Header>
					<Modal.Title>Novo dependente</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Número Cartão Titular</Form.Label>
						<Form.Control
							name='holder_subscription_number'
							onChange={() => {}}
							type='text'
							value={FormData.public_number}
							disabled
						/>
					</Form.Group>
					<Form.Group className='mt-3'>
						<Form.Label>Cliente (dependente)</Form.Label>
						<AsyncSelect
							styles={customStyles}
							name='business_partner_id'
							placeholder='Selecionar cliente'
							isClearable
							defaultOptions
							loadOptions={debounceOptionsCustomer}
							onChange={handleChangeBP}
							noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
							value={modalDataForm?.business_partner_id}
						/>
					</Form.Group>
					<Form.Group className='mt-3'>
						<Form.Label>Parentesco</Form.Label>
						<Form.Select
							name='kinship_id'
							onChange={handleFieldChange}
							value={modalDataForm?.kinship_id}
						>
							<option value=''>Selecionar</option>
							{kinshipTypes?.map((item, index) => (
								<option key={item?.value} value={item.value}>
									{item.label}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => {
							onClose()
						}}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							addDependent()
						}}
					>
						Adicionar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalAddDependent
