import { FC, useState, useContext, useEffect } from 'react'
import { Card, Button, Row, Col, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { AiFillSave } from 'react-icons/ai'
import AppContext from 'context/Context'
import CustomLinkLeft from 'components/common/CustomLinkLeft'
import SoftBadge from 'components/common/SoftBadge'
import useAuth from 'hooks/useAuth'
import { useLoading} from 'context/LoadingBar/LoadingBar'
import {
	IOption,
	IDataUser,
	IDataUserLoadDataEdit,
	IFormData
} from './interfaces'
import {
	create,
	loadDataEditform,
	update
} from 'services/vittamais-core/user/user'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
const FormUsers: FC = function FormUsers() {
	const {setLoading} = useLoading()
	const { showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [optionsPDV, setOptionsPDV] = useState<IOption[]>([])
	const [optionsAccessRoles, setOptionsAcessRoles] = useState<IOption[]>([])
	const { id } = useParams()
	const { dataAuth } = useAuth()
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)

	const navigate = useNavigate()

	const [formData, setFormData] = useState<IFormData>({
		name: '',
		email: '',
		password: '',
		access_roles: [],
		status: '',
		username: '',
		pdvs: [],
		created_at: ''
	})

	const handleFieldChange = (e: any): void => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	const handleAccessRoles = (e: any): void => {
		setFormData({
			...formData,
			access_roles: e
		})
	}

	const handlePdvs = (e: any): void => {
		setFormData({
			...formData,
			pdvs: e
		})
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		try {
			let dataSend: IDataUser = JSON.parse(JSON.stringify(formData))
			dataSend.access_roles = dataSend?.access_roles?.map(item => item.value)
			dataSend.pdvs = dataSend?.pdvs?.map(item => item.value)
			if (!id) {
				await create(dataSend)
				showToast('Usuário criado com sucesso!', 'success')
			} else {
				await update(parseInt(id), dataSend)
				showToast('Usuário atualizado com sucesso!', 'success')
			}

			navigate('/usuarios')
		} catch (e) {
			console.log(e)
			showAlert({
				title: 'Ops...!',
				text: 'Aconteceu algo inesperado, verifique os campos e tente novamente.',
				type: 'error'
			})
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '', 
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}

	const loadDataPage = () => {

		let dataPdvs: IOption[] = []
		let dataAccessRoles: IOption[] = []
		for (let i = 0; i < dataAuth?.user?.pdvs?.length; i++) {
			let use = dataAuth?.user?.pdvs[i]
			dataPdvs.push({
				value: use.id,
				label: use.name
			})
		}
		for (let i = 0; i < dataAuth?.user?.access_roles?.length; i++) {
			let use = dataAuth?.user?.access_roles[i]
			dataAccessRoles.push({
				value: use.id,
				label: use.name
			})
		}
		setOptionsAcessRoles(dataAccessRoles)
		setOptionsPDV(dataPdvs)
		loadDataEditPage()
	
	}
	const loadDataEditPage = async () => {
		try {
			if (!id) return
			setLoading(true)
			let dataForm = { ...formData }
			let dataEdit: IDataUserLoadDataEdit = await loadDataEditform(parseFloat(id))
			dataForm.name = dataEdit.name
			dataForm.email = dataEdit.email
			dataForm.username = dataEdit.username
			dataForm.status = dataEdit.status
			dataForm.access_roles = dataEdit?.access_roles.map(role => ({
				label: role.name,
				value: role.id
			}))
			dataForm.pdvs = dataEdit?.pdvs.map(pdv => ({
				label: pdv.name,
				value: pdv.id
			}))
			dataForm.created_at = dataEdit?.created_at
			setFormData(dataForm)
		} catch (e) {
			console.log(e)
		}

		setLoading(false)
	}
	const renderDate = (dateString: any)=> {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}

	const renderStatus = (status: string) => {
		if (status == '1') {
			return (
				<SoftBadge pill bg='success'>
					Ativo
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='danger'>
					Inativo
				</SoftBadge>
			)
		}
	}

	useEffect(() => {
		loadDataPage()
	}, [])

	return (
		<Row>
			<Col md={12}>
				<Form onSubmit={handleSubmit}>
					<Card>
						<Card.Body>
							{!id ? (
								<>
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Adicionar Usuário</h5>
										</Col>
										<Col></Col>
									</Row>
									<Row className='mt-4'>
										<Col sm='6'>
											<CustomLinkLeft to='/usuarios' />
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}
											>
												<Button type='submit' variant='falcon-default'>
													<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
												</Button>
											</div>
										</Col>
									</Row>
								</>
							) : (
								<div>
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Editar Usuário: #{id}</h5>
											<div>
												<h6 style={{ color: '#8d99a7' }}>
													{renderDate(formData?.created_at)}
												</h6>
											</div>
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}
											>
												<h5 style={{ color: '#757e89' }}>Status: </h5>{' '}
												<span style={{ position: 'relative', top: '-2px' }}>
													{renderStatus(formData?.status)}
												</span>
											</div>
										</Col>
									</Row>
									<Row className='d-flex justify-content-end mt-3'>
										<Col sm='6'>
											<CustomLinkLeft to='/usuarios' />
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}
											>
												<Button type='submit' variant='falcon-default'>
													<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
												</Button>
											</div>
										</Col>
									</Row>
								</div>
							)}
						</Card.Body>
					</Card>
					<Card className='mt-3'>
						<Card.Body>
							<Row>
								<Col>
									{/* <div className='bg-light card-body mt-2'> */}
									<div className=''>
										<Row className='mb-3'>
										<Col sm='6'>
												<Form.Group>
													<Form.Label>Nome</Form.Label>
													<Form.Control
														value={formData.name}
														name='name'
														onChange={handleFieldChange}
														type='text'
													/>
												</Form.Group>
											</Col>
											<Col sm='3'>
												<Form.Group>
													<Form.Label>Username</Form.Label>
													<Form.Control
														value={formData.username}
														name='username'
														onChange={handleFieldChange}
														type='text'
														disabled={id ? true : false}
													/>
												</Form.Group>
											</Col>
											<Col sm='3'>
												<Form.Group>
													<Form.Label>E-mail</Form.Label>
													<Form.Control
														value={formData.email}
														name='email'
														onChange={handleFieldChange}
														type='text'
													/>
												</Form.Group>
											</Col>
											<Col sm='6'>
												<Form.Group>
													<Form.Label>Senha</Form.Label>
													<Form.Control
														value={formData.password}
														name='password'
														onChange={handleFieldChange}
														type='password'
													/>
												</Form.Group>
											</Col>
											<Col sm='6'>
												<Form.Group>
													<Form.Label>Status</Form.Label>
													<Form.Select
														onChange={handleFieldChange}
														name='status'
														value={formData.status}
													>
														<option value=''>Selecionar</option>
														<option value='2'>Inativo</option>
														<option value='1'>Ativo</option>
													</Form.Select>
												</Form.Group>
											</Col>
											<Col sm='6'>
												<Form.Group>
													<Form.Label>Perfil de acesso</Form.Label>
													<Select
														styles={customStyles}
														name='access_roles'
														onChange={handleAccessRoles}
														placeholder='Selecione...'
														isMulti
														options={optionsAccessRoles}
														value={formData.access_roles}
													/>
												</Form.Group>
											</Col>
											<Col sm='6'>
												<Form.Group>
													<Form.Label>PDV</Form.Label>
													<Select
														styles={customStyles}
														name='pdvs'
														onChange={handlePdvs}
														placeholder='Selecione...'
														isMulti
														options={optionsPDV}
														value={formData.pdvs}
													/>
												</Form.Group>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Form>
			</Col>
		</Row>
	)
}

export default FormUsers
