import { FC, useContext, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { AppContext } from 'context/Context'
import CardLogin from 'components/authentication/card/Login'
import CardLogout from 'components/authentication/card/Logout'
import CardForgetPassword from 'components/authentication/card/ForgetPassword'
import CardPasswordReset from 'components/authentication/card/PasswordReset'

const is = require('is_js')

const PublicRoutes: FC = function PublicRoutes() {
	const HTMLClassList = document.getElementsByTagName('html')[0].classList
	useContext(AppContext)

	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add('windows')
		}
		if (is.chrome()) {
			HTMLClassList.add('chrome')
		}
		if (is.firefox()) {
			HTMLClassList.add('firefox')
		}
	}, [HTMLClassList])

	return (
		<>
			<Routes>
				<Route path='login' element={<CardLogin />} />
				<Route path='logout' element={<CardLogout />} />
				{/* <Route path="register" element={<CardRegistration />} /> */}
				<Route path='forgot-password' element={<CardForgetPassword />} />
				<Route path='reset-password' element={<CardPasswordReset />} />
				<Route path='*' element={<Navigate to='/login' replace />} />
			</Routes>
		</>
	)
}

export default PublicRoutes
