
export const hasPermission = (access_roles: any[], permissions?: string[]) => {
    if (!permissions || (permissions && permissions[0] == '')) return true
    if (!access_roles) {
        return false;
    }

    const accessPermissions = access_roles?.flatMap((accessRole: any) => accessRole?.permissions.map((permission: any) => permission.name));

    const result = permissions?.every((permission) =>
        accessPermissions.includes(permission)
    );

    return result;
};