import { Form } from "react-bootstrap";

interface ContractTypeProps {
  isDisabled: boolean
  value: any
}

export function ContractType({ isDisabled, value }: ContractTypeProps) {
  return (
    <Form.Group>
      <Form.Label>Tipo</Form.Label>
      <Form.Select
        name='contract_type'
        disabled={isDisabled}
        value={value}
      >
        <option value='contract'>Contrato</option>
        <option value='addendum'>Aditivo</option>
      </Form.Select>
    </Form.Group>
  )
}