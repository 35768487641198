import { FC } from 'react';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const ForgetPassword: FC = function ForgetPassword() {
    return (
        <AuthCardLayout leftSideContent={undefined} footer={false}>
            <h4 className="mb-0"> Esqueceu sua senha?</h4>
            <p className="mb-0"> Digite seu e-mail para redefinição de senha!</p>
            <ForgetPasswordForm />
        </AuthCardLayout>
    );
};

export default ForgetPassword;
