import { useState, FC } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { ILoginForm } from 'types/authentication/loginForm'
import useAuth from 'hooks/useAuth'
import { useToast } from 'context/Toasts/Toastify'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { login } from 'services/vittamais-core/user/user'

export type ITypeResponse = {
	token: string
	expiresIn: string
	user: {
		id: number
		name?: string
		email?: string
		picture?: string
		access_roles?: any[]
		pdvs?: any[]
	}
}

const LoginForm: FC<ILoginForm> = function LoginForm() {
	const { saveDataLoginOnStorage } = useAuth()
	const { showToast } = useToast()
	const { showAlert } = useSweetAlert()
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		username: '',
		password: '',
		remember: false
	})

	// Handler
	const handleSubmit = async (e: any) => {
		e.preventDefault()
		try {
			let dataSend = {
				username: formData.username,
				password: formData.password
			}

			let response = await login(dataSend)

			let dataUser: ITypeResponse = {
				user: {
					id: response?.result?.user?.id,
					name: response?.result?.user?.name,
					email: response?.result?.user?.email,
					access_roles: response?.result?.user?.access_roles,
					pdvs: response?.result?.user?.pdvs
				},
				token: response?.result?.token,
				expiresIn: response?.result?.expiresIn
			}

			showToast('Login realizado com sucesso!', 'success')
			saveDataLoginOnStorage(dataUser)
			navigate('/dashboard')
		} catch (e) {
			console.log(e)
			showAlert({
				title: 'Credenciais inválidas!',
				text: 'Verifique os dados inseridos e tente novamente...',
				type: 'warning'
			})
		}
	}

	const handleFieldChange = (e: any): void => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}

	
	return (
		<Form onSubmit={handleSubmit}>
			<Form.Group className='mb-3'>
				<Form.Label>Username</Form.Label>
				<Form.Control
					value={formData.username}
					name='username'
					onChange={handleFieldChange}
					type='text'
				/>
			</Form.Group>

			<Form.Group className='mb-3'>
				<Form.Label>Senha</Form.Label>
				<Form.Control
					value={formData.password}
					name='password'
					onChange={handleFieldChange}
					type='password'
				/>
			</Form.Group>

			<Row className='justify-content-between align-items-center'>
				<Col xs='auto'>
					<Form.Check type='checkbox' id='rememberMe' className='mb-0'>
						<Form.Check.Input
							type='checkbox'
							name='remember'
							checked={formData.remember}
							onChange={(e: any): void =>
								setFormData({
									...formData,
									remember: e.target.checked
								})
							}
						/>
						<Form.Check.Label className='mb-0 text-700'>Lembrar me?</Form.Check.Label>
					</Form.Check>
				</Col>

				<Col xs='auto'>
					<Link className='fs--1 mb-0' to='/forgot-password'>
						Esqueceu a senha?
					</Link>
				</Col>
			</Row>

			<Form.Group>
				<Button
					type='submit'
					color='primary'
					className='mt-3 w-100'
					disabled={!formData.username || !formData.password}
				>
					Entrar
				</Button>
			</Form.Group>

		</Form>
	)
}

export default LoginForm
