export default (): any => {
	let authUser: any = localStorage.getItem('vittavantagens.identity')
	
	if (authUser) {
		authUser = JSON.parse(authUser)
		if (authUser.token) return authUser.token
	}

	return null
}
