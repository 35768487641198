import Modal from 'react-bootstrap/Modal'
import { Form, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { printBooklet } from 'services/vittamais-core/invoice/invoice'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { searchUpdateSubscription } from 'services/vittamais-core/subscription/subscription'
interface IPropsModal {
	show: boolean
	onClose: (data?: { action?: string }) => void
	subscriptionData: any
	loadData: () => void
}
function ModalPrintMeat({ show, onClose, subscriptionData, loadData }: IPropsModal) {
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [showLoading, setShowLoading] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState({
		order_date: true,
		order_print: false
	})
	const [formData, setFormData] = useState({
		month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
		year: (new Date().getFullYear()).toString()
	})
	const generateOptions = () => {
		const currentYear = new Date().getFullYear()
		const endYear = currentYear + 10

		const options = []
		for (let year = currentYear; year <= endYear; year++) {
			options.push(
				<option key={year} value={year}>
					{year}
				</option>
			)
		}

		return options
	}
	const handleSelectedTag = (name: string, selected: boolean) => {
		if (name == 'order_date') {
			setSelectedOptions({
				order_date: true,
				order_print: false
			})
		} else {
			setSelectedOptions({
				order_date: false,
				order_print: true
			})
		}
	}
	const onSubmit = async () => {
		setShowLoading(true)
		try {
			let dataFilter = {
				month: formData.month,
				year: formData.year,
				subscription: subscriptionData?.id
			}
			const result  = await printBooklet(dataFilter) //base64
			const bytes = atob(result);
			const byteArray = new Uint8Array(bytes.length);

			for (let i = 0; i < bytes.length; i++) {
				byteArray[i] = bytes.charCodeAt(i);
			}

			const blob = new Blob([byteArray], { type: 'application/pdf' })

			const url = URL.createObjectURL(blob)
			window.open(url)

			//await searchUpdateSubscription(subscriptionData?.id)
			loadData()
			
			onClose()
			showToast('Impressão realizada com sucesso!', 'success')
		} catch (e: any) {
			console.log(e?.response?.data)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				setShowLoading(false)
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
		setShowLoading(false)
	}
	// useEffect(() => {
	// 	console.log(formData)
	// }, [show])
	if (!show) return null
	return (
		<>
			<Modal show={show} size='lg'>
				{' '}
				<Modal.Header>
					<Modal.Title>Impressão de carnê</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ paddingTop: '2px' }}>
					<small
						className='text-primary'
						style={{ fontWeight: 'bold', color: '#2c7be5' }}
					>
						Selecione até quando você quer gerar o carnê:
					</small>
					<Form.Group className='mt-2'>
						<Form.Label>Mês:</Form.Label>
						<Form.Select
							onChange={e => {
								setFormData({ ...formData, month: e?.target?.value })
							}}
							value={formData?.month}
						>
							<option value='01'>Janeiro</option>
							<option value='02'>Fevereiro</option>
							<option value='03'>Março</option>
							<option value='04'>Abril</option>
							<option value='05'>Maio</option>
							<option value='06'>Junho</option>
							<option value='07'>Julho</option>
							<option value='08'>Agosto</option>
							<option value='09'>Setembro</option>
							<option value='10'>Outubro</option>
							<option value='11'>Novembro</option>
							<option value='12'>Dezembro</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className='mt-2'>
						<Form.Label>Ano:</Form.Label>
						<Form.Select
							onChange={e => {
								setFormData({ ...formData, year: e?.target?.value })
							}}
							value={formData?.year}
						>
							{generateOptions()}
						</Form.Select>
					</Form.Group>
					{/* <Form.Group className='mt-3 mb-3'>
						<Form.Label>Como Imprimir?</Form.Label>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexFlow: 'wrap',
								gap: '20px',
								justifyContent: 'center'
							}}
						>
							<CustomTag
								icon={<MdDateRange />}
								label='Por ordem de vencimento'
								isSelected={selectedOptions?.order_date}
								onClick={() => {
									handleSelectedTag('order_date', !selectedOptions.order_date)
								}}
							/>
							<CustomTag
								icon={<MdReorder />}
								label='Por ordem de impressão'
								isSelected={selectedOptions.order_print}
								onClick={() => {
									handleSelectedTag('order_print', !selectedOptions.order_print)
								}}
							/>
						</div>
					</Form.Group> */}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => {
							onClose()
						}}
						disabled={showLoading}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							onSubmit()
						}}
						disabled={showLoading}
					>
						{showLoading ? 'Imprimindo...' : 'Imprimir'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalPrintMeat
