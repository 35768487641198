import { Form } from "react-bootstrap";
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'

interface PdvProps {
  onChange: any
  isDisabled: boolean
  value: any
  pdvList: any
}

export function Pdv({ onChange, isDisabled, value, pdvList }: PdvProps) {
  return (
    <Form.Group>
      <Form.Label>PDV</Form.Label>
      <Form.Select
        name='pdv_id'
        onChange={onChange}
        disabled={isDisabled}
        value={value}
      >
        {pdvList.map((item: any, index: number) => (
          <option key={item.id} value={item.id}>
            {item?.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}