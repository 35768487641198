import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import SoftBadge from 'components/common/SoftBadge'
import { INavbarVerticalMenuItem } from 'types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { BsFillFileEarmarkExcelFill } from 'react-icons/bs'
import { IoMdHelp } from 'react-icons/io'

const NavbarVerticalMenuItem: FC<INavbarVerticalMenuItem> =
	function NavbarVerticalMenuItem({ route }) {
		return (
			<Flex alignItems='center'>
				{route.icon == 'excel' ? (
					<BsFillFileEarmarkExcelFill
						fontSize={15}
						style={{ position: 'relative', top: '-2px' }}
					/>
				) : route.icon == 'help' ? (
					<IoMdHelp fontSize={18} style={{ position: 'relative', top: '-2px' }} />
				) : (
					route.icon && (
						<span className='nav-link-icon'>
							<FontAwesomeIcon icon={route.icon as any} />
						</span>
					)
				)}
				<span className='nav-link-text ps-1'>{route.name}</span>
				{route.badge && (
					<SoftBadge pill bg={route.badge.type} className='ms-2'>
						{route.badge.text}
					</SoftBadge>
				)}
			</Flex>
		)
	}
export default React.memo(NavbarVerticalMenuItem)
