import request from '../config/request'
import { IPropsFilter } from './interfaces'


const search = (filter: IPropsFilter, page = 1, size = 10) => {

    let query = "?page=" + page + "&size=" + size
    if (filter.term)
        query += "&term="
            + filter.term
    if (filter.status) {
        query += "&status=" + filter.status
    }

    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
            `/additional-products` +
            query,
        vtAuth: true
    })
}



export { search }
