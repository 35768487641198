import React, { useState, useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import { FiFilter } from 'react-icons/fi'
import { search as searchBusinessPartners } from 'services/vittamais-core/customer/customer'
import { search as searchContracts } from 'services/vittamais-core/contract/contract'
import { search as searchSignatures } from 'services/vittamais-core/subscription/subscription'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import AppContext from 'context/Context'
import INVOICE_STATUS from 'helpers/invoiceStatus'
import useAuth from 'hooks/useAuth'
interface FilterDropdownProps {
	filtersData: {
		due_date_init: string
		due_date_final: string
		status: string
		signature_id: string
		find_text: string
		bp_id: string
		contract_id: string
		pdv_id:string
	}
	setFiltersData: any
	searchInvoices: () => void
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
	filtersData,
	setFiltersData,
	searchInvoices
}) => {
	const { dataAuth } = useAuth()
	const {
		config: { isDark }
	} = useContext(AppContext)
	const [isOpen, setIsOpen] = useState(false)
	const handleToggle = () => {
		setIsOpen(!isOpen)
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0',
			minHeight: '30px',
			height: '30px'
		})
	}

	const loadBpInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					{
						label: 'Todos',
						value: ''
					}
				]

				let response: any = await searchBusinessPartners({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					let cgc =
						element?.person_type == 1
							? `CPF: ${element?.cpf} - `
							: `CNPJ: ${element?.cnpj} `
					options.push({
						label: element.name + ' - ' + cgc + 'E-mail: ' + element?.email,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsBp: any = debounce(loadBpInputOptions, 600)
	const loadSignatureInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = []

				let response: any = await searchSignatures({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label: `Cliente: ${element?.business_partner?.name} - Número Cartão: ${element?.public_number} - Contrato: #${element?.contract_id}`,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsSignature: any = debounce(loadSignatureInputOptions, 600)
	const loadContractInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					{
						label: 'Todos',
						value: ''
					}
				]

				let response: any = await searchContracts({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label: `Contrato: #${element.id} - Dependentes: ${
							element?.holder_dependents_quantity
						} - Valor: ${(element?.value / 100)?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})} - Preço: ${(element?.price / 100)?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})} - Desconto: ${element?.discount?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL'
						})}`,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceContractOptionsBp: any = debounce(loadContractInputOptions, 600)
	
	const clearInputs = () => {
		let newData = { ...filtersData }
		newData.due_date_final = ''
		newData.due_date_init = ''
		newData.signature_id = ''
		newData.bp_id = ''
		newData.status = ''
		newData.find_text = ''
		newData.contract_id = ''
		newData.pdv_id = ''
		setFiltersData(newData)
	}
	const applyFilters = () => {
		setIsOpen(false)
		searchInvoices()
	}
	const changeFiltersData = (name: string, e: any) => {
		if (name == 'status') {
			setFiltersData({ ...filtersData, status: e?.target?.value })
		} 
		else if (name == 'due_date_init') {
			setFiltersData({ ...filtersData, due_date_init: e?.target?.value })
		}
		else if (name == 'pdv_id') {
			setFiltersData({ ...filtersData, pdv_id:e?.target?.value})
		}
		 else if (name == 'due_date_final') {
			setFiltersData({ ...filtersData, due_date_final: e?.target?.value })
		} else {
			setFiltersData({ ...filtersData, [name]: e })
		}
	}
	return (
		<Dropdown show={isOpen} onToggle={handleToggle}>
			<Dropdown.Toggle variant='falcon-default'>
				<FiFilter
					size={20}
					style={{ position: 'relative', left: '-4px', top: '-2px' }}
				/>{' '}
				Filtros
			</Dropdown.Toggle>
			<Dropdown.Menu
				style={{
					position: 'absolute',
					left: 0,
					right: 0,
					minWidth: 'calc(100vw - 300px)',
					maxWidth: '100%',
					width: 'auto'
				}}
				className='text-dark mt-1'
			>
				<Card.Body style={{ paddingTop: '10px' }}>
					<Row>
						<Col lg='6'>
							{' '}
							<Form.Group>
								<Form.Label>Cliente</Form.Label>
								<AsyncSelect
									styles={customStyles}
									name='business_partner_id'
									placeholder='Selecionar cliente'
									isClearable
									defaultOptions
									loadOptions={debounceOptionsBp}
									onChange={e => {
										changeFiltersData('bp_id', e)
									}}
									value={filtersData.bp_id}
									noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
								/>
							</Form.Group>
						</Col>
						<Col lg='2'>
							{' '}
							<Form.Group>
								<Form.Label>Vencimento inicial</Form.Label>
								<Form.Control
									name='due_date_init'
									onChange={e => {
										changeFiltersData('due_date_init', e)
									}}
									type='date'
									size='sm'
									value={filtersData.due_date_init}
								/>
							</Form.Group>
						</Col>
						<Col lg='2'>
							{' '}
							<Form.Group>
								<Form.Label>Vencimento final</Form.Label>
								<Form.Control
									name='due_date_final'
									onChange={e => {
										changeFiltersData('due_date_final', e)
									}}
									type='date'
									size='sm'
									value={filtersData.due_date_final}
								/>
							</Form.Group>
						</Col>
						<Col lg='2'>
							{' '}
							<Form.Group>
								<Form.Label>Status</Form.Label>
								<Form.Select
									size='sm'
									name='status'
									value={filtersData.status}
									onChange={e => {
										changeFiltersData('status', e)
									}}
								>
									<option value=''>Todos</option>
									<option value={INVOICE_STATUS.PENDING}>Aguardando pagamento</option>
									<option value={INVOICE_STATUS.AWAITING_RISK_ANALYSIS}>
										Pagamento em análise
									</option>
									<option value={INVOICE_STATUS.CONFIRMED}>
										Pagamento confirmado
									</option>
									<option value={INVOICE_STATUS.RECEIVED}>
										Recebida
									</option>
									<option value={INVOICE_STATUS.OVERDUE}>Vencida</option>
									<option value={INVOICE_STATUS.REFUND_REQUESTED}>
										Estorno Solicitado
									</option>
									<option value={INVOICE_STATUS.REFUNDED}>Estornada</option>
									<option value={INVOICE_STATUS.CHARGEBACK_REQUESTED}>
										Recebido chargeback
									</option>
									<option value={INVOICE_STATUS.CHARGEBACK_DISPUTE}>
										Em disputa de chargeback
									</option>
									<option value={INVOICE_STATUS.AWAITING_CHARGEBACK_REVERSAL}>
										Disputa vencida
									</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg='3' className='mt-2'>
							<Form.Group>
								<Form.Label>Assinatura</Form.Label>
								<AsyncSelect
									styles={customStyles}
									name='signature_id'
									placeholder='Selecionar assinatura'
									isClearable
									defaultOptions
									loadOptions={debounceOptionsSignature}
									value={filtersData.signature_id}
									onChange={e => {
										changeFiltersData('signature_id', e)
									}}
									noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
								/>
							</Form.Group>
						</Col>
						<Col lg='3' className='mt-2'>
							{' '}
							<Form.Group>
								<Form.Label>Contrato</Form.Label>
								<AsyncSelect
									styles={customStyles}
									value={filtersData.contract_id}
									name='contract_id'
									placeholder='Selecionar contrato'
									isClearable
									defaultOptions
									loadOptions={debounceContractOptionsBp}
									onChange={e => {
										changeFiltersData('contract_id', e)
									}}
									noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
								/>
							</Form.Group>
						</Col>
						<Col lg='2' className='mt-2'>
							<Form.Group>
								<Form.Label>PDV</Form.Label>
								<Form.Select
									size='sm'
									name='pdv_id'
									onChange={e => {
										changeFiltersData('pdv_id', e)
									}}
									value={filtersData.pdv_id}
								>
									<option value=''>Selecionar</option>
									{dataAuth?.user?.pdvs?.map((item: any, index: number) => (
										<option key={item.id} value={item.id}>
											{item?.name}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
				</Card.Body>
				<hr />
				<Row>
					<Col align='end'>
						<Button
							size='sm'
							variant='falcon-default'
							type='button'
							className='me-2'
							style={{ position: 'relative', left: '-16px' }}
							onClick={() => {
								clearInputs()
							}}
						>
							Limpar Filtros
						</Button>{' '}
						<Button
							size='sm'
							type='button'
							style={{ position: 'relative', left: '-16px' }}
							onClick={() => {
								applyFilters()
							}}
						>
							Aplicar Filtros
						</Button>
					</Col>
				</Row>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default FilterDropdown
