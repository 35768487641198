import styled from 'styled-components';
interface StyledHeadingProps {
  isDependent?: boolean;
}

export const  CardContainer = styled.div<any>`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius:10px;
  background-color:${props => (props.isDark ? '#121e2d' : 'white')};
  cursor:pointer;
  color:${props => (props.isDark ? '#d8e2ef' : '#344050')};
  margin-top:12px;
`;

export const BpName = styled.h2<StyledHeadingProps>`
  margin: 0;
  font-size:  0.875rem;
  margin-bottom: ${(props) => (props.isDependent ? '5px' : '')};
`;

export const StatusCard = styled.span<StyledHeadingProps>`
  float: right;
    position:relative;
    font-size:  0.875rem;
    top:${(props) => (props.isDependent ? '-47px' : '-45px')};
`;
export const DisableDependentLable = styled.div`
color:red;
font-size:13px;
top:32px;
right:14px;
position:relative;
&:hover {
  color:  #2c7be5;
  text-decoration:underline;
}
font-weight:bold;
`;