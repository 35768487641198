import request from 'services/vittamais-core/config/request';

const exportContracts = async (filter: any) => {
    let query = '';

    if (filter?.start_created_at && filter?.end_created_at) {
        query = "?start_created_at=" + filter?.start_created_at + "&end_created_at=" + filter?.end_created_at;
    }
    else if (filter?.start_signed_at && filter?.end_signed_at) {
        query = "?start_signed_at=" + filter?.start_signed_at + "&end_signed_at=" + filter?.end_signed_at;
    }
    else if (filter?.start_canceled_at && filter?.end_canceled_at) {
        query = "?start_canceled_at=" + filter?.start_canceled_at + "&end_canceled_at=" + filter?.end_canceled_at;
    }


    if (filter?.pdv_id?.length>0) query += "&pdv_id=" + filter?.pdv_id;
    if (filter?.user_id) query += "&user_id=" + filter?.user_id
    if (filter?.subscription_status?.length>0)
        query += "&subscription_status=" + filter?.subscription_status
    if (filter?.contract_status?.length>0)
        query += "&contract_status=" + filter?.contract_status
    if (filter?.bp_id) query += "&bp_id=" + filter?.bp_id
    if (filter?.start_created_at && !query.includes("start_created_at"))
        query += "&start_created_at=" + filter?.start_created_at
    if (filter?.end_created_at && !query.includes("end_created_at"))
        query += "&end_created_at=" + filter?.end_created_at
    if (filter?.start_signed_at && !query.includes("start_signed_at"))
        query += "&start_signed_at=" + filter?.start_signed_at
    if (filter?.end_signed_at && !query.includes("end_signed_at"))
        query += "&end_signed_at=" + filter?.end_signed_at
    if (filter?.start_canceled_at && !query.includes("start_canceled_at"))
        query += "&start_canceled_at=" + filter?.start_canceled_at
    if (filter?.end_canceled_at && !query.includes("end_canceled_at"))
        query += "&end_canceled_at=" + filter?.end_canceled_at


    return request({
        method: "GET",
        responseType: 'arraybuffer', // important
        url:
            process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
            `/reports/export-contracts` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

export { exportContracts }

