import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	CardContainer,
	BpName,
	StatusCard,
	DisableDependentLable
} from '../styles/CustomCardHolder'
import { AiOutlineFileDone } from 'react-icons/ai'
import { BsPostcardHeart } from 'react-icons/bs'
import { FiUser } from 'react-icons/fi'
import { FaExternalLinkAlt } from 'react-icons/fa'
import SoftBadge from 'components/common/SoftBadge'
import SUBSCRIPTION_STATUS from 'helpers/subscriptionStatus'
import KINSHIP_TYPES from 'helpers/kinshipTypes'
import { RiParentLine } from 'react-icons/ri'

interface IPropsComponent {
	bp_card_number: string
	bp_name: string
	status: string | number
	url_to_path: string
	isDependent: boolean
	dependentData?: any
	loadDataForm?: any
	isDark: boolean
}

const CustomCardHolder: React.ComponentType<IPropsComponent> = ({
	dependentData,
	bp_name,
	bp_card_number,
	status,
	url_to_path,
	isDependent,
	loadDataForm,
	isDark
}) => {
	const navigate = useNavigate()
	const renderKinship = (type_dependent: number) => {
		let kinship = ''
		let findKinship = KINSHIP_TYPES.find(e => e?.value == type_dependent)
		if (findKinship?.label) {
			kinship = findKinship?.label
		}

		return kinship
	}
	// const disableDependent = async (id: number) => {
	// 	try {
	// 		if (!id) return
	// 		let confirmed = await showConfirm({
	// 			title: 'Atenção!',
	// 			text: 'Tem certeza que deseja desativar este dependente?'
	// 		})
	// 		if (!confirmed) return
	// 		await removeSubscription(id)
	// 		loadDataForm()
	// 		showToast('Dependente desativado com sucesso!', 'success')
	// 	} catch (e:any) {
	// 		console.log(e)
	// 		if (e?.response?.data?.error_message) {
	// 			showAlert({
	// 				type: 'error',
	// 				title: 'Ops...!',
	// 				text: e?.response?.data?.error_message
	// 			})
	// 			return
	// 		}
	// 		showAlert({type:"warning",title:"Ops...!",text:"Não conseguimos desativar este dependente, tente novamente."})
	// 	}
	// }
	return (
		<CardContainer
			onClick={() => {
				navigate(url_to_path)
			}}
			isDark={isDark}
		>
			{isDependent ? (
				<BpName isDependent={isDependent}>
					{' '}
					<RiParentLine
						size={14}
						style={{ position: 'relative', top: '-3px', marginRight: '7px' }}
					/>
					{renderKinship(dependentData?.type_dependents)}
				</BpName>
			) : null}
			<BpName isDependent={isDependent}>
				{' '}
				<FiUser
					size={14}
					style={{ position: 'relative', top: '-3px', marginRight: '7px' }}
				/>
				{bp_name}
			</BpName>
			{!isDependent?(
				<BpName className='mt-2' isDependent={isDependent}>
				{' '}
				<BsPostcardHeart
					size={14}
					style={{ position: 'relative', top: '-3px', marginRight: '7px' }}
				/>
				{bp_card_number}
			</BpName>
			):null}
			
			<StatusCard isDependent={isDependent}>
				{' '}
				{status == SUBSCRIPTION_STATUS.ACTIVATED ? (
					<SoftBadge pill bg={'success'}>
						Ativo
					</SoftBadge>
				) : status == SUBSCRIPTION_STATUS.CANCELED ? (
					<SoftBadge pill bg={'danger'}>
						Cancelado
					</SoftBadge>
				) : (
					<SoftBadge pill bg={'warning'}>
						Desativado
					</SoftBadge>
				)}
				{/* {isDependent && status == SUBSCRIPTION_STATUS.ACTIVATED ? (
					<DisableDependentLable
						onClick={e => {
							disableDependent(dependentData?.id)
							e.stopPropagation()
						}}
					>
						Desativar
					</DisableDependentLable>
				) : null} */}
			</StatusCard>
		</CardContainer>
	)
}

export default CustomCardHolder
