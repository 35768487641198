import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	CardContainer,
	InfoContainer,
	AdditionalInfo,
	InfoFinalContainer,
	SubInformation
} from '../styles/CustomCardVitta'
import { AiOutlineFileDone } from 'react-icons/ai'
import { BsPostcardHeart } from 'react-icons/bs'
import { FiUser } from 'react-icons/fi'
import { FaExternalLinkAlt } from 'react-icons/fa'
import SoftBadge from 'components/common/SoftBadge'
import SUBSCRIPTION_STATUS from 'helpers/subscriptionStatus'

interface IPropsComponent {
	bp_id: number
	subscription_id: number
	bp_name: string
	bp_card_number: string
	bp_card_contract_id: string
	status: string | number
	bp_type: string | number
	url_to_path: string
	isDark: boolean
}

const CustomCardVitta: React.ComponentType<IPropsComponent> = ({
	bp_id,
	subscription_id,
	bp_name,
	bp_card_number,
	bp_card_contract_id,
	status,
	bp_type,
	url_to_path,
	isDark
}) => {
	const navigate = useNavigate()
	const replaceString = (text: string) => {
		let newString = text?.length > 25 ? `${text.substring(0, 25)}...` : text
		return newString
	}
	const onNavigate = (url: string) => {
		window.open(url, '_blank')
	}
	const handleBorderCardColor = (status:any)=>
	{
		let color:string = "";
		if(isDark)
		{
			switch (status) {
				case SUBSCRIPTION_STATUS.ACTIVATED:
					color = "#5ce2aa";
				  break;
				case SUBSCRIPTION_STATUS.DISABLED:
					color = "#d9610c";
					break;
				case SUBSCRIPTION_STATUS.CANCELED:
					color = "#bc0406";
					break;

			  }
		}
		else
		{
			switch (status) {
				case SUBSCRIPTION_STATUS.ACTIVATED:
					color = "#1c8a67";
				  break;
				case SUBSCRIPTION_STATUS.DISABLED:
					color = "#ad643e";
					break;
				case SUBSCRIPTION_STATUS.CANCELED:
					color = "#9e354c";
					break;

			  }
		}

		return color;
	}
	return (
		<CardContainer
			isDark={isDark}
			color={
				handleBorderCardColor(status)
			}
			onClick={() => {
				navigate(url_to_path)
			}}
		>
			<InfoContainer>
				<SubInformation
					isDark={isDark}
					// onClick={e => {
					// 	onNavigate(`/clientes/${bp_id}`)
					// 	e.stopPropagation()
					// }}
				>
					{' '}
					<FiUser style={{ fontSize: '24px' }} />{' '}
					<span style={{ position: 'relative', top: '5px', left: '3px' }}>
						{' '}
						{replaceString(bp_name)}
					</span>
					<FaExternalLinkAlt className='icon' />
				</SubInformation>

				<SubInformation
					isDark={isDark}
					// onClick={e => {
					// 	onNavigate(`/assinaturas/${subscription_id}`)
					// 	e.stopPropagation()
					// }}
				>
					{' '}
					<BsPostcardHeart style={{ fontSize: '24px' }} />{' '}
					<span style={{ position: 'relative', top: '5px', left: '3px' }}>
						{' '}
						{replaceString(bp_card_number)}
					</span>
					<FaExternalLinkAlt className='icon' />
				</SubInformation>
				<SubInformation
					isDark={isDark}
					// onClick={e => {
					// 	onNavigate(`/contratos/${bp_card_contract_id}`)
					// 	e.stopPropagation()
					// }}
				>
					{' '}
					<AiOutlineFileDone style={{ fontSize: '24px' }} />{' '}
					<span style={{ position: 'relative', top: '5px', left: '1px' }}>
						Contrato #{replaceString(bp_card_contract_id)}
					</span>
					<FaExternalLinkAlt className='icon' />
				</SubInformation>
			</InfoContainer>
			<InfoFinalContainer isDark={isDark}>
				{' '}
				{/* <VerticalLine /> */}
				<AdditionalInfo>
					{/* <div
						className={status == '1' ? 'text-success' : 'text-danger'}
						style={{ fontWeight: 'bold', fontSize: '15px' }}
					>
						{status == '1' ? (
							<SoftBadge pill bg='success'>
								Ativo
							</SoftBadge>
						) : (
							<SoftBadge pill bg='danger'>
								Inativo
							</SoftBadge>
						)}
					</div> */}
					<div>
						{' '}
						{bp_type == '1' ? (
							<SoftBadge
								pill
								bg={
									status == SUBSCRIPTION_STATUS.ACTIVATED
										? 'success'
										: SUBSCRIPTION_STATUS.DISABLED == status
										? 'warning'
										: 'danger'
								}
							>
								Titular
							</SoftBadge>
						) : (
							<SoftBadge
								pill
								bg={
									status == SUBSCRIPTION_STATUS.ACTIVATED
										? 'success'
										: SUBSCRIPTION_STATUS.DISABLED == status
										? 'warning'
										: 'danger'
								}
							>
								Dependente
							</SoftBadge>
						)}
					</div>
				</AdditionalInfo>
			</InfoFinalContainer>
		</CardContainer>
	)
}

export default CustomCardVitta
