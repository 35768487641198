import styled from 'styled-components'

export const CardContainer = styled.div<any>`
	margin-top:10px;
	display: flex;
	border-radius: 6px;
	border-left: 10px solid ${props => props.color};
	padding-left: 15px;
	padding-right:20px;
	padding-top:6px;
	padding-bottom:6px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	position: relative;
	gap: 50px;
	max-width: 100%;
	cursor: pointer;
	background-color:${props => (props.isDark ? '#121e2d' : 'white')};
	color:${props => (props.isDark ? '#d8e2ef' : '#344050')} !important;
`

export const InfoContainer = styled.div`
	margin-bottom: 8px;
	
`

export const InfoFinalContainer = styled.div<any>`
	display: flex;
	gap: 10px;
	max-width: 100%;
	color:${props => (props.isDark ? '#d8e2ef' : '#344050')} !important;
	font-weight: bold;
	position:absolute;
	top:10px;
	right:12px;
	@media (max-width: 366px) {
        top:3px;
		left:5px;
      }
`

export const Title = styled.h4<any>`
	margin: 0;
	color:${props => (props.isDark ? '#d8e2ef' : '#344050')} !important;
`

export const Information = styled.p<any>`
	margin: 1px 0;
	color:${props => (props.isDark ? '#d8e2ef' : '#344050')} !important;
	font-weight: bold;
`

export const SubInformation = styled.p<any>`
	margin: 10px 0 0 1px;
	font-size: 13px;
	font-weight: bold;
	color:${props => (props.isDark ? '#d8e2ef' : '#344050')} !important;

	.icon {
		display: none;
		margin-left: 5px;
	}
`
//hover external link
// &:hover {
// 	color: blue;
// 	cursor: pointer;
// 	& > .icon {
// 		display: inline-block;
// 		position: relative;
// 		top: 3px;
// 		left: 2px;
// 	}
// }
export const AdditionalInfo = styled.div``

export const VerticalLine = styled.div`
	width: 3px;
	background-color: #ebebeb;
`
