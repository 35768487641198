import { FC } from 'react';
import PasswordResetForm from 'components/authentication/PasswordResetForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const PasswordReset: FC = function PasswordReset() {
    return (
        <AuthCardLayout leftSideContent={undefined} footer={false}>
            <h3>Resetar senha</h3>
            <PasswordResetForm hasLabel />
        </AuthCardLayout>
    );
};

export default PasswordReset;
