import { useState, FC } from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { IPasswordResetForm } from 'types/authentication/password';
import { Link } from 'react-router-dom';

const PasswordResetForm: FC<IPasswordResetForm> = function PasswordResetForm({ hasLabel }) {
    // State
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    // Handler
    const handleSubmit = (e: any): void => {
        e.preventDefault();
        toast.success('Login with your new password', {
            theme: 'colored'
        });
    };

    const handleFieldChange = (e: any): void => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form className={classNames('mt-3', { 'text-left': hasLabel })} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Nova senha</Form.Label>}
                <Form.Control placeholder={!hasLabel ? 'New Password' : ''} value={formData.password} name="password" onChange={handleFieldChange} type="password" />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Confirmar nova senha</Form.Label>}
                <Form.Control placeholder={!hasLabel ? 'Confirm Password' : ''} value={formData.confirmPassword} name="confirmPassword" onChange={handleFieldChange} type="password" />
            </Form.Group>

            <Button type="submit" className="w-100" disabled={!formData.password || !formData.confirmPassword}>
               Alterar senha
            </Button>
            <p/>
            <Link className="fs--1 text-600" to="/login">
                Voltar para login
                <span className="d-inline-block ms-1"> &rarr;</span>
            </Link>
        </Form>
    );
};


export default PasswordResetForm;
