import request from '../config/request'
import { IPropsFilter } from './interfaces'

const consultBenefits = (filter: IPropsFilter) => {
	let query = `?subscription_id=${filter.subscription_id}`

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/consult-benefits` +
			query,
		vtAuth: true
	})
}

export {consultBenefits}
