import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { version } from 'config'

const Footer: FC = function Footer() {
	const onNavigate = (url:string)=>{
	
		window.open(url, '_blank');
	}
	return (
		<footer className='footer'>
			<Row className='justify-content-between text-center fs--1 mt-4 mb-3'>
				<Col sm='auto'>
					<p className='mb-0 text-600'>
						{/* Direitos reservados - Vittá + Vantagens */}
						{/* <span className='d-none d-sm-inline-block'>| </span> */}
						<br className='d-sm-none' /> {new Date().getFullYear()} &copy;{' '}
						<a  href='#' onClick={(event)=>{onNavigate('https://www.souclinicavitta.com.br/')
						 event?.preventDefault()}}>Vittá</a>
					</p>
				</Col>
				<Col sm='auto'>
					<p className='mb-0 text-600'>v{version}</p>
				</Col>
			</Row>
		</footer>
	)
}

export default Footer
