import { Button, Form, Spinner } from "react-bootstrap";
import { GrMoney } from 'react-icons/gr'

interface CalculateContractValuesProps {
  onClick: any
  isDisabled: boolean
  showLoadingCalculateValues: any
}

export function CalculateContractValues({ onClick, isDisabled, showLoadingCalculateValues }: CalculateContractValuesProps) {
  return (
    <Button
      disabled={isDisabled}
      variant='falcon-default'
      style={{ position: 'relative', top: '30px', width: '100%' }}
      onClick={onClick}
    >
      {showLoadingCalculateValues ? (
        <div className='text-primary'>
          <Spinner
            variant='primary'
            size='sm'
            style={{ position: 'relative', left: '-25px', top: '2px' }}
          />
          Calculando...
        </div>
      ) : (
        <>
          <GrMoney style={{ position: 'relative', top: '-1px' }} />{' '}
          Calcular Valores
        </>
      )}
    </Button>
  )
}