import request from '../config/request'
import { IPropsFilter,IDataUpdateSingleInvoice,IDataUpdateSignatureInvoices,filterPrintBooklet, IDataConfirmCashInvoice } from './interfaces'

const search = (filter: IPropsFilter, page = 1, size = 10) => {
	let query = "?page=" + page + "&size=" + size
	if (filter.term)
		query += "&term="
			+ filter.term
	if (filter.contract)
		query += "&contract="
			+ filter.contract
	if (filter.subscription)
		query += "&subscription="
			+ filter.subscription
	if (filter.status)
		query += "&status="
			+ filter.status
	if (filter.business_partner)
		query += "&business_partner="
			+ filter.business_partner
	if (filter.due_date_final)
		query += "&due_date_final="
			+ filter.due_date_final
	if (filter.due_date_init)
		query += "&due_date_init="
			+ filter.due_date_init

			if (filter.pdv_id)
			query += "&pdv_id="
				+ filter.pdv_id

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/invoices` +
			query,
		vtAuth: true
	})
}

const loadDataEditform = async (id: number) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/invoices/${id}`,
		vtAuth: true
	});
}

const updateSingleInvoice = async (invoice_id:number, invoice_data:IDataUpdateSingleInvoice) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/invoices/${invoice_id}`,
        data: invoice_data,
        vtAuth: true
    });
}

const confirmCashInvoice = async (invoice_id:number, invoice_data:IDataConfirmCashInvoice) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/invoices/${invoice_id}/confirm-cash`,
        data: invoice_data,
        vtAuth: true
    });
}
const undoCashInvoice = async (id: number) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/invoices/${id}/undo-cash`,
		vtAuth: true
	});
}
const updateSignatureInvoices = async (invoice_data:IDataUpdateSignatureInvoices) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/invoices-subscription`,
        data: invoice_data,
        vtAuth: true
    });
}

const searchUpdateInvoices = async (invoice_id:number) => {
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/force-update-invoices/${invoice_id}`,
        vtAuth: true
    });
}

const printBooklet = async (filter_data:filterPrintBooklet) => {
	let query = `?subscription=${filter_data?.subscription}&month=${filter_data?.month}&year=${filter_data?.year}`
    return request({
        method: "GET",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/invoices-print-carnet`  +
		query,
        vtAuth: true,
    });
}
const deleteInvoice = async (invoice_id:number) => {
    return request({
        method: "POST",
        url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/delete-invoices/${invoice_id}`,
        vtAuth: true
    });
}


export { search,loadDataEditform,updateSingleInvoice,updateSignatureInvoices, searchUpdateInvoices, printBooklet, confirmCashInvoice, undoCashInvoice,deleteInvoice }

