import React,{useEffect} from 'react'
import Error404 from 'components/errors/Error404'
import useAuth from 'hooks/useAuth'
import { hasPermission } from 'helpers/hasPermission'
interface RouteMiddlewareProps {
	path?: string
	permissions?: string[]
	element: any
}
interface IPropsHasPermission
{
	checkPermissions?:string[]
}

const RouteMiddleware: React.FC<RouteMiddlewareProps> = ({
	permissions,
	element
}) => {
	const {dataAuth} = useAuth();


	let hasRelease = true
	if (!hasPermission(dataAuth?.user?.access_roles,permissions)) {
		hasRelease = false
	}

	if (!hasRelease) {
		return <Error404 />
	}

	return element;
}

export default RouteMiddleware
