import { Form } from "react-bootstrap";

interface MembershipTierProps {
  onChange: any
  isDisabled: boolean
  value: any
  membershipList: any
}

export function MembershipTier({ onChange, isDisabled, value, membershipList }: MembershipTierProps) {
  return (
    <Form.Group>
      <Form.Label>Plano</Form.Label>
      <Form.Select
        name='membership_tier_id'
        onChange={onChange}
        disabled={isDisabled}
        value={value}
      >
        <option value=''>Selecionar plano</option>
        {membershipList?.map((item: any, index: number) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}