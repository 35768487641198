import request from '../config/request'
import { IPropsFilter, IDataCreate } from './interfaces'

const search = (filter: IPropsFilter, page = 1, size = 20) => {
	let query = "?page=" + page + "&size=" + size
	if (filter.bp_id)
		query += "&business_partner="
			+ filter.bp_id
	if (filter.contract_id)
		query += "&contract="
			+ filter.contract_id
	if (filter.status)
		query += "&status="
			+ filter.status
	if (filter.term) {
		query += "&term="
			+ filter.term
	}
	if (filter.pdv_id) {
		query += "&pdv_id="
			+ filter.pdv_id
	}
	if (filter.type_subscription) {
		query += "&type_subscription="
			+ filter.type_subscription
	}


	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/subscriptions` +
			query,
		vtAuth: true
	})
}
const loadDataEditform = async (id: number) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/subscriptions/${id}`,
		vtAuth: true
	});
}
const create = async (data: IDataCreate) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/subscriptions-dependents`,
		data: data,
		vtAuth: true
	});
}
const removeSubscription = async (id: number) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/subscriptions-dependents/cancel/${id}`,
		vtAuth: true
	});
}

const searchUpdateSubscription = async (subscription_id: number) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/force-update-subscription/${subscription_id}`,
		vtAuth: true
	});
}

const renegotiateSubscriptionData = async (subscription_id: number, data: any) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/renegotiate-subscription/${subscription_id}`,
		data: data,
		vtAuth: true
	});
}

const generateCancellationFee = async (subscription_id: number, data: any) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/cancell-penalty-subscription/${subscription_id}`,
		data: data,
		vtAuth: true
	});
}

const updatePublicNumber = async (subscription_id: number, data: any) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/update-public-number-subscription/${subscription_id}`,
		data: data,
		vtAuth: true
	});
}

const exportSubscription= async (filter: any) => {
    let query = '';

    if (filter?.start_created_at && filter?.end_created_at) {
        query = "?start_created_at=" + filter?.start_created_at + "&end_created_at=" + filter?.end_created_at;
    }
   


    if (filter?.pdv_id?.length>0) query += "&pdv_id=" + filter?.pdv_id;
    if (filter?.subscription_status?.length > 0)
        query += "&subscription_status=" + filter?.subscription_status
    if (filter?.bp_id) query += "&bp_id=" + filter?.bp_id
    if (filter?.contract_id) query += "&contract_id=" + filter?.contract_id
    if (filter?.start_created_at && !query.includes("start_created_at"))
        query += "&start_created_at=" + filter?.start_created_at
    if (filter?.end_created_at && !query.includes("end_created_at"))
        query += "&end_created_at=" + filter?.end_created_at

    return request({
        method: "GET",
        responseType: 'arraybuffer', // important
        url:
            process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
            `/reports/export-subscription` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

export { search, loadDataEditform, create, removeSubscription, searchUpdateSubscription, renegotiateSubscriptionData, generateCancellationFee, updatePublicNumber, exportSubscription }
