import { Form } from "react-bootstrap";

interface DependentsQuantityProps {
  id: string | undefined
  onChange: any
  onKeyUp: any
  isDisabled: boolean
  value: any
}

export function DependentsQuantity({ id, onChange, onKeyUp, isDisabled, value }: DependentsQuantityProps) {
  return (
    <Form.Group>
      <Form.Label>Dependentes</Form.Label>
      <Form.Control
        value={value}
        name='holder_dependents_quantity'
        onChange={onChange}
        onKeyUp={onKeyUp}
        type='number'
        disabled={isDisabled}
      />
    </Form.Group>
  )
}