import { IoMdArrowDropdown } from 'react-icons/io'
import React, { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import avatar from 'assets/img/logos/cat.jpg'
import Avatar from 'components/common/Avatar'
import { AvatarWrapper, AvatarLetter } from './styles'
import useAuth from 'hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
const ProfileDropdown: FC = function ProfileDropdown() {
	const { SignOut, dataAuth } = useAuth()
	const navigate = useNavigate()
	return (
		<Dropdown navbar as='li'>
			<Dropdown.Toggle
				bsPrefix='toggle'
				as={Link}
				to='#'
				className='pe-0 ps-2 nav-link'
			>
				{/* <Avatar src={avatar} size={undefined} />{' '} */}
				<span
					className='info-avatar'
					style={{ position: 'relative', top: '-9px', display: 'flex', gap: '4px' }}
				>
					{' '}
					<AvatarWrapper>
						<AvatarLetter>{dataAuth?.user?.name?.charAt(0)}</AvatarLetter>
					</AvatarWrapper>{' '}
					<span style={{ position: 'relative', top: '11px' }}>
						{dataAuth?.user?.name} <IoMdArrowDropdown size={20} />
					</span>
				</span>
			</Dropdown.Toggle>

			<Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
				<div className='bg-white rounded-2 py-2 dark__bg-1000'>
					{/* <Dropdown.Item className='fw-bold text-warning' href='#!'>
						<FontAwesomeIcon icon='crown' className='me-1' />
						<span>Go Pro</span>
					</Dropdown.Item>
					<Dropdown.Divider /> */}
					{/* <Dropdown.Item href='#!'>Set status</Dropdown.Item>
					<Dropdown.Item as={Link} to='/user/profile'>
						Profile &amp; account
					</Dropdown.Item>
					<Dropdown.Item href='#!'>Feedback</Dropdown.Item>
					<Dropdown.Divider /> */}
					{/* <Dropdown.Item as={Link} to='/user/settings'>
						Settings
					</Dropdown.Item> */}
					<Dropdown.Item
						onClick={() => {
							navigate('/meu-perfil')
						}}
					>
						<FontAwesomeIcon icon='user' className='me-1' /> Meu Perfil
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => {
							SignOut()
						}}
					>
						<FontAwesomeIcon icon='arrow-left' className='me-1' /> Sair
					</Dropdown.Item>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default ProfileDropdown
