import { Form } from "react-bootstrap";
import { NumericFormat } from 'react-number-format'

interface PriceProps {
  isDisabled: boolean
  value: any
  isAddendum?: boolean
}

export function Price({ isDisabled, value, isAddendum }: PriceProps) {
  return (
    <Form.Group>
      <Form.Label>{isAddendum?'Valor Aditivo':'Preço'}</Form.Label>
      <NumericFormat
        name='price'
        displayType={'input'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        prefix={'R$ '}
        className='form-control'
        disabled={isDisabled}
      />
    </Form.Group>
  )
}