import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import { IPropsModalData } from './interfaces'
import { confirmCashInvoice, updateSingleInvoice } from 'services/vittamais-core/invoice/invoice'
import { NumericFormat } from 'react-number-format'

interface IModalConfim{
    show: boolean;
    onClose: () => void;
    FormData: any;
    setFormData: any
    loadDataPage: ()=> void;
}
function ModalConfirmCash({
    show,
    onClose,
    FormData,
    setFormData,
    loadDataPage
}: IModalConfim) {
    const { showConfirm, showAlert } = useSweetAlert()
    const { showToast } = useToast()
    const [loading, setLoading] = useState(false)
    const [FormState, setFormState] = useState({
        payment_date: '',
        value: 0,
    })
   
    const handleSubmit = async () => {
        try {
            if (
                !FormState.payment_date &&
                !FormState.value
            ) {
                onClose()
                return
            }
            let confirmed = await showConfirm({
                title: 'Atenção!',
                text: 'Tem certeza que deseja confirmar esse pagamento?'
            })
            if (!confirmed) return
            //chamar rota do back


            let dataToConfirm: any = {
                value: FormData.value,
                paymentDate: FormState.payment_date,

            }
            setLoading(true)
            await confirmCashInvoice(FormData?.id, dataToConfirm)
            setLoading(false)
            await loadDataPage()
            showToast('Pagamento confirmado!', 'success')
            //setFormData(newForm)
            onClose()
        } catch (e: any) {
            setLoading(false)
            console.log(e)
            if (e?.response?.data?.error_message) {
                showAlert({
                    type: 'warning',
                    title: 'Ops...!',
                    text: e?.response?.data?.error_message
                })
                return
            }

            showAlert({
                type: 'warning',
                title: 'Ops...!',
                text: 'Algo deu errado, tente novamente mais tarde.'
            })
        }
    }
    useEffect(() => {

        if (!FormState.payment_date || !FormState.value) {
            const dataAtual = new Date();
			const ano = dataAtual.getFullYear();
			const mes = dataAtual.getMonth() + 1; 
			const dia = dataAtual.getDate();
			const dataFormatada = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
            setFormState({
                payment_date: dataFormatada,
                value: FormData.value,
            })
        }
    }, [show])

    if (!show) return null
    return (
        <>
            <Modal
                show={show}
                // onHide={() => {
                // 	onClose()
                // }}
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>Recebimento em dinheiro da Fatura - #{FormData.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Valor da fatura</Form.Label>
                        <NumericFormat
                            name='value'
                            displayType={'input'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={FormState.value}
                            prefix={'R$ '}
                            className='form-control'
                            disabled
                        // onValueChange={values =>
                        //     handleFieldChange('month_value', values.floatValue)
                        // }
                        />
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Form.Label>Data de pagamento</Form.Label>
                        <Form.Control
                            value={FormState.payment_date}
                            name='payment_date'
                            type='date'
                            onChange={e => {
                                setFormState({ ...FormState, payment_date: e?.target?.value })
                            }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={() => {
                            onClose()
                        }}
                        disabled={loading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            handleSubmit()
                        }}
                        disabled={loading}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalConfirmCash
