import Modal from 'react-bootstrap/Modal'
import { Form, Row, Col, Button, Table } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { useState, useEffect } from 'react'
import INVOICE_STATUS from 'helpers/invoiceStatus'
import SoftBadge from 'components/common/SoftBadge'
import { renegotiateSubscriptionData } from 'services/vittamais-core/subscription/subscription'
import { generateCancellationFee } from 'services/vittamais-core/subscription/subscription'
import { updatePublicNumber } from 'services/vittamais-core/subscription/subscription'
interface IPropsModal {
	show: boolean
	onClose: (data?: { action?: string }) => void
	subscriptionId: any
}
function ModalAddPublicNumber({ subscriptionId, show, onClose }: IPropsModal) {
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [showLoading, setShowLoading] = useState(false)
	const initialState = {
		public_number: ''
	}
	const [formData, setFormData] = useState<any>(initialState)
	const handleFieldChange = (name: string, e: any): void => {
		setFormData({
			...formData,
			[name]: e.target.value
		})
	}
	const onsubmit = async () => {
		try {
			setShowLoading(true)

			await updatePublicNumber(subscriptionId, {
				public_number: formData?.public_number
			})

			showToast('Número de cartão adicionado com sucesso!', 'success')

			onClose({ action: 'refresh' })
		} catch (e: any) {
			setShowLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'error',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
		setShowLoading(false)
	}

	if (!show) return null
	return (
		<>
			<Modal show={show} size='lg'>
				<Modal.Header>
					<Modal.Title>Criar número do cartão</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<Form.Group>
								<Form.Label>Número Cartão</Form.Label>
								<Form.Control
									name='public_number'
									onChange={e => {
										handleFieldChange('public_number', e)
									}}
									type='text'
									value={formData.public_number}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => {
							onClose()
						}}
						disabled={showLoading}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						onClick={async () => {
							if (!formData?.public_number || formData?.public_number == '') {
								return showAlert({
									type: 'warning',
									title: 'Atenção!',
									text: 'É necessário preencher todos os campos!'
								})
							}
							let confirmed = await showConfirm({
								title: 'Atenção!',
								text: 'Tem certeza que deseja utilizar este número de cartão?'
							})
							if (!confirmed) return
							onsubmit()
						}}
						disabled={showLoading}
					>
						{showLoading ? 'Enviando solicitação...' : 'Confirmar'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalAddPublicNumber
