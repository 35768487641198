import React, { createContext, useEffect, useState, ReactNode, FC } from 'react'
import { ITypeAuthContextValue, ITypeResponse, IDataPDV } from './AuthInterface'

export const AuthContext = createContext<ITypeAuthContextValue>({
	dataAuth: {
		authenticate: false,
		user: {},
		origin_scope: {
			show_modal: true,
			pdv_origin: null
		}
	},
	saveDataLoginOnStorage: () => {},
	SignOut: () => {},
	fillScopePDV: () => {},
	openSettings:()=>{}
})

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [user, setUser] = useState({
		authenticate: false,
		user: {},
		origin_scope: { show_modal: true, pdv_origin: {} }
	})

	useEffect(() => {
		const authUser = localStorage.getItem('vittavantagens.identity')
		let refreshState = { ...user }
		if (authUser && JSON.parse(authUser)) {
			let dataUser = JSON.parse(authUser)
			if (dataUser?.token) {
				refreshState = { ...dataUser }
				refreshState.authenticate = true
			}
		}
		const authScopePdv = localStorage.getItem('vittavantagens.scope.pdv')
		if (authScopePdv && JSON.parse(authScopePdv)) {
			let dataPDV = JSON.parse(authScopePdv)
			if (dataPDV?.id) {
				refreshState.origin_scope = { show_modal: false, pdv_origin: dataPDV }
			}
		}

		setUser(refreshState)
	}, [])

	const saveDataLoginOnStorage = (response: ITypeResponse) => {
		setUser({
			authenticate: true,
			user: { ...response?.user },
			origin_scope: { show_modal: true, pdv_origin: {} }
		})

		localStorage.setItem(
			'vittavantagens.identity',
			JSON.stringify({
				token: response?.token,
				user: { ...response?.user },
				origin_scope: { show_modal: true, pdv_origin: null }
			})
		)
	}

	const SignOut = () => {
		localStorage.removeItem('vittavantagens.identity')
		localStorage.removeItem('vittavantagens.scope.pdv')
		window.location.href = '/login'
		setUser({
			authenticate: false,
			user: {},
			origin_scope: { show_modal: true, pdv_origin: {} }
		})

	}

	const fillScopePDV = (pdv: IDataPDV) => {
		localStorage.setItem('vittavantagens.scope.pdv', JSON.stringify({ ...pdv }))
		let new_data = { ...user }
		new_data.origin_scope = { show_modal: false, pdv_origin: pdv }
		setUser(new_data)
	}
	const openSettings = ()=>
	{
		let data = {...user};
		data.origin_scope.show_modal = true;
		setUser(data);
	}

	let value: ITypeAuthContextValue = {
		dataAuth: user,
		signed: !!user.authenticate,
		SignOut: SignOut,
		saveDataLoginOnStorage: saveDataLoginOnStorage,
		fillScopePDV: fillScopePDV,
		openSettings:openSettings
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
