import React, { FC, useContext, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Collapse, Nav } from 'react-bootstrap'
import classNames from 'classnames'
import AppContext from 'context/Context'
import { ChildrenType, ICollapseItems, INavbarVerticalMenu } from 'types'
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem'


const CollapseItems: FC<ICollapseItems> = function CollapseItems({ route }) {
	const { pathname } = useLocation()


	const openCollapse = (childrens: ChildrenType[]): boolean => {
		const checkLink = (children: ChildrenType): boolean => {
			if (children.to === pathname) {
				return true
			}
			return (
				Object.prototype.hasOwnProperty.call(children, 'children') &&
				(children?.children?.some(checkLink) as boolean)
			)
		}
		return childrens.some(checkLink)
	}

	const [open, setOpen] = useState(
		openCollapse(route.children as ChildrenType[])
	)

	return (
		<Nav.Item as='li'>
			<Nav.Link
				onClick={(): void => {
					setOpen(!open)
				}}
				className={classNames('dropdown-indicator cursor-pointer', {
					'text-500': !route.active
				})}
				aria-expanded={open}
				// {...route}
			>
				<NavbarVerticalMenuItem route={route} />
			</Nav.Link>
			<Collapse in={open}>
				<Nav className='flex-column nav' as='ul'>
					<NavbarVerticalMenu routes={route.children as ChildrenType[]} />
				</Nav>
			</Collapse>
		</Nav.Item>
	)
}

const NavbarVerticalMenu: FC<INavbarVerticalMenu> =
	function NavbarVerticalMenu({ routes }) {
		const {
			config: { showBurgerMenu },
			setConfig
		} = useContext(AppContext)

		const handleNavItemClick = (): void => {
			if (showBurgerMenu) {
				setConfig('showBurgerMenu', !showBurgerMenu)
			}
		}
		//filtrar rotas aqui a serem exibidas, conforme as permissions...
		return (
			<>
				{routes?.map((route: ChildrenType) => {
						
					if (!route.children) {
						return (
							<Nav.Item as='li' key={route.name} onClick={handleNavItemClick}>
								<NavLink
									end={route.exact}
									to={route.to as string}
									state={{ open: route.to === '/authentication-modal' }}
									className={({ isActive }): string =>
										isActive ? 'active nav-link' : 'nav-link'
									}
								>
									<NavbarVerticalMenuItem route={route} />
								</NavLink>
							</Nav.Item>
						)
					}
					return <CollapseItems route={route} key={route.name} />
				})}
			</>
		)
	}

export default NavbarVerticalMenu
