import { FC, useState, useEffect } from 'react'
import {
	Card,
	Table,
	Button,
	Row,
	Col,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import { search } from 'services/vittamais-core/customer/customer'
import { IResponseSearchData, IDataBusinessPartner } from './interfaces'
import SoftBadge from 'components/common/SoftBadge'
import CustomPagination from 'components/common/CustomPagination'
import CustomLoading from 'components/common/CustomLoading'
const ListCustomers: FC = function ListCustomers() {
	const navigate = useNavigate()
	const [listData, setListData] = useState({
		total_items: 0,
		total_pages: 0,
		current_page: 1,
		data: []
	})
	const [showLoading, setShowLoading] = useState(false)
	const [inputSearchData, setInputSearchData] = useState('')
	const searchCustomers = async (page = 1, sizePerPage = 10) => {
		setShowLoading(true)
		try {
			let filter = {
				term: inputSearchData
			}

			let data: IResponseSearchData = await search(filter, page, sizePerPage)

			setListData({
				total_items: data.total_items,
				total_pages: data.total_pages,
				current_page: data.current_page,
				data: data.data
			})
		} catch (e) {
			console.log(e)
		}
		setShowLoading(false)
	}

	const renderStatus = (status: number) => {
		if (status == 1) {
			return (
				<SoftBadge pill bg='success'>
					Ativo
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='danger'>
					Inativo
				</SoftBadge>
			)
		}
	}

	useEffect(() => {
		searchCustomers()
	}, [])

	return (
		<Row>
			<Col md={12}>
				<Card>
					<Card.Body>
						<Row>
							<Col>
								{' '}
								<h5 style={{ fontSize: 18 }}>Lista de clientes </h5>
							</Col>{' '}
						</Row>
						<Row className='mt-4'>
							<Col lg='7' className='mb-2'>
								{' '}
								<InputGroup>
									<FormControl
										placeholder='Procurar por nome ou cpf/cnpj do cliente ou id do cliente'
										aria-label="Recipient's username with two button addons"
										onChange={(e: any) => {
											setInputSearchData(e?.target?.value)
										}}
										onKeyDown={(e)=>{
											if(e.keyCode == 13 && !e.repeat)
											{
												searchCustomers()
											}
										}}
										value={inputSearchData}
									/>
									<Button
										variant='outline-primary'
										onClick={() => {
											searchCustomers()
										}}
									>
										<BiSearch size={20} />
									</Button>
								</InputGroup>
							</Col>
							<Col lg='5' align='end'>
								<Button
									variant='falcon-default'
									onClick={() => {
										navigate('/clientes/adicionar')
									}}
									style={{ position: 'relative', top: '-4px' }}
								>
									<FontAwesomeIcon icon='user-plus' /> Adicionar
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				<Card className='mt-3'>
					<Card.Body>
						{showLoading ? (
							<CustomLoading />
						) : (
							<div>
								<Table responsive striped>
									<thead>
										<tr>
											<th scope='col'>ID</th>
											{/* <th scope='col'>Tipo</th> */}
											<th scope='col'>Nome</th>
											<th scope='col'>CPF/CNPJ</th>
											<th scope='col'>E-mail</th>
											<th scope='col'>Telefone</th>
											<th scope='col'>Criado em</th>
											<th scope='col'>Status</th>
											<th className='text-end' scope='col'>
												Ações
											</th>
										</tr>
									</thead>
									<tbody>
										{listData?.data?.length > 0
											? listData?.data?.map(
													(item: IDataBusinessPartner, index: number) => (
														<tr key={item?.id}>
															<td>{item?.id}</td>
															{/* <td>
																{item?.bd_type == 1 ? 'Cliente' : 'Parceiro de benefício'}
															</td> */}
															<td>{item?.name}</td>
															<td>{item?.person_type == 1 ? item.cpf : item.cnpj}</td>
															<td>{item?.email}</td>
															<td>
																{item?.phone
																	? item?.phone
																	: item?.mobile_phone
																	? item?.mobile_phone
																	: ''}
															</td>
															<td>
																{item?.created_at
																	? 
																	// new Date(item?.created_at).toLocaleDateString("pt-BR", {
																	// 	hour: "2-digit",
																	// 	hour12: false,
																	// 	minute: "2-digit",
																	// 	second: "2-digit",
																	// })
																	new Date(item?.created_at).toLocaleDateString("pt-BR")
																	: null}
															</td>
															<td>{renderStatus(item?.status)}</td>
															<td className='text-end'>
																<FontAwesomeIcon
																	icon={'edit'}
																	style={{
																		color: '#2c7be5',
																		fontSize: '20px',
																		cursor: 'pointer',
																		position: 'relative',
																		left: '-10px'
																	}}
																	onClick={() => {
																		navigate(`/clientes/${item?.id}`)
																	}}
																/>
															</td>
														</tr>
													)
											  )
											: null}
									</tbody>
								</Table>
								<CustomPagination
									data={listData}
									handlePageChange={e => {
										searchCustomers(e)
									}}
								/>
							</div>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default ListCustomers
