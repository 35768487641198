import request from 'services/vittamais-core/config/request';

const exportInvoices = async (filter: any) => {
    let query = '';

    if (filter?.start_created_at && filter?.end_created_at) {
        query = "?start_created_at=" + filter?.start_created_at + "&end_created_at=" + filter?.end_created_at;
    }
    else if (filter?.start_due_date_at && filter?.end_due_date_at) {
        query = "?start_due_date_at=" + filter?.start_due_date_at + "&end_due_date_at=" + filter?.end_due_date_at;
    }
    else if (filter?.start_payment_date_at && filter?.end_payment_date_at) {
        query = "?start_payment_date_at=" + filter?.start_payment_date_at + "&end_payment_date_at=" + filter?.end_payment_date_at;
    }


    if (filter?.pdv_id?.length>0) query += "&pdv_id=" + filter?.pdv_id;
    if (filter?.invoice_status?.length > 0)
        query += "&invoice_status=" + filter?.invoice_status
    if (filter?.bp_id) query += "&bp_id=" + filter?.bp_id
    if (filter?.contract_id) query += "&contract_id=" + filter?.contract_id
    if (filter?.subscription_id) query += "&subscription_id=" + filter?.subscription_id
    if (filter?.start_created_at && !query.includes("start_created_at"))
        query += "&start_created_at=" + filter?.start_created_at
    if (filter?.end_created_at && !query.includes("end_created_at"))
        query += "&end_created_at=" + filter?.end_created_at
    if (filter?.start_due_date_at && !query.includes("start_due_date_at"))
        query += "&start_due_date_at=" + filter?.start_due_date_at
    if (filter?.end_due_date_at && !query.includes("end_due_date_at"))
        query += "&end_due_date_at=" + filter?.end_due_date_at
    if (filter?.start_payment_date_at && !query.includes("start_payment_date_at"))
        query += "&start_payment_date_at=" + filter?.start_payment_date_at
    if (filter?.end_payment_date_at && !query.includes("end_payment_date_at"))
        query += "&end_payment_date_at=" + filter?.end_payment_date_at


    return request({
        method: "GET",
        responseType: 'arraybuffer', // important
        url:
            process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
            `/reports/export-invoices` +
            query,
        vtAuth: true,
        returnFullResponse: true,
    })
}

export { exportInvoices }

