import React, { FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import PrivateRoutes from './routes/PrivateRoutes'
import PublicRoutes from 'routes/PublicRoutes'
const App: FC = function App() {
	const { dataAuth } = useAuth()
	
	return (
		<Router basename={'/'}>
			{dataAuth && dataAuth.authenticate ? <PrivateRoutes /> : <PublicRoutes />}
		</Router>
	)
}

export default App
