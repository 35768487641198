import request from '../config/request'
import { IPropsSearchFilter } from './interfaces'


const search = (filter: IPropsSearchFilter, page = 1, size = 10) => {
	let query = "?page=" + page + "&size=" + size
	if (filter.term)
		query += "&term="
			+ filter.term

	if (filter.pdv_id)
		query += "&pdv_id="
			+ filter.pdv_id

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/membership-tiers` +
			query,
		vtAuth: true
	})
}

export { search }
