import { Form } from "react-bootstrap";

interface AddendumTypeProps {
  onChange: any,
  isDisabled: boolean,
  value: any
}

export function AddendumType({ onChange, isDisabled, value }: AddendumTypeProps) {
  return (
    <Form.Group>
      <Form.Label>Ação</Form.Label>
      <Form.Select
        name='addendum_type'
        onChange={onChange}
        disabled={isDisabled}
        value={value}
      >
        <option value=''>Selecionar a ação</option>
        <option value='upgrade'>Upgrade</option>
        <option value='downgrade'>Downgrade</option>
      </Form.Select>
    </Form.Group>
  )
}