import Swal, { SweetAlertIcon } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './styles.css'
interface ShowAlertOptions {
	title: string
	text: string
	type: SweetAlertIcon
}

const useSweetAlert = () => {
	const MySwal = withReactContent(Swal)
	const popupOptions = {
		customClass: {
			popup: 'my-sweetalert-popup',
			title: 'my-sweetalert-title',
			content: 'my-sweetalert-content',
			confirmButton: 'my-sweetalert-confirm-button',
			container: 'my-sweetalert'
		}
		// you can define other default options here
	}

	const showAlert = ({ title, text, type }: ShowAlertOptions) => {
		MySwal.fire({
			title,
			text,
			icon: type,
			...popupOptions
		})
	}

	const showConfirm = async ({
		title,
		text
	}: Omit<ShowAlertOptions, 'type'>): Promise<boolean> => {
		const result = await MySwal.fire({
			title,
			text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar!',
			cancelButtonText: 'Cancelar!',
			...popupOptions
		})
		return result.isConfirmed
	}

	return { showAlert, showConfirm }
}

export default useSweetAlert
