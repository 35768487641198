import React, { FC } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import logo from 'assets/img/logos/logovitta.png'
import { ILogo } from 'types'

const Logo: FC<ILogo> = function Logo({
	at = 'auth',
	width = 58,
	className,
	textClass,
	...rest
}) {
	return (
		<Link
			to='/'
			className={classNames(
				'text-decoration-none',
				{ 'navbar-brand text-left': at === 'navbar-vertical' },
				{ 'navbar-brand text-left': at === 'navbar-top' }
			)}
			{...rest}
		>
			<div
				className={classNames(
					'd-flex',
					{
						'align-items-center py-3': at === 'navbar-vertical',
						'align-items-center': at === 'navbar-top',
						'flex-center fw-bolder fs-5 mb-4': at === 'auth'
					},
					className
				)}
			>
				<img className='me-2' src={logo} alt='Logo' width={width} />
				<span style={{ color: '#2995d3' }}>Vittá +</span>
			</div>
		</Link>
	)
}

export default Logo
