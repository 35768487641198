import styled from "styled-components";
import { GrMoney } from "react-icons/gr";
import { FaFileInvoiceDollar } from "react-icons/fa"
import { FiBookmark } from "react-icons/fi"


export const Card = styled.div<any>`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color:${props => (props.isDark ? '#121e2d' : 'white')};
  border-radius:10px;
  cursor:pointer;
  color:${props => (props.isDark ? '#d8e2ef' : '#344050')};
  margin-bottom:6px;
  margin-top:6px;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size:0.875rem !important;
  font-weight:500;
  line-height:1.2;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
`;

export const IconMoney = styled(GrMoney)`
  font-size: 14px;
  margin-right: 10px;
`;
export const IconInvoice = styled(FaFileInvoiceDollar)`
  font-size: 14px;
  margin-right: 10px;
`;

export const IconTypeInvoice = styled(FiBookmark)`
  font-size: 20px;
  margin-right: 10px;
`;


