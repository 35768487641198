import { FC, useEffect, useState,useContext } from 'react'
import {
	Card,
	Row,
	Col,
	Button,
	Spinner,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import CustomCardVitta from '../components/customCardVitta/Index'
import { IResponseSearchData, IitemSubscription } from './interfaces'
import { search } from 'services/vittamais-core/subscription/subscription'
import { FaSearch } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'
import FilterDropdown from 'pages/cards/list/components/FilterDropdown'
import { BiSearch } from 'react-icons/bi'
import CustomLoading from 'components/common/CustomLoading'
import AppContext from 'context/Context'
const ListCards: FC = function ListCards() {
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	const [searchParams] = useSearchParams()
	const [listData, setListData] = useState({
		total_items: 0,
		total_pages: 0,
		current_page: 1,
		data: []
	})
	const [filtersData, setFiltersData] = useState<any>({
		show: false,
		bp_id: '',
		status: '',
		contract_id: '',
		find_text: '',
		type_subscription: '',
		pdv_id:''
	})
	const [showLoading, setShowLoading] = useState(false)
	const searchSubscriptions = async (
		page = 1,
		sizePerPage = 20,
		scroll?: boolean,
		contractByQuery?: number
	) => {
		setShowLoading(true)
		try {
			
			let filter = {
				bp_id: filtersData?.bp_id?.value,
				status: filtersData?.status,
				pdv_id: filtersData?.pdv_id,
				contract_id: contractByQuery
					? contractByQuery
					: filtersData?.contract_id?.value,
				term: filtersData?.find_text,
				type_subscription: filtersData?.type_subscription
			}
			let itemsForm = []
			let data: IResponseSearchData = await search(filter, page, sizePerPage)

			if (scroll) {
				itemsForm = listData?.data?.concat(data?.data)
			} else {
				itemsForm = data.data
			}

			setListData({
				total_items: data.total_items,
				total_pages: data.total_pages,
				current_page: data.current_page,
				data: itemsForm
			})
		} catch (e) {
			console.log(e)
			setShowLoading(false)
		}
		setShowLoading(false)
	}

	useEffect(() => {
		let contractByQuery: any = searchParams.get('contract_id')
		if (contractByQuery) {
			setFiltersData({
				...filtersData,
				contract_id: {
					label: `Contrato: - #${contractByQuery}`,
					value: contractByQuery
				}
			})
		}

		searchSubscriptions(1, 20, false, contractByQuery)
	}, [])

	return (
		<>
			<Row>
				<Col md={12}>
					<Card>
						<Card.Body>
							<Row>
								<Col>
									{' '}
									<h5 style={{ fontSize: 18 }}>Assinaturas </h5>
								</Col>{' '}
							</Row>
							<div
								className='mt-4 mb-2'
								style={{
									display: 'flex',
									gap: '15px',
									flexDirection: 'row',
									flexFlow: 'wrap'
								}}
							>
								<div>
									{' '}
									<FilterDropdown
										searchSubscriptions={searchSubscriptions}
										filtersData={filtersData}
										setFiltersData={setFiltersData}
									/>
								</div>
								<div style={{ width: '50%' }}>
									{' '}
									<InputGroup>
										<FormControl
											placeholder='Procurar por nome ou cpf/cnpj do cliente ou número da assinatura'
											aria-label="Recipient's username with two button addons"
											onChange={(e: any) => {
												setFiltersData({ ...filtersData, find_text: e?.target?.value })
											}}
											onKeyDown={(e)=>{
												if(e.keyCode == 13 && !e.repeat)
												{
													searchSubscriptions()
												}
											}}
											value={filtersData.find_text}
										/>
										<Button
											variant='outline-primary'
											onClick={() => {
												searchSubscriptions()
											}}
										>
											<BiSearch size={20} />
										</Button>
									</InputGroup>
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{showLoading && listData?.total_items == 0 ? <CustomLoading/> : 	<Row className='mt-1'>
				{listData?.data?.map((item: IitemSubscription, index: number) => (
					<Col
						xs={12}
						sm={12}
						md={6}
						lg={4}
						xl={4}
						xxl={3}
						className='mt-2'
						key={item?.id}
					>
						<CustomCardVitta
							subscription_id={item?.id}
							url_to_path={`/assinaturas/${item?.id}`}
							bp_name={item?.business_partner?.name}
							bp_id={item?.business_partner?.id}
							bp_card_number={item?.public_number}
							bp_card_contract_id={item?.contract_id}
							status={item?.status}
							bp_type={item?.holder_subscription_id ? '2' : '1'}
							isDark={isDark}
						/>
					</Col>
				))}
				{listData?.current_page < listData?.total_pages ? (
					<Col sm='12' className='mt-5 mb-3' align='center'>
						{' '}
						<Button
						type='button'
							variant='primary'
							onClick={() => {
								let newPage = listData?.current_page + 1
								let pageSize = 20

								searchSubscriptions(newPage, pageSize, true)
							}}
						>
							{showLoading ? (
								<>
									Buscando...{' '}
									<Spinner
										variant='light'
										size='sm'
										style={{ position: 'relative', top: '3px' }}
									/>
								</>
							) : (
								<>
									<FaSearch color='white' /> Buscar mais assinaturas
								</>
							)}
						</Button>
					</Col>
				) : null}
			</Row>}
		
		</>
	)
}

export default ListCards
