import React from 'react';
import styled from 'styled-components';

interface TagProps {
  isSelected: boolean;
  icon: React.ReactElement;
  label: string;
  onClick: () => void;
}

const TagContainer = styled.div<{ isSelected: boolean }>`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${(props) => (props.isSelected ? '#2c7be5' : '#2c7be5')};
  border-radius: 50px;
  padding: 6px 20px;
  background-color: ${(props) => (props.isSelected ? '#2c7be5' : 'transparent')};
  color: ${(props) => (props.isSelected ? 'white' : '#2c7be5')};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.isSelected ? '#0030b9' : '#0030b9')};
    color: white;
  }
`;

const IconContainer = styled.div`
  margin-right: 8px;
  font-size: 22px;
  position: relative;
  top: -4px;
`;

const Label = styled.span`
  font-size: 16px;
`;

const CustomTagProps: React.FC<TagProps> = ({ isSelected, icon, label, onClick }) => {
  return (
    <TagContainer isSelected={isSelected} onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
      <Label>{label}</Label>
    </TagContainer>
  );
};

export default CustomTagProps;