import { useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppContext } from 'context/Context'
import Error404 from 'components/errors/Error404'
import Error500 from 'components/errors/Error500'
import Dashboard from 'pages/dashboard/Index'
import ListUsers from 'pages/users/list/Index'
import ErrorLayout from 'layouts/ErrorLayout'
import MainLayout from 'layouts/MainLayout'
import FormUsers from 'pages/users/form/Index'
import MyProfile from 'pages/profiles/MyProfile'
import ListContracts from 'pages/contracts/list/Index'
import FormContracts from 'pages/contracts/form/Index'
import RouteMiddleware from './Components/RouteMiddleware'
import ListCustomers from 'pages/customers/list/Index'
import FormCustomers from 'pages/customers/form/Index'
import ListCards from 'pages/cards/list/Index'
import FormCards from 'pages/cards/form/Index'
import ListCharges from 'pages/charges/list/Index'
import ViewCharges from 'pages/charges/view/Index'
import AddCharges from 'pages/charges/add/Index'
import ImportTitles from 'pages/import-files/titles/Index'
import OriginScope from 'pages/scope/Index'
import useAuth from 'hooks/useAuth'
import IndexReportContracts from 'pages/reports/contracts/Index'
import IndexReportInvoices from 'pages/reports/invoices/Index'
import Help from 'pages/help/Index'
// import SettingsToggle from 'components/settings-panel/SettingsToggle'
// import SettingsPanel from 'components/settings-panel/SettingsPanel'
import CustomLoadingBar from 'components/common/CustomLoadingBar'
import IndexReportSubscription from 'pages/reports/subscription/Index'
import ListPDVs from 'pages/pdvs/list/Index'
import FormPDVs from 'pages/pdvs/form/Index'
const is = require('is_js')

const PrivateRoutes: any = () => {
	const { dataAuth } = useAuth()
	const HTMLClassList = document.getElementsByTagName('html')[0].classList
	useContext(AppContext)

	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add('windows')
		}
		if (is.chrome()) {
			HTMLClassList.add('chrome')
		}
		if (is.firefox()) {
			HTMLClassList.add('firefox')
		}
	}, [HTMLClassList])

	return (
		<>
		<CustomLoadingBar/>
			<Routes>
				{/* <Route path="landing" element={<Landing />} /> */}
				<Route element={<ErrorLayout />}>
					<Route path='errors/404' element={<Error404 />} />
					<Route path='errors/500' element={<Error500 />} />
				</Route>

				<Route element={<MainLayout />}>
					<Route
						path='/'
						element={<RouteMiddleware element={<Dashboard />} permissions={['']} />}
					/>
					<Route
						path='/dashboard'
						element={<RouteMiddleware element={<Dashboard />} permissions={['']} />}
					/>
					<Route
						path='/usuarios'
						element={
							<RouteMiddleware element={<ListUsers />} permissions={['view_user']} />
						}
					/>
					<Route
						path='/usuarios/adicionar'
						element={
							<RouteMiddleware element={<FormUsers />} permissions={['add_user']} />
						}
					/>
					<Route
						path='/usuarios/:id'
						element={
							<RouteMiddleware element={<FormUsers />} permissions={['edit_user']} />
						}
					/>
					<Route
						path='/pdvs'
						element={
							<RouteMiddleware element={<ListPDVs />} permissions={['view_pdv']} />
						}
					/>
					<Route
						path='/pdvs/adicionar'
						element={
							<RouteMiddleware element={<FormPDVs />} permissions={['view_pdv']} />
						}
					/>
					<Route
						path='/pdvs/:id'
						element={
							<RouteMiddleware element={<FormPDVs />} permissions={['view_pdv']} />
						}
					/>
					<Route
						path='/meu-perfil'
						element={<RouteMiddleware element={<MyProfile />} permissions={['']} />}
					/>
					<Route
						path='/contratos'
						element={
							<RouteMiddleware
								element={<ListContracts />}
								permissions={['view_contract']}
							/>
						}
					/>
					<Route
						path='/contratos/adicionar'
						element={
							<RouteMiddleware
								element={<FormContracts />}
								permissions={['add_contract']}
							/>
						}
					/>
					<Route
						path='/contratos/adicionar/:contractId'
						element={
							<RouteMiddleware
								element={<FormContracts />}
								permissions={['add_contract']}
							/>
						}
					/>	
					<Route
						path='/contratos/:id/aditivo/:contractId'
						element={
							<RouteMiddleware
								element={<FormContracts />}
								permissions={['add_contract']}
							/>
						}
					/>	
					<Route
						path='/contratos/:id'
						element={
							<RouteMiddleware
								element={<FormContracts />}
								permissions={['view_contract']}
							/>
						}
					/>
					<Route
						path='/clientes'
						element={
							<RouteMiddleware element={<ListCustomers />} permissions={['view_bp']} />
						}
					/>
					<Route
						path='/clientes/adicionar'
						element={
							<RouteMiddleware element={<FormCustomers />} permissions={['add_bp']} />
						}
					/>
					<Route
						path='/clientes/:id'
						element={
							<RouteMiddleware element={<FormCustomers />} permissions={['edit_bp']} />
						}
					/>
					<Route
						path='/assinaturas'
						element={
							<RouteMiddleware
								element={<ListCards />}
								permissions={['view_subscription']}
							/>
						}
					/>
					<Route
						path='/assinaturas/:id'
						element={
							<RouteMiddleware
								element={<FormCards />}
								permissions={['view_subscription']}
							/>
						}
					/>
					<Route
						path='/faturas'
						element={
							<RouteMiddleware
								element={<ListCharges />}
								permissions={['view_invoice']}
							/>
						}
					/>
					<Route
						path='/importar/titulares'
						element={
							<RouteMiddleware element={<ImportTitles />} permissions={['view_contract']} />
						}
					/>
					{/* <Route
						path='/faturas/adicionar'
						element={
							<RouteMiddleware element={<AddCharges />} permissions={['']} />
						}
					/> */}
					<Route
						path='/faturas/:id'
						element={<RouteMiddleware element={<ViewCharges />} permissions={['view_invoice']} />}
					/>
					<Route
						path='/relatorios/contratos'
						element={<RouteMiddleware element={<IndexReportContracts />} permissions={['view_contract']} />}
					/>
						<Route
						path='/relatorios/faturas'
						element={<RouteMiddleware element={<IndexReportInvoices />} permissions={['view_invoice']} />}
					/>
					<Route
						path='/relatorios/assinaturas'
						element={<RouteMiddleware element={<IndexReportSubscription />} permissions={['view_subscription']} />}
					/>
							<Route
						path='/ajuda'
						element={<RouteMiddleware element={<Help />} permissions={['']} />}
					/>
					{/* <Route path='/' element={<Dashboard />} />  */}
					{/* <Route path='/dashboard' element={<Dashboard />} />
					<Route path='/usuarios' element={<ListUsers />} /> */}
					{/* <Route path='/usuarios/adicionar' element={<FormUsers />} /> */}
					{/* <Route path='/usuarios/:id' element={<FormUsers />} /> */}
					{/* <Route path='/meu-perfil' element={<MyProfile />} /> */}
				</Route>

				<Route path='*' element={<Navigate to='/errors/404' replace />} />
			</Routes>
			{/* <SettingsToggle />
      <SettingsPanel /> */}
			{/* <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      /> */}

			<OriginScope show={dataAuth?.origin_scope?.show_modal} />
		</>
	)
}

export default PrivateRoutes
