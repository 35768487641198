import React, { useContext, createContext, ReactNode, FC } from 'react'
import { ToastContainer, toast, ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export type ToastType = 'success' | 'info' | 'warning' | 'error'

export interface IToastContext {
	showToast: (message: string, type: ToastType) => void
}

const ToastContext = createContext<IToastContext>({
	showToast: () => null
})

export const useToast = () => useContext(ToastContext)

export const ToastifyProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const showToast = (message: string, type: ToastType) => {
		const options: ToastOptions = {
			position: 'top-right',
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'colored'
		}

		switch (type) {
			case 'success':
				toast.success(message, options)
				break
			case 'info':
				toast.info(message, options)
				break
			case 'warning':
				toast.warn(message, options)
				break
			case 'error':
				toast.error(message, options)
				break
			default:
				break
		}
	}

	const value = { showToast }

	return (
		<ToastContext.Provider value={value}>
			<ToastContainer />
			{children}
		</ToastContext.Provider>
	)
}
