export default {
    PENDING: 0,
    AWAITING_RISK_ANALYSIS: 1,
    CONFIRMED: 2,
    RECEIVED: 3,
    OVERDUE: 4,
    REFUND_REQUESTED: 5,
    REFUNDED: 6,
    CHARGEBACK_REQUESTED: 7,
    CHARGEBACK_DISPUTE: 8,
    AWAITING_CHARGEBACK_REVERSAL: 9
}