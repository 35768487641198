import React, {
	useContext,
	createContext,
	ReactNode,
	FC,
	useState
} from 'react'

interface LoadingContextType {
	showBar: boolean
	isLoading: boolean
	setLoading: (isLoading: boolean) => void
	setShowBar: (showBar: boolean) => void
}

const LoadingContext = createContext<LoadingContextType>({
	showBar: false,
	isLoading: false,
	setLoading: () => {},
	setShowBar: () => {}
})

export const LoadingBarProvider: FC<{ children: ReactNode }> = ({
	children
}) => {
	const [isLoading, setLoading] = useState(false)
	const [showBar, setShowBar] = useState(false)
	const setLoadingState = (newIsLoading: boolean) => {
		setLoading(newIsLoading)
	}

	return (
		<LoadingContext.Provider
			value={{
				showBar,
				isLoading,
				setLoading: setLoadingState,
				setShowBar: setShowBar
			}}
		>
			{children}
		</LoadingContext.Provider>
	)
}

export const useLoading = (): LoadingContextType => useContext(LoadingContext)
