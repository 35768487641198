import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { IPropsModal } from './interfaces'
import reasons from 'helpers/reasonsCancelContract'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { cancelContract } from 'services/vittamais-core/contract/contract'
import CONTRACT_STATUS from 'helpers/contractStatus'
function CancelContractModal({
	show,
	contract_id,
	onClose,
	FormData,
	handleFieldChange,
	setFormData
}: IPropsModal) {
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [loading, setLoading] = useState(false)
	const handleCancelContract = async () => {
		try {
			if (!FormData.cancel_reason) {
				return showAlert({
					type: 'info',
					title: 'Atenção!',
					text:
						'Para cancelar um contrato é necessário selecionar o motivo do cancelamento.'
				})
			}
			if (!contract_id) return
			let confirmed = await showConfirm({
				title: 'Atenção!',
				text: 'Tem certeza que deseja cancelar este contrato?'
			})
			if (!confirmed) return
			let dataToCancel = {
				cancel_reason: FormData.cancel_reason,
				cancel_obs: FormData.cancel_obs
			}
			setLoading(true)
			await cancelContract(contract_id, dataToCancel)
			setLoading(false)
			showToast('Contrato cancelado com sucesso!', 'success')

			let newForm = { ...FormData }

			newForm.status = CONTRACT_STATUS.CANCELED

			setFormData(newForm)
			onClose()
		} catch (e: any) {
			setLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
	}
	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				// onHide={() => {
				// 	onClose()
				// }}
				size='lg'
			>
				<Modal.Header>
					<Modal.Title>Cancelar Contrato - #{contract_id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Motivo Cancelamento</Form.Label>
						<Form.Select
							name='cancel_reason'
							onChange={handleFieldChange}
							value={FormData.cancel_reason}
						>
							<option value=''>Selecionar</option>
							{reasons?.map((item, index) => (
								<option key={item?.id} value={item.id}>
									{item.name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group className='mt-3'>
						<Form.Label>Observações</Form.Label>
						<Form.Control
							value={FormData.cancel_obs}
							name='cancel_obs'
							onChange={handleFieldChange}
							as='textarea'
							rows={3}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						disabled={loading}
						onClick={() => {
							onClose()
						}}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						disabled={loading}
						onClick={() => {
							handleCancelContract()
						}}
					>
						Confirmar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CancelContractModal
