import { Form } from "react-bootstrap";
import { NumericFormat } from 'react-number-format'

interface DiscountProps {
  isDisabled: boolean
  value: any
}

export function Discount({ isDisabled, value }: DiscountProps) {
  return (
    <Form.Group>
      <Form.Label>Desconto</Form.Label>
      <NumericFormat
        name='discount'
        displayType={'input'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        prefix={'R$ '}
        className='form-control'
        disabled={isDisabled}
      />
    </Form.Group>
  )
}