import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { IPropsModal } from './interfaces'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { GiSmartphone } from 'react-icons/gi'
import { AiOutlineMail } from 'react-icons/ai'
import CustomTag from 'components/common/CustomTagProps'
import { sendContract } from 'services/vittamais-core/contract/contract'

function SendContractModal({
	show,
	contract_id,
	onClose,
	FormData,
	setFormData
}: IPropsModal) {
	const { showAlert } = useSweetAlert()
	const [showLoading, setShowLoading] = useState(false)
	const { showToast } = useToast()
	const [selectedOptions, setSelectedOptions] = useState({
		email: false,
		sms: false
	})
	const handleSendContract = async () => {
		setShowLoading(true)
		try {
			if (!contract_id) return
			if (!selectedOptions.email && !selectedOptions.sms) {
				setShowLoading(false)
				return showAlert({
					type: 'info',
					title: 'Escolha uma opção de envio!',
					text: ''
				})
			}
			if (selectedOptions.email && selectedOptions.sms) {
				setShowLoading(false)
				return showAlert({
					type: 'info',
					title: 'Atenção!',
					text: 'Só é possível escolher uma opção para envio!'
				})
			}
			let typeToSend: string = 'email'
			if (selectedOptions?.sms) {
				typeToSend = 'sms'
			}
			await sendContract(contract_id, typeToSend)
			showToast('Contrato enviado com sucesso!', 'success')
			onClose({action:'success'})
		} catch (e: any) {
			setShowLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
		setShowLoading(false)
	}

	const handleSelectedTag = (name: string, selected: boolean) => {
		if (name == 'sms') {
			setSelectedOptions({
				email: false,
				sms: true
			})
		} else {
			setSelectedOptions({
				email: true,
				sms: false
			})
		}
	}
	const clearState = () => {
		setSelectedOptions({
			email: false,
			sms: false
		})
	}
	useEffect(() => {
		clearState()
	}, [show])
	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				// onHide={() => {
				// 	onClose()
				// }}
				size='lg'
			>
				<Modal.Header>
					<Modal.Title>Enviar Contrato - #{contract_id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<span>Qual a forma de envio?</span>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '20px',
							flexWrap: 'wrap',
							marginTop: '20px'
						}}
					>
						<CustomTag
							icon={<GiSmartphone />}
							label='SMS'
							isSelected={selectedOptions.sms}
							onClick={() => {
								handleSelectedTag('sms', !selectedOptions.sms)
							}}
						/>
						<CustomTag
							icon={<AiOutlineMail />}
							label='E-Mail'
							isSelected={selectedOptions.email}
							onClick={() => {
								handleSelectedTag('email', !selectedOptions.email)
							}}
						/>
					</div>
					<div className='mt-3'>
						<small>
							<strong className='text-primary'>
								Obs: Só é possível selecionar uma opção.
							</strong>
						</small>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => {
							onClose()
						}}
						disabled={showLoading}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							handleSendContract()
						}}
						disabled={showLoading}
					>
						{showLoading ? 'Enviando...' : 'Enviar'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default SendContractModal
