import { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import { Form, Table } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'


interface IPropsModal {
    show: boolean;
    onClose: any
    data: any

}
function ModalError({
    show,
    onClose,
    data,
}: IPropsModal) {



    if (!show) return null
    return (
        <>
            <Modal
                show={show}
                onHide={() => {
                    onClose()
                }}
                size='lg'
            >
                <Modal.Header style={{ flexDirection: 'column' }}>
                    <div style={{ width: '100%' }}>
                        <Modal.Title className='pb-2' style={{ color: "green" }}>Importação realizada com sucesso!</Modal.Title>
                    </div>
                    <div style={{ width: '100%' }}>
                        <Modal.Title style={{ color: "red" }}>Atenção! Esses titulares não foram importados pois já tinham assinatura ativa em outro contrato!</Modal.Title>
                    </div>
                </Modal.Header>


                <Modal.Body>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th scope='col'>Cliente</th>
                                <th scope='col'>CPF</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0
                                ? data?.map((item: any, index: number) => (
                                    <tr key={index}>
                                        <td>{item?.name}</td>
                                        <td>{item?.cpf}</td>

                                    </tr>
                                ))
                                : null}
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={() => {
                            onClose()
                        }}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalError
