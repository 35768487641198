import React, { useContext, useEffect, Fragment, FC,useState } from 'react'
import classNames from "classnames"
import { Nav, Navbar, Row, Col } from 'react-bootstrap'
import { navbarBreakPoint, topNavbarBreakpoint } from 'config'
import AppContext from 'context/Context'
import Flex from 'components/common/Flex'
import Logo from 'components/common/Logo'
import { capitalize } from 'helpers/utils'
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus'
import routes from 'routes/routes'
import { PageRoutesType } from 'types'
import NavbarVerticalMenu from './NavbarVerticalMenu'
import ToggleButton from './ToggleButton'
import useAuth from 'hooks/useAuth'
import { hasPermission } from 'helpers/hasPermission'

const NavbarLabel: FC<{ label: string }> = function NavbarLabel({ label }) {
	return (
		<Nav.Item as='li'>
			<Row className='mt-3 mb-2 navbar-vertical-label-wrapper'>
				<Col xs='auto' className='navbar-vertical-label navbar-vertical-label'>
					{label}
				</Col>
				<Col className='ps-0'>
					<hr className='mb-0 navbar-vertical-divider' />
				</Col>
			</Row>
		</Nav.Item>
	)
}
const NavbarVertical: FC = function NavbarVertical() {
	const {dataAuth} = useAuth();
	const [routesFiltered,setRoutesFiltered] = useState<any>([]);
	const {
		config: {
			navbarPosition,
			navbarStyle,
			isNavbarVerticalCollapsed,
			showBurgerMenu
		}
	} = useContext(AppContext)

	const HTMLClassList = document.getElementsByTagName('html')[0].classList

	useEffect(() => {
		filterRoutes();
		if (isNavbarVerticalCollapsed) {
			HTMLClassList.add('navbar-vertical-collapsed')
		} else {
			HTMLClassList.remove('navbar-vertical-collapsed')
		}
		return () => {
			HTMLClassList.remove('navbar-vertical-collapsed-hover')
		}
	}, [isNavbarVerticalCollapsed, HTMLClassList])

	// Control mouseEnter event
	let time: NodeJS.Timeout
	const handleMouseEnter = (): void => {
		if (isNavbarVerticalCollapsed) {
			time = setTimeout(() => {
				HTMLClassList.add('navbar-vertical-collapsed-hover')
			}, 100)
		}
	}
	const handleMouseLeave = (): void => {
		clearTimeout(time)
		HTMLClassList.remove('navbar-vertical-collapsed-hover')
	}
	const filterRoutes = ()=>
	{
		try
		{
			let newChildrens:any = [];
			let newRoutes:any = [...routes];
			for(let i=0; i<routes?.length; i++)
			{
				let route = routes[i];

				for(let j=0; j<route?.children?.length; j++)
				{
					let useChildren = route?.children[j];
					let dataToPush:any = {};
					if(useChildren?.permissions)
					{
						if(hasPermission(dataAuth?.user?.access_roles,useChildren?.permissions))
						{
							dataToPush = {...useChildren};
						}
					}
					if(dataToPush?.children && dataToPush?.children?.length > 0)
					{
						let newSubChildrens = [];
						for(let k=0; k<dataToPush?.children?.length; k++)
						{
							let useSubChildren = dataToPush?.children[k];
							if(hasPermission(dataAuth?.user?.access_roles,useSubChildren.permissions))
							{
								newSubChildrens.push(useSubChildren);
							}
						}
						dataToPush.children = newSubChildrens
					}
					if(dataToPush?.name)
					{
						newChildrens.push(dataToPush);
					}
				}
				if(i+1 >= routes?.length)
				{
					routes[i].children = newChildrens
				}
			}
			setRoutesFiltered(newRoutes);
		}
		catch(e)
		{
			console.log(e);
		}
		
	}
	return (
		<Navbar
		
			expand={navbarBreakPoint}
			className={classNames('navbar-vertical', 
			
			{
				[`navbar-${navbarStyle}`]: navbarStyle !== 'transparent',
				// [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent',
			}
			
			)}
			variant='light'
		>
			<Flex alignItems='center'>
				<ToggleButton />
				<Logo at='navbar-vertical' width={40} />
			</Flex>
			<Navbar.Collapse
				in={showBurgerMenu}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				// style={{
				// 	backgroundImage:
				// 		navbarStyle === 'vibrant'
				// 			? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
				// 			: 'none'
				// }}
			>
				<div className='navbar-vertical-content scrollbar'>
					<Nav className='flex-column' as='ul'>
						{routesFiltered?.map((route: PageRoutesType) => (
							<Fragment key={route.label}>
								{!route.labelDisable && <NavbarLabel label={capitalize(route.label)} />}
								<NavbarVerticalMenu routes={route.children} />
							</Fragment>
						))}
					</Nav>

					{navbarPosition === 'combo' && (
						<div className={`d-${topNavbarBreakpoint}-none`}>
							<div className='navbar-vertical-divider'>
								<hr className='navbar-vertical-hr my-2' />
							</div>
							<Nav navbar>
								<NavbarTopDropDownMenus />
							</Nav>
						</div>
					)}
					{/* <PurchaseCard /> */}
				</div>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default NavbarVertical
