import request from '../config/request'
import {IPropsFilter } from './interfaces'


const search = (filter: any, page = 1, size = 20) => {
	let query = "?page=" + page + "&size=" + size
    if(filter.state)
    query+="&state=" + filter.state
	if (filter.term)
		query += "&term="
			+ filter.term
	if(filter.ibge_code)
	{
		query += "&ibge_code="
		+ filter.ibge_code
	}
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/counties` +
			query,
		vtAuth: true
	})
}

export { search}
