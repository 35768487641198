import Main from 'Main'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'helpers/initFA'
import { AuthProvider } from 'context/Auth/Auth'
import { ToastifyProvider } from 'context/Toasts/Toastify'
import {LoadingBarProvider} from 'context/LoadingBar/LoadingBar'
const root = ReactDOM.createRoot(document.getElementById('main') as HTMLElement)
root.render(
	//strict mode faz o componente renderizar 2 vezes no modo dev
	// <React.StrictMode>
	<ToastifyProvider>
		<AuthProvider>
			<LoadingBarProvider>
			<Main>
				<App />
			</Main>
			</LoadingBarProvider>
		</AuthProvider>
	</ToastifyProvider>
	// </React.StrictMode>
)
