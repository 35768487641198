import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async'
import { FaUserAlt } from 'react-icons/fa'

interface BusinessPartnerProps {
  id: string | undefined
  styles: any
  isDisabled: boolean
  loadOptions: any
  value: any
  onChange: any
}

export function BusinessPartner({ id, styles, isDisabled, loadOptions, value, onChange }: BusinessPartnerProps) {
  return (
    <Form.Group>
      <Form.Label>
        Cliente{' '}
        {id ? (
          <FaUserAlt
            style={{ position: 'relative', top: '-2px', cursor: 'pointer', color: '#27bcfd' }}
            onClick={() => {
              window.open(`/clientes/${value?.value}`, '_blank')
            }}
          />
        ) : ('')}
      </Form.Label>
      <AsyncSelect
        styles={styles}
        name='business_partner_id'
        placeholder='Selecionar cliente'
        isClearable
        defaultOptions
        loadOptions={loadOptions}
        value={value}
        onChange={onChange}
        noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
        isDisabled={isDisabled}
      />
    </Form.Group>
  )
}