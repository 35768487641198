import Modal from 'react-bootstrap/Modal'
import { useState, useEffect } from 'react'
import { Button, Table } from 'react-bootstrap'
import { consultBenefits } from 'services/vittamais-core/benefit/benefit'
import { IDataConsultBenefit } from './interfaces'
interface propsModal {
	show: boolean
	subscription_id: any
	onClose: () => void
	isDark: boolean
}

function ModalBenefits({ show, subscription_id, onClose, isDark }: propsModal) {
	const [dataPage, setDataPage] = useState<any>([])

	const loadDataPage = async () => {
		let dataTable = []
		try {
			let result: IDataConsultBenefit[] = await consultBenefits({
				subscription_id: subscription_id
			})
			for (let i = 0; i < result?.length; i++) {
				let use = result[i]
				dataTable.push({
					business_partner_subscription: use.business_partner_subscription,
					name_benefit: use.name_benefit,
					total_amount_plan: use.total_amount_plan,
					reserved_amount: use.reserved_amount,
					quantity_used: use.quantity_used,
					available_quantity: use.available_quantity,
					is_unlimited: use.is_unlimited
				})
			}
		} catch (e) {
			console.log(e)
		}
		setDataPage(dataTable)
	}

	useEffect(() => {
		if (show) loadDataPage()
	}, [show])

	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				onHide={() => {
					onClose()
				}}
				size='xl'
				style={{ color: isDark ? '#d8e2ef !important' : '' }}
			>
				<Modal.Header>
					<Modal.Title>Benefícios</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight:"400px",overflow:"auto" }}>
					<div style={{ color: isDark ? '#d8e2ef !important' : '' }}>
						<Table responsive striped>
							<thead>
								<tr>
									<th scope='col'>Benefício</th>
									<th scope='col'>Quantidade do plano</th>
									<th scope='col'>Quantidade usada</th>
									<th scope='col'>Quantidade disponível</th>
								</tr>
							</thead>
							<tbody>
								{dataPage?.length > 0
									? dataPage?.map((item: IDataConsultBenefit, index: number) => (
											<tr key={'row' + index}>
												<td>{item?.name_benefit}</td>
												<td style={{ textAlign: 'center' }}>
													{!item?.is_unlimited ? item?.total_amount_plan : 'Ilimitado'}
												</td>
												<td style={{ textAlign: 'center' }}>{item?.quantity_used}</td>
												<td style={{ textAlign: 'center' }}>
													{!item?.is_unlimited ? item?.available_quantity : 'Ilimitado'}
												</td>
											</tr>
									  ))
									: null}
							</tbody>
						</Table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='primary'
						onClick={() => {
							onClose()
						}}
					>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalBenefits
