import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import { updateSingleInvoice } from 'services/vittamais-core/invoice/invoice'
interface IPropsEditModal {
    show: boolean;
    onClose: () => void;
    FormData: any;
    setFormData: any
    loadDataPage: ()=> void;
}
function ModalEditInvoice({
	show,
	onClose,
	FormData,
	setFormData,
	loadDataPage
}: IPropsEditModal) {
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [FormState, setFormState] = useState({
		payment_method: '',
		description: '',
		due_date: ''
	})
	function convertDate(inputData: string) {
		const dataObj = new Date(inputData)
		const dia = String(dataObj.getDate()).padStart(2, '0')
		const mes = String(dataObj.getMonth() + 1).padStart(2, '0') //
		const ano = String(dataObj.getFullYear())

		return `${dia}/${mes}/${ano}`
	}

	const handleUpdateInvoice = async () => {
		try {
			if (
				!FormState.description &&
				!FormState.payment_method &&
				!FormState.due_date
			) {
				onClose()
				return
			}
			let confirmed = await showConfirm({
				title: 'Atenção!',
				text: 'Tem certeza que deseja alterar essas informações da fatura?'
			})
			if (!confirmed) return
			//chamar rota do back

			let newForm = { ...FormData }
			if (FormState.description) {
				newForm.description = FormState.description
			}

			if (FormState.payment_method) {
				newForm.payment_method = FormState.payment_method
			}

			if (FormState.due_date) {
				// newForm.due_date = convertDate(FormState.due_date)
				newForm.due_date = FormState.due_date
			}

			let dataToUpdate = {
				subscription_id: null,
				contract_id: newForm.contract_id,
				due_date: FormState.due_date,
				value: newForm?.value,
				payment_method: newForm.payment_method,
				description: newForm?.description
			}

			await updateSingleInvoice(FormData?.id, dataToUpdate)
			showToast('Informações alteradas com sucesso!', 'success')
			setFormData(newForm)
			loadDataPage()
			onClose()
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
	}
	const clearState = () => {
		setFormState({
			payment_method: '',
			description: '',
			due_date: ''
		})
	}
	useEffect(() => {
		
		if(!FormState.payment_method|| !FormState.description|| !FormState.due_date){
			setFormState({
				payment_method: FormData.payment_method,
				description: FormData.description,
				due_date: FormData.due_date
			})
		}
	}, [show])

	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				// onHide={() => {
				// 	onClose()
				// }}
				size='lg'
			>
				<Modal.Header>
					<Modal.Title>Editar Fatura - #{FormData.id}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Vencimento</Form.Label>
						<Form.Control
							value={FormState.due_date}
							name='due_date'
							type='date'
							onChange={e => {
								setFormState({ ...FormState, due_date: e?.target?.value })
							}}
						/>
					</Form.Group>
					<Form.Group className='mt-3'>
						<Form.Label>Método de pagamento</Form.Label>
						<Form.Select
							name='payment_method'
							value={FormState.payment_method}
							onChange={e => {
								setFormState({ ...FormState, payment_method: e?.target?.value })
							}}
						>
							<option value=''>Selecionar</option>
							<option value={PAYMENT_METHOD.TICKET}>Boleto</option>
							<option value={PAYMENT_METHOD.CREDIT_CARD}>Cartão de Crédito</option>
							<option value={PAYMENT_METHOD.PIX}>PIX</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className='mt-3'>
						<Form.Label>Descrição</Form.Label>
						<Form.Control
							value={FormState.description}
							name='description'
							as='textarea'
							rows={3}
							onChange={e => {
								setFormState({ ...FormState, description: e?.target?.value })
							}}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => {
							onClose()
						}}
					>
						Cancelar
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							handleUpdateInvoice()
						}}
					>
						Atualizar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalEditInvoice
