import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

const StyledLink = styled(Link)`
	display: flex;
	align-items: center;
	color: #929aa8;
	text-decoration: none;
	font-weight: bold;
	justify-content: flex-end;
	font-size: 15px;
`

const Icon = styled(FontAwesomeIcon)`
	margin-right: 5px;
	position: relative;
	top: 1px;
	font-size: 15px;
`

interface CustomLinkProps {
	to: string
	goback?: boolean
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, goback }) => {
	const navigate = useNavigate()

	return (
		<Button
			variant='falcon-default'
			onClick={() => {
				if (goback) {
					navigate(-1)
				} else {
					navigate(to)
				}
			}}
		>
			<Icon icon={faArrowLeft} />
			Voltar
		</Button>
	)
	// return (
	//   <StyledLink to={to}>
	//     <Icon icon={faArrowLeft} />
	//     Voltar
	//   </StyledLink>
	// );
}

export default CustomLink
