import { PageRoutesType } from '../types'

export const dashboardRoutes: PageRoutesType = {
	label: 'allRoutes',
	labelDisable: true,
	children: [
		{
			permissions: [''],
			name: 'Dashboard',
			active: true,
			icon: 'chart-pie',
			to: '/'
			// children: [
			// 	{
			// 		name: 'Default',
			// 		to: '/',
			// 		exact: true,
			// 		active: true
			// 	},

			// ]
		},
		{
			name: 'Gestão',
			active: true,
			icon: 'users',
			permissions: ['view_user'],
			children: [
				{
					permissions: ['view_user'],
					name: 'Usuários',
					to: '/usuarios',
					exact: true,
					active: true
				},
				{
					permissions: ['view_pdv'],
					name: 'PDVs',
					to: '/pdvs',
					exact: true,
					active: true
				}
			]
		},
		{
			permissions: ['view_bp'],
			name: 'Clientes',
			active: true,
			icon: 'user-friends',
			to: '/clientes'
		},
		{
			permissions: ['view_contract'],
			name: 'Contratos',
			active: true,
			icon: 'file',
			to: '/contratos'
		},
		{
			permissions: ['view_subscription'],
			name: 'Assinaturas',
			active: true,
			icon: 'shopping-cart',
			to: '/assinaturas'
		},
		{
			permissions: ['view_invoice'],
			name: 'Faturas',
			active: true,
			icon: 'tags',
			to: '/faturas'
		},
		{
			name: 'Importar arquivos',
			active: true,
			icon: 'excel',
			permissions: ['view_contract'],
			children: [
				{
					permissions: [''],
					name: 'Titulares',
					to: '/importar/titulares',
					exact: true,
					active: true
				}
			]
		},
		{
			name: 'Relatórios',
			active: true,
			icon: 'tools',
			permissions: [''],
			children: [
				{
					permissions: ['view_contract'],
					name: 'Contratos',
					to: '/relatorios/contratos',
					exact: true,
					active: true
				},
				{
					permissions: ['view_invoice'],
					name: 'Faturas',
					to: '/relatorios/faturas',
					exact: true,
					active: true
				},
				{
					permissions: ['view_subscription'],
					name: 'Assinaturas',
					to: '/relatorios/assinaturas',
					exact: true,
					active: true
				},
			]
		},
		{
			permissions: [''],
			name: 'Ajuda',
			active: true,
			icon:'help',
			to: '/ajuda'
		},
	]
}

export default [dashboardRoutes]
