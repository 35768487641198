import { FC } from 'react'
import { Row, Col } from 'react-bootstrap'
import CustomCardHolder from '../customCardHolder/Index'
export interface IPropsComponent
{
	dataHolder:any
	isDark:boolean
}
 function HolderComponent({dataHolder,isDark}:IPropsComponent) {
	return (
		<div className='mt-3 text-primary' style={{ fontWeight: 'bold' }}>
			<Row>
				<Col sm='6'>
					{' '}
					<h5 style={{ fontWeight: 'bold' }}>Titular</h5>{' '}
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<CustomCardHolder
						bp_name={dataHolder?.business_partner?.name ? dataHolder?.business_partner?.name : ''}
						url_to_path={dataHolder?.id ?`/assinaturas/${dataHolder.id}` :`` }
						status={dataHolder?.status ? dataHolder?.status : ''}
						bp_card_number={dataHolder?.public_number? dataHolder?.public_number : ''}
						isDependent={false}
						isDark={isDark}
					/>
				</Col>
			</Row>
		</div>
	)
}

export default HolderComponent
