import { FC, useContext, useState } from 'react';
import { Row, Col, Card, Badge, Table, ProgressBar, Form, Button } from 'react-bootstrap';

import FalconCardHeader from 'components/common/FalconCardHeader';

import AppContext from 'context/Context';
import React from 'react';
import SimpleBarReact from 'simplebar-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';


import { IContractAddendum } from '../inteface';

const ChartDashboard: React.FC<IContractAddendum> = (data) => {
    let chartData: any[] = []
    if (data.contract_upgrade > 0) chartData.push(
        {
            value: data.contract_upgrade,
            name: 'Upgrade',
            itemStyle: {
                color: getColor('primary')
            }
        },
    )
    if (data.contract_downgrade > 0) chartData.push(
        {
            value: data.contract_downgrade,
            name: 'Downgrade',
            itemStyle: {
                color: rgbaColor(getColor('primary'), 0.5)
            }
        },
    )


    echarts.use([
        TitleComponent,
        TooltipComponent,
        GridComponent,
        PieChart,
        CanvasRenderer,
        LegendComponent
    ]);

    const getOption = (isDark: boolean) => ({
        legend: {
            left: 'center',
            bottom: 22,
            itemWidth: 12,
            itemHeight: 12,
            borderRadius: 0,
            icon: 'circle',
            inactiveColor: getColor('gray-400'),
            inactiveBorderColor: 'transparent',
            textStyle: {
                color: getColor('gray-800'),
                fontSize: 12,
                fontFamily: 'Poppins',
                fontWeight: '500'
            },
            itemGap: 16
        },
        series: [
            {
                type: 'pie',
                radius: ['60%', '80%'],
                label: {
                    show: false
                },

                center: ['50%', '42%'],
                itemStyle: {
                    borderWidth: 2,
                    borderColor: getColor('gray-100')
                },
                data: chartData
            }
        ],
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: getColor('gray-100'),
            borderColor: getColor('gray-300'),
            textStyle: { color: getColor('dark') },
            borderWidth: 1,
            transitionDuration: 0,
            axisPointer: {
                type: 'none'
            }
        }
    });
    const { config } = useContext(AppContext);
    const { isDark } = config;
    const formatNumberToK = (number:number) => {
        if (number >= 1000) {
          const formattedNumber = (number / 1000).toFixed(1);
          return `${formattedNumber}k`;
        } else {
          return number.toString();
        }
      }
    return (
        <>
            <Col md={6} xs={6}>
                <Card className="h-100">
                    <FalconCardHeader
                        title={"Aditivos Realizados"}
                        titleTag="h5"
                    />
                    <Card.Body className="p-3 d-flex aligm-items-center">
                        <ReactEChartsCore
                            echarts={echarts}
                            option={getOption(isDark)}
                            className="h-100 w-100"
                        // style={{ width: 90, height: '6.625rem' }}
                        />
                    </Card.Body>
                    <Card.Footer className="border-top border-200 py-0">
                        <Row>
                            <Col className="border-end border-200 py-3 d-flex justify-content-center ">
                                <div>
                                    <h6 className="text-bold mb-1 fs--2">Upgrade</h6>
                                    <h6 className="fs-0 mb-0 d-flex justify-content-center">
                                        {data.contract_upgrade}
                                    </h6>
                                </div>
                            </Col>
                            <Col className="border-end border-200 py-3 d-flex justify-content-center ">
                                <div>
                                    <h6 className="text-bold mb-1 fs--2">Downgrade</h6>
                                    <h6 className="fs-0 mb-0 d-flex justify-content-center">
                                        {data.contract_downgrade}
                                    </h6>
                                </div>
                            </Col>
                            <Col className="py-3 d-flex justify-content-center">
                                <div>
                                    <h6 className="text-bold mb-1 fs--2">Total</h6>
                                    <h6 className="fs-0 mb-0 d-flex justify-content-center">{data.contract_total?.toLocaleString()}</h6>
                                </div>
                            </Col>
                            <div style={{top:218, width:180}}className="position-absolute start-50 translate-middle text-center">
                                <p style={{fontSize:40}}className="mb-0 text-400 font-sans-serif fw-medium">Total</p>
                                <p style={{fontSize:20}}className=" mb-0 font-sans-serif fw-medium mt-n2">{formatNumberToK(data.contract_total)}</p>
                            </div>
                        </Row>
                    </Card.Footer>

                </Card>
            </Col>
        </>
    )
}

export default ChartDashboard