import request from '../config/request'
import { IPropsSearchFilter, IDataSendToHandleBusinessPartner } from './interfaces'


const search = (filter: IPropsSearchFilter, page = 1, size = 10) => {
	let query = "?page=" + page + "&size=" + size
	if (filter.term)
		query += "&term="
			+ filter.term

	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST +
			`/business-partner` +
			query,
		vtAuth: true
	})
}
const create = async (bp_data: IDataSendToHandleBusinessPartner) => {
	

	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/business-partner`,
		data: bp_data,
		vtAuth: true
	});
}
const loadDataEditform = async (id: number) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/business-partner/${id}`,
		vtAuth: true
	});
}
const update = async (id: number, bp_data: IDataSendToHandleBusinessPartner) => {
	return request({
		method: "POST",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/business-partner/${id}`,
		data: bp_data,
		vtAuth: true
	});
}
const getByCgc = async (cgc: string) => {
	return request({
		method: "GET",
		url: process.env.REACT_APP_VITTAMAIS_SERVICES_HOST + `/business-partner/find-by-cgc/${cgc}`,
		vtAuth: true
	});
}
export { search, create, update,loadDataEditform,getByCgc }
