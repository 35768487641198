import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useContext } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import AppContext from 'context/Context'
import Select from 'react-select'
import { IPropsModal } from './interfaces'
import useAuth from 'hooks/useAuth'
import useSweetAlert from 'context/Alerts/SweetAlert'
function OriginScope({ show }: IPropsModal) {
	const { showAlert } = useSweetAlert()
	const { dataAuth, fillScopePDV } = useAuth()
	const [optionsPDV, setOptionsPDV] = useState<any>([])
	const {
		config: { isDark }
	} = useContext(AppContext)
	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0'
		})
	}

	const [dataForm, setDataForm] = useState<any>({
		pdv_data: ''
	})
	const [showLoading, setShowLoading] = useState(false)

	const onSubmit = async () => {
		try {
			if (!dataForm?.pdv_data) {
				showAlert({
					text: 'Selecione o PDV de origem!',
					title: 'Atenção!',
					type: 'info'
				})
				return
			}
			fillScopePDV({
				id: dataForm?.pdv_data?.value,
				name: dataForm?.pdv_data?.label
			})
		} catch (e: any) {
			console.log(e)
		}
	}

	const loadDataPage = () => {
		let dataPdvs = []

		for (let i = 0; i < dataAuth?.user?.pdvs?.length; i++) {
			let use = dataAuth?.user?.pdvs[i]
			dataPdvs.push({
				value: use.id,
				label: use.name
			})
		}

		setOptionsPDV(dataPdvs)
	}

	useEffect(() => {
		loadDataPage()
	}, [show])

	if (!show) return null
	return (
		<>
			<Modal show={show} onHide={() => {}} size='lg'>
				<Modal.Header>
					<Modal.Title>Selecione o PDV de origem</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<Form.Group>
								<Select
									styles={customStyles}
									name='pdvs'
									placeholder='Selecionar PDV'
									options={optionsPDV}
									onChange={e => {
										setDataForm({
											pdv_data: e
										})
									}}
									value={dataForm.pdv_id}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='primary'
						onClick={() => {
							onSubmit()
						}}
						disabled={showLoading}
					>
						Confirmar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default OriginScope
